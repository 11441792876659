import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-reverse-scroll',
  styleUrls: ['./reverse-scroll.component.scss'],
  templateUrl: './reverse-scroll.component.html'
})
export class ReverseScrollComponent {
  @Input() height: string;
  @Output() onTop = new EventEmitter();

  @ViewChild('reverse', {static: true}) element: ElementRef;

  onScroll(event) {
    event.preventDefault();
    let delta = 1;

    if (event.deltaMode === WheelEvent.DOM_DELTA_LINE) {
      delta = 20;
    }

    this.element.nativeElement.scrollTop = this.element.nativeElement.scrollTop + -1 * event.deltaY * delta;

    const height = this.element.nativeElement.offsetHeight;
    const scrollTop = this.element.nativeElement.scrollTop;
    const scrollHeight = this.element.nativeElement.scrollHeight;
    const isOnTop = (height + 100) >= (scrollHeight - scrollTop);

    if (isOnTop) {
      this.onTop.emit();
    }
  }

  scrollBottom() {
    this.element.nativeElement.scrollTop = 0;
  }
}
