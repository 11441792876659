import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {endpoints} from '../constants/endpoints';
import {User} from '../models/responses/users.response';
import {filter, map} from 'rxjs/operators';
import {ApiResponseList} from '../models/responses/api.response';
import {AuthService} from './auth.service';

@Injectable({providedIn: 'root'})
export class UserService {

  constructor(private httpClient: HttpClient, private authService: AuthService) {}

  getAgents() {
        const url = endpoints.getUsers + '?query=["isSupportAgent", 1, "="]&limit=6';
        return this.httpClient.get<ApiResponseList<User>>(url).pipe(map(res => Object.values(res.records)));
  }
  
  getTeamMembers() {
    const url = endpoints.getUsers + `?query=["accountId", ${this.authService.getAccountId()}, "="]`;
    return this.httpClient.get<ApiResponseList<User>>(url).pipe(
      map(res => Object.values(res.records)),
      map(res => res.filter(user => user.id !== this.authService.getUserId()))
    );
  }
  addEmailAlertsOption(alertOption) {
    const url = endpoints.emailAlerts
        .replace('{userID}', this.authService.getUserId().toString())
        .replace('{accountID}', this.authService.getAccountId().toString());
    return this.httpClient.put(url, {modifiedAt: new Date().toUTCString(), emailalert:alertOption}).subscribe(res => res);
  }
}
