import {Component, Input, OnInit} from '@angular/core';
import {ConversationResponse} from '../../../../models/responses/conversation.response';
import {AuthService} from '../../../../services/auth.service';
import {UserResponse} from '../../../../models/responses/user.response';
import {AlertService} from '../../../../services/alert.service';
import {Router} from '@angular/router';
import {ConversationService} from 'src/app/services/conversation.service';
import {IframeParentService} from 'src/app/services/iframe-parent.service';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss']
})
export class ChatItemComponent implements OnInit {
  @Input()
  conversation: ConversationResponse;
  conversationUser: UserResponse;
  @Input()
  home = false;
  @Input() isFullScreen;
  defaultAvatar = '../../../../../assets/avatar.jpg';
  @Input()
  currentConversation = null;

  constructor(
    private authService: AuthService,
    private router: Router,
    private _ConversationService: ConversationService,
    private _iFrameSer: IframeParentService,
    private _alertService: AlertService) {
  }

  ngOnInit() {
    this.conversationUser = Object.values(this.conversation.users).filter(user => user.id !== this.authService.getUserId())[0];
  }

  getLastMessageDate() {
    return this.conversation.lastMessage ? this.conversation.lastMessageAt : this.conversation.createdAt;
  }

  isRead() {

    if (this.conversation.lastMessage) {
      let lastMsgCreatedAt = 0;
      if (typeof(this.conversation.lastMessage.createdAt) === 'object') {
        lastMsgCreatedAt = this.conversation.lastMessage.createdAt.getTime() / 1000;
      } else {
        lastMsgCreatedAt = this.conversation.lastMessage.createdAt;
      }
      if (this.conversation.lastMessage.userId === this.authService.getUserId()) {
        return true;
      }
      if (this.conversation.lastRead && this.conversation.lastMessage.createdAt) {
        return this.conversation.lastRead > lastMsgCreatedAt;
      }
    } else {
      return true;
    }
  }

  getPhoto() {
    return this.conversationUser.photo ? this.conversationUser.photo : this.defaultAvatar;
  }

  openConversation() {
    if (this.isFullScreen) {
      this._ConversationService.changeID(this.conversation.id);
    } else if (this._iFrameSer.isFullscreen) {
      this.router.navigate(['/full-screen', this.conversation.id]);
    } else {
      this.router.navigate(['/conversations', this.conversation.id]);
    }
  }

  archive(event) {
    event.stopPropagation();
    this._alertService.showConfirm('ARCHIVE_CONFIRM').then((result) => {
      if (result.value) {
        this._ConversationService.archive(this.conversation.id).subscribe( res => {
          this.conversation.archive = 1;
        });
      }
    });
  }
}
