import {MessageResponse} from '../models/responses/message.response';
import {ApiResponseList, ApiResponseSingle} from '../models/responses/api.response';
import {EMOJIS_MAP} from '../constants/emojis.constant';
import {endpoints} from '../constants/endpoints';
import {Attachment} from '../models/attachment';

export function mapMessagesApiResponse(messages: ApiResponseList<MessageResponse>): MessageResponse[] {
  return Object.values(messages.records).map(mapMessage);
}

export function mapMessage(message: MessageResponse): MessageResponse {
  if (message.attachment) {
    message.attachment.fileContent = getAttachmentUrl(message);
  }

  if (typeof message.createdBy === 'number') {
    message.createdBy = {
      id: message.createdBy
    };
  }

  message.message = replaceEmojisReturnedMessage(message.message);
  message.loading = false;
  message.failed = false;

  return new MessageResponse(message);
}

export function mapMessageApiResponse(message: ApiResponseSingle<MessageResponse>): MessageResponse {
  return mapMessage(message.record);
}

export function replaceEmojis(message: string) {
  Object.values(EMOJIS_MAP)
    .filter((emoji: any) => message.indexOf(emoji.key) > -1)
    .forEach((emoji: any) => message = message.replace(new RegExp(emoji.key, 'g'), emoji.value));
  return message;
}

export function replaceEmojisReturnedMessage(message: string) {
  Object.values(EMOJIS_MAP)
    .filter((emoji: any) => message.indexOf(emoji.value) > -1)
    .forEach((emoji: any) => {
      message = message.replace(new RegExp(emoji.value, 'g'), emoji.key);
    });
  return message;
}

export function isImage(attachment: Attachment) {
  const types = ['image/png', 'image/jpg', 'image/jpeg'];
  return types.filter(type => type === attachment.fileType).length > 0;
}

export function getAttachmentUrl(message: MessageResponse) {
  return endpoints.getAttachment
    .replace('{conversationId}', message.conversationId.toString())
    .replace('{messageId}', message.id.toString());
}
