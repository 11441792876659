import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnInit {

  @Input() appTooltip;
  private tipElement: HTMLSpanElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.classList.add('tip-holder');
    this.tipElement = document.createElement('span');
    this.tipElement.classList.add('tip');
    this.tipElement.innerHTML = this.appTooltip;
    this.renderer.appendChild(this.elementRef.nativeElement, this.tipElement);
  }

  @HostListener('mousemove', ['$event'])
  onHover(event) {
    const width = this.elementRef.nativeElement.offsetWidth / 2;
    const x = event.offsetX;
    const y = event.offsetY;
    this.tipElement.style.left = (x - 20) + 'px';
    this.tipElement.style.top = (y + 20) + 'px';
  }
}
