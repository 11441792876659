import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-status',
  templateUrl: './system-status.component.html',
  styleUrls: ['./system-status.component.scss']
})
export class SystemStatusComponent implements OnInit {

  dateNow;


  constructor(public authService: AuthService,) { }

  ngOnInit() {
    this.dateNow = new Date();
  }

}
