import {animate, group, query, style} from '@angular/animations';

export const animateFromBottom = [
    query(':fadeAnimation, :leave',
        style({ position: 'fixed',  width: '100%' }), {optional: true}),
    group([
        query(':fadeAnimation', [
            style({ transform: 'translateY(100%)' }),
            animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' })),
        ], { optional: true }),
        query(':leave', [
            style({ transform: 'translateY(0%)' }),
            animate('0.5s ease-in-out',
                style({ transform: 'translateY(-100%)' })),
        ], { optional: true }),
    ])
];

export const fadeInOut = [
    query(':fadeAnimation',
        style({ position: 'fixed',  width: '100%' }), {optional: true}),
    group([
        query(':leave', [
            style({display: 'block', opacity: 1}),
            animate('300ms ease-in', style({display: 'none', opacity: 0}))
        ]),
        query(':leave', [
            style({ transform: 'translateY(0%)' }),
            animate('0.5s ease-in-out',
                style({ transform: 'translateY(-100%)' })),
        ], { optional: true })
    ])
];
