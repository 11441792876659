<div class="card">
  <div class="card-body">
    <form #searchForm="ngForm">
      <div class="form-group mt-2">
        <label for="search" class="search-label">{{ 'FIND_ANSWER' | translate }}</label>
        <div class="d-flex w-100">
          <input (keyup)="onKeyup($event)" [(ngModel)]="keyword" #search="ngModel" class="search-input" id="search" name="keyword" placeholder="{{ 'SEARCH_ARTICLES' | translate }}" required>
          <button type="button" (click)="onSearch()" [disabled]="searchForm.invalid" class="btn search-button">
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
