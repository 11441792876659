import {UserResponse} from './user.response';
import {MessageResponse} from './message.response';

export class ConversationResponse {
  id: number;
  accountId: number;
  title: string;
  description: string;
  relatedObjectKey: string;
  relatedObjectUid: string;
  relatedObjectIcon: string;
  relatedObjectName: string;
  relatedObjectDeeplink: string;
  lastMessageAt: Date;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
  modifiedBy: number;
  users: UserResponse[];
  lastMessage: MessageResponse;
  messagesCount: number;
  lastRead: number;
  newMessageCount: number;
  archive: number;
}
