<div (click)="openConversation()" *ngIf="conversation && !conversation.archive" class="item" [ngClass]="{'item-home': home, 'conversation_item': !home,'current' : currentConversation == conversation.id}">
  <!-- <div *ngIf="!isRead()" class="red-circle"></div> -->
  <div class="avatar-container">
    <img *ngIf="conversationUser.photo" class="profile-pic" [src]="getPhoto()">
    <span *ngIf="!conversationUser.photo" class="user_name_photo">{{ conversationUser.name.charAt(0).toUpperCase() }}</span>
  </div>
  <div class="item-body">
    <div class="heading">
      <div class="d-flex align-items-center username_container" >
        <span class="username">{{ conversation.title? conversation.title : conversationUser.name }}</span>
      </div>
      <span class="username last-conversation-date">{{getLastMessageDate() | formNow }}  </span>
    </div>
    <div class="message">{{ conversation.lastMessage ? conversation.lastMessage.message : 'No Messages' }}</div>
    <div *ngIf="conversation.newMessageCount > 0" class="message_badge">{{ conversation.newMessageCount > 10 ? '10+': conversation.newMessageCount  }}</div>
    <div class="conv_archive_button" (click)="archive($event)"><i class="fa fa-times"></i></div>
  </div>
</div>
