import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {
  transform(value): any {
    return value + 'Z';
  }
}
