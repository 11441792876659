<div class="d-flex">
  <div class="images">
    <img [ngClass]="{'first-image': images.length > 1}"
         [src]="images[0]? images[0] : '../../../../../assets/avatar.jpg'">
    <img *ngIf="images.length > 1" class="second-image"
         [src]="images[1] ? images[1] : '../../../../../assets/avatar.jpg'">
  </div>
  <div class="d-flex align-items-center header">
    <div class="header-title">
      <div class="d-flex align-items-center" *ngIf="conversation.title && !editable">
        <h5 [ngClass]="{'regular_header': !isFullScreen}">{{conversation.title}}</h5>
        <button (click)="editTitle()" class="d-flex ml-2"><i class="fa fa-edit"></i></button>
      </div>
      <div class="title" *ngIf="!conversation.title || editable">
        <form (ngSubmit)="onEditTitle()">
          <input [(ngModel)]="newTitle" name="title" class="title-input">
        </form>
      </div>
      <ng-container *ngIf="conversation.users.length === 2; else groupChat">
        <p *ngIf="supportAgent">{{chatMembers}}</p>
        <p *ngIf="!supportAgent">{{chatMembers}}</p>
      </ng-container>
      <ng-template #groupChat>
        <p class="chat_members">{{chatMembers}}</p>
      </ng-template>
    </div>
    <div class="action align-self-end conv_action">
      <i class="fal fa-user-plus abit-bigger" alt="" (click)="addUser()"></i>
      <i class="fal fa-window-minimize" alt="" (click)="close()"></i>
      <i  *ngIf="!isFullScreen" class="fal fa-window-maximize" alt="" (click)="maximize()"></i>
      <i *ngIf="isFullScreen"  class="fal fa-window-maximize" alt="" (click)="maximize()"></i>
    </div>
  </div>
</div>
<app-add-members (addedUser)="onMembersAdded($event)" [conversationId]="conversation.id"
                 [chatMembers]="chatMembersIds"></app-add-members>
