<div class="conversation-types" *ngIf="!nextClicked && !chatDetails">
  <div class="conversation-type" (click)="selectConversationType('team')"
    [ngClass]="{'active' : conversationTypeSelected == 'team'}">
    <div class="img-container">
      <i class="fad fa-users team-image"></i>
    </div>
    <p>{{'Team_members' | translate}}</p>
  </div>
  <div class="conversation-type" *ngIf="!isTaxConsultant && taxConsultantId" (click)="selectConversationType('consultant')"
    [ngClass]="{'active' : conversationTypeSelected == 'consultant'}">
    <div class="img-container">
      <i class="fad fa-user-check team-image"></i>
    </div>
    <p>{{'Tax_consultant' | translate}}</p>
  </div>
  <div class="conversation-type" *ngIf="isTaxConsultant" (click)="selectConversationType('clients')"
    [ngClass]="{'active' : conversationTypeSelected == 'clients'}">
    <div class="img-container">
      <i class="fad fa-users team-image"></i>
    </div>
    <p>{{'Clients' | translate}}</p>
  </div>
</div>
<div *ngIf="chatDetails && !nextClicked">
  <span class="subject">{{'SUBJECT' | translate}}</span>
  <input type="textbox" class="rounded-rectangle-6" name="chatTitle" value="{{chatTitle}}" (blur)="ChatTitle($event.target.value)">

  <span class="description">{{'DESCRIPTION' | translate}}</span>
  <p class="please-enter-the-details-of-yo">
    {{'DESCRIPTION_TEXT' | translate}}
  </p>
  <textarea class="rounded-rectangle-6-copy" name="chatDescription" (blur)="ChatDescription($event.target.value)">{{chatDescription}}</textarea>
  <span class="attachments">{{'ATTACHMENT' | translate}}</span>
  <div class="vector-smart-object-1"  (click)="attachment.click()">
    <i class="fa fa-paperclip attach-png" ></i>  <label class="up-to-5-files"> {{'ATTACHMENT_TEXT' | translate}}</label>
    <input  #attachment type="file" class="d-none" multiple="multiple" (change)="Upload($event)" name="file[]" max="5">
  </div>
  <div class="up-to-5-files-error" *ngIf="maxFileExceed">only 5 files allowed</div>
  <ul class="attachments pl-3" *ngIf="files.length">
    <li *ngFor="let file of files">
      <span>{{file.fileName}}</span>
    </li>
  </ul>
</div>
<div class="teamMember" *ngIf="nextClicked">
  <ng-template ngFor let-input [ngForOf]="selectMenuUsers" let-i="index">
    <label class="checkbox-style">
      <span class="d-inline-block" class="filter_name"> {{input.name}}</span>
      <input type="checkbox" [name]="input.id" [value]="input.id" (change)="teamMember(input.name,input.id, $event)" />
      <span class="checkmark"></span>
    </label>
  </ng-template>
</div>
