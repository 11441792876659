<ng-template #content let-modal>
  <form [@fadeInOut]>
    <div class="modal-header">
      <i class="fal fa-user-plus"></i>
      <h4 class="modal-title" id="modal-basic-title">{{ 'ADD_MEMBERS' | translate }}</h4>
      <button type="button" class="close close-button" aria-label="Close"
              (click)="modal.dismiss('Cross click');">
        <i class="fal fa-close" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body">
      <p class="intro">{{'select-group-to-chat-with' | translate  }}</p>
      <app-chat-type [chatMembers]="chatMembers" (messageEvent)="memberReceived($event)" [nextClicked]="true"></app-chat-type>
    </div>
    <div class="modal-footer justify-content-end">
      <button class="btn btn-primary" (click)="addMembers()">
        <span *ngIf="!loading">{{ 'ADD' | translate }}</span>
        <div *ngIf="loading" class="spinner-border" role="status">
          <span class="sr-only">{{'Loading' | translate}}...</span>
        </div>
      </button>
    </div>
  </form>
</ng-template>
