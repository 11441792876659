import {Attachment} from '../attachment';
import {UserResponse} from './user.response';
import {EMOJIS_MAP} from '../../constants/emojis.constant';
import * as moment from 'moment';

export class MessageResponse {
  id?: number;
  message: string;
  userId?: number;
  createdBy: UserResponse;
  createdAt: Date;
  loading?: boolean;
  failed?: boolean;
  conversationId: number;
  sys_message: number;
  isImage: boolean;
  private file: Attachment;

  constructor(message) {
    Object.assign(this, message);
    this.setCreatedAt(message.createdAt);
    this.setIsImage();
  }

  get text() {
    /**
     * adding a regex to check if the text contains any URLs and if it contains
     * convert it to the URL
     */
    var text = this.message.trim();
    var url1 = /(\b(www\.?|https?|ftp|):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim;
    var url2 = /(\b(www\..+?\..+?)(\s|$))/gim;
    if(text.match(url1)) {
        text = text.replace(url1, "<a href=\"$1\" target=\"_blank\">$1</a>");
      } else if(text.match(url2)){
        text = text.replace(url2, "<a href=\"http://$1\" target=\"_blank\">$1</a>");
      }
    return text;
  }

  setIsImage(): boolean {
    if (!this.attachment) {
      return false;
    }
    const types = ['image/png', 'image/jpg', 'image/jpeg'];
    this.isImage = types.filter(type => type === this.attachment.fileType).length > 0;
  }

  imageLoaded() {
    return this.attachment.imageUrl;
  }

  isEmoji(): boolean {
    return this.message in EMOJIS_MAP;
  }

  isFile(): boolean {
    return this.attachment !== undefined && !this.isImage;
  }

  set attachment(attachment: Attachment) {
    this.file = attachment;
    this.setIsImage();
  }

  get attachment() {
    return this.file;
  }

  get emoji() {
    return EMOJIS_MAP[this.message].colons;
  }

  get creationDate() {
    return new Date(moment(this.createdAt).format('MM/D/YYYY')).getTime();
  }

  setCreatedAt(date) {
    if (isNaN(date)) {
      this.createdAt = new Date(date + 'Z');
    } else {
      this.createdAt = new Date(date * 1000);
    }
  }
}
