import {ConversationResponse} from '../models/responses/conversation.response';
import {replaceEmojisReturnedMessage} from './mapMessages';
import {MessageResponse} from '../models/responses/message.response';

export function mapConversations(conversations: ConversationResponse[]) {
  return conversations.map(conversation => {
    if (conversation.lastMessage) {
      conversation.lastMessage.message = replaceEmojisReturnedMessage(conversation.lastMessage.message);
      conversation.lastMessage = new MessageResponse(conversation.lastMessage);
    }
    return conversation;
  });
}
