<div class="box-body">
  <div class="chat_relation_info_bar" *ngIf="conversation && conversation.relatedObjectKey">
    {{ conversation.relatedObjectKey | translate }}: <a target="_blank" href="{{ conversation.relatedObjectDeeplink }}">{{ conversation.relatedObjectName }}</a>
  </div>
  <app-reverse-scroll (onTop)="onScroll()" height="573px">
    <div class="messages">
      <div *ngFor="let item of messagesModel.messages | keyvalue">
        <div class="d-flex justify-content-center">
          <span class="message-header">{{ item.key | date: authService.getUserDateFormat() : authService.getUser().timezone : authService.getUser().language }}</span>
        </div>
        <app-message [conversationType]="conversationType" [message]="message.value" *ngFor="let message of item.value | keyvalue: messageOrdering"></app-message>
      </div>
    </div>
  </app-reverse-scroll>
  <div [@onMessagesEnter]="loading" class="add-message" *ngIf="!isSupportAgent || conversationType=='GroupChat'">
    <textarea (keypress)="onKeyup($event)" #messageInput [(ngModel)]="textMessage" name="message"
              placeholder="{{ 'ANSWER' | translate }}"></textarea>

    <div class="position-relative d-flex mr-4">
      <emoji-mart [i18n]="'EMOJIS' | translate " [include]="['recent', 'people', 'nature', 'objects', 'symbols']"
                  [@emojisAnimation]="isEmojiButtonActive"
                  (emojiClick)="addEmoji($event, messageInput)" [showPreview]="false"
                  emojiSize="37"
                  perLine="6" set="emojione" sheetSize="64" title="Pick your emoji…" emoji="point_up"></emoji-mart>
      <button (click)="onEmojiButtonClick($event)" [ngClass]="{'emoji-btn__clicked': isEmojiButtonActive}"
              class="emoji-btn mx-1">
        <i class="fa fa-smile-o"></i>
      </button>
      <div *ngIf="textMessage.length < 1; else sendButton"
           class="d-flex flex-column justify-content-center mx-1 upload-file" (click)="attachment.click()">
        <input (change)="uploadFile($event)" #attachment type="file" class="d-none" name="file">
        <i class="fa fa-paperclip"></i>
      </div>
      <ng-template #sendButton>
        <button (click)="addMessage()" class="send-btn" aria-hidden="true">
          <i class="fa fa-paper-plane-o"></i>
        </button>
      </ng-template>
    </div>
  </div>
  <div class="card p-2 add-message" style="margin: 0;" *ngIf="isSupportAgent && conversationType=='SingleChat'">
    <span [innerHtml]="'SUPPORT_REMOVE_MSG' | translate"></span>
  </div>
  <div *ngIf="loading" class="position-absolute d-flex justify-content-center align-items-center w-100 h-100 spinner">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
