export const EMOJIS_MAP = {
  '🐵': {key: '🐵', value: ':monkey_face:', colons: ':monkey_face:'},
  '😀': {key: '😀', value: ':grinning:', colons: ':grinning:'},
  '🏻': {key: '🏻', value: ':skin-tone-2:', colons: ':skin-tone-2:'},
  '🌍': {key: '🌍', value: ':earth_africa:', colons: ':earth_africa:'},
  '🏁': {key: '🏁', value: ':checkered_flag:', colons: ':checkered_flag:'},
  '🔇': {key: '🔇', value: ':mute:', colons: ':mute:'},
  '🎃': {key: '🎃', value: ':jack_o_lantern:', colons: ':jack_o_lantern:'},
  '🏧': {key: '🏧', value: ':atm:', colons: ':atm:'},
  '🍇': {key: '🍇', value: ':grapes:', colons: ':grapes:'},
  '🌎': {key: '🌎', value: ':earth_americas:', colons: ':earth_americas:'},
  '😁': {key: '😁', value: ':grin:', colons: ':grin:'},
  '🍈': {key: '🍈', value: ':melon:', colons: ':melon:'},
  '🚩': {key: '🚩', value: ':triangular_flag_on_post:', colons: ':triangular_flag_on_post:'},
  '🐒': {key: '🐒', value: ':monkey:', colons: ':monkey:'},
  '🎄': {key: '🎄', value: ':christmas_tree:', colons: ':christmas_tree:'},
  '🏼': {key: '🏼', value: ':skin-tone-3:', colons: ':skin-tone-3:'},
  '🚮': {key: '🚮', value: ':put_litter_in_its_place:', colons: ':put_litter_in_its_place:'},
  '🔈': {key: '🔈', value: ':speaker:', colons: ':speaker:'},
  '🌏': {key: '🌏', value: ':earth_asia:', colons: ':earth_asia:'},
  '🎌': {key: '🎌', value: ':crossed_flags:', colons: ':crossed_flags:'},
  '😂': {key: '😂', value: ':joy:', colons: ':joy:'},
  '🔉': {key: '🔉', value: ':sound:', colons: ':sound:'},
  '🍉': {key: '🍉', value: ':watermelon:', colons: ':watermelon:'},
  '🦍': {key: '🦍', value: ':gorilla:', colons: ':gorilla:'},
  '🎆': {key: '🎆', value: ':fireworks:', colons: ':fireworks:'},
  '🚰': {key: '🚰', value: ':potable_water:', colons: ':potable_water:'},
  '🏽': {key: '🏽', value: ':skin-tone-4:', colons: ':skin-tone-4:'},
  '♿': {key: '♿', value: ':wheelchair:', colons: ':wheelchair:'},
  '🤣': {key: '🤣', value: ':rolling_on_the_floor_laughing:', colons: ':rolling_on_the_floor_laughing:'},
  '🔊': {key: '🔊', value: ':loud_sound:', colons: ':loud_sound:'},
  '🏴': {key: '🏴', value: ':waving_black_flag:', colons: ':waving_black_flag:'},
  '🍊': {key: '🍊', value: ':tangerine:', colons: ':tangerine:'},
  '🐶': {key: '🐶', value: ':dog:', colons: ':dog:'},
  '🎇': {key: '🎇', value: ':sparkler:', colons: ':sparkler:'},
  '🏾': {key: '🏾', value: ':skin-tone-5:', colons: ':skin-tone-5:'},
  '🌐': {key: '🌐', value: ':globe_with_meridians:', colons: ':globe_with_meridians:'},
  '🏿': {key: '🏿', value: ':skin-tone-6:', colons: ':skin-tone-6:'},
  '😃': {key: '😃', value: ':smiley:', colons: ':smiley:'},
  '📢': {key: '📢', value: ':loudspeaker:', colons: ':loudspeaker:'},
  '✨': {key: '✨', value: ':sparkles:', colons: ':sparkles:'},
  '🐕': {key: '🐕', value: ':dog2:', colons: ':dog2:'},
  '🏳️': {key: '🏳️', value: ':waving_white_flag:', colons: ':waving_white_flag:'},
  '🗺️': {key: '🗺️', value: ':world_map:', colons: ':world_map:'},
  '🍋': {key: '🍋', value: ':lemon:', colons: ':lemon:'},
  '🚹': {key: '🚹', value: ':mens:', colons: ':mens:'},
  '🚺': {key: '🚺', value: ':womens:', colons: ':womens:'},
  '🏳️‍🌈': {key: '🏳️‍🌈', value: ':rainbow-flag:', colons: ':rainbow-flag:'},
  '😄': {key: '😄', value: ':smile:', colons: ':smile:'},
  '🍌': {key: '🍌', value: ':banana:', colons: ':banana:'},
  '📣': {key: '📣', value: ':mega:', colons: ':mega:'},
  '🗾': {key: '🗾', value: ':japan:', colons: ':japan:'},
  '🐩': {key: '🐩', value: ':poodle:', colons: ':poodle:'},
  '🎈': {key: '🎈', value: ':balloon:', colons: ':balloon:'},
  '🇦🇨': {key: '🇦🇨', value: ':flag-ac:', colons: ':flag-ac:'},
  '😅': {key: '😅', value: ':sweat_smile:', colons: ':sweat_smile:'},
  '🍍': {key: '🍍', value: ':pineapple:', colons: ':pineapple:'},
  '🚻': {key: '🚻', value: ':restroom:', colons: ':restroom:'},
  '📯': {key: '📯', value: ':postal_horn:', colons: ':postal_horn:'},
  '🐺': {key: '🐺', value: ':wolf:', colons: ':wolf:'},
  '🎉': {key: '🎉', value: ':tada:', colons: ':tada:'},
  '🏔️': {key: '🏔️', value: ':snow_capped_mountain:', colons: ':snow_capped_mountain:'},
  '😆': {key: '😆', value: ':laughing:', colons: ':laughing:'},
  '🍎': {key: '🍎', value: ':apple:', colons: ':apple:'},
  '🇦🇩': {key: '🇦🇩', value: ':flag-ad:', colons: ':flag-ad:'},
  '🦊': {key: '🦊', value: ':fox_face:', colons: ':fox_face:'},
  '🎊': {key: '🎊', value: ':confetti_ball:', colons: ':confetti_ball:'},
  '🔔': {key: '🔔', value: ':bell:', colons: ':bell:'},
  '⛰️': {key: '⛰️', value: ':mountain:', colons: ':mountain:'},
  '🚼': {key: '🚼', value: ':baby_symbol:', colons: ':baby_symbol:'},
  '🚾': {key: '🚾', value: ':wc:', colons: ':wc:'},
  '😉': {key: '😉', value: ':wink:', colons: ':wink:'},
  '🔕': {key: '🔕', value: ':no_bell:', colons: ':no_bell:'},
  '🍏': {key: '🍏', value: ':green_apple:', colons: ':green_apple:'},
  '🎋': {key: '🎋', value: ':tanabata_tree:', colons: ':tanabata_tree:'},
  '🇦🇪': {key: '🇦🇪', value: ':flag-ae:', colons: ':flag-ae:'},
  '🌋': {key: '🌋', value: ':volcano:', colons: ':volcano:'},
  '🐱': {key: '🐱', value: ':cat:', colons: ':cat:'},
  '🇦🇫': {key: '🇦🇫', value: ':flag-af:', colons: ':flag-af:'},
  '🎼': {key: '🎼', value: ':musical_score:', colons: ':musical_score:'},
  '😊': {key: '😊', value: ':blush:', colons: ':blush:'},
  '🍐': {key: '🍐', value: ':pear:', colons: ':pear:'},
  '🎍': {key: '🎍', value: ':bamboo:', colons: ':bamboo:'},
  '🛂': {key: '🛂', value: ':passport_control:', colons: ':passport_control:'},
  '🗻': {key: '🗻', value: ':mount_fuji:', colons: ':mount_fuji:'},
  '🐈': {key: '🐈', value: ':cat2:', colons: ':cat2:'},
  '🎵': {key: '🎵', value: ':musical_note:', colons: ':musical_note:'},
  '🎎': {key: '🎎', value: ':dolls:', colons: ':dolls:'},
  '🦁': {key: '🦁', value: ':lion_face:', colons: ':lion_face:'},
  '🏕️': {key: '🏕️', value: ':camping:', colons: ':camping:'},
  '🇦🇬': {key: '🇦🇬', value: ':flag-ag:', colons: ':flag-ag:'},
  '🛃': {key: '🛃', value: ':customs:', colons: ':customs:'},
  '😋': {key: '😋', value: ':yum:', colons: ':yum:'},
  '🍑': {key: '🍑', value: ':peach:', colons: ':peach:'},
  '🐯': {key: '🐯', value: ':tiger:', colons: ':tiger:'},
  '🎶': {key: '🎶', value: ':notes:', colons: ':notes:'},
  '🎏': {key: '🎏', value: ':flags:', colons: ':flags:'},
  '🏖️': {key: '🏖️', value: ':beach_with_umbrella:', colons: ':beach_with_umbrella:'},
  '🍒': {key: '🍒', value: ':cherries:', colons: ':cherries:'},
  '🇦🇮': {key: '🇦🇮', value: ':flag-ai:', colons: ':flag-ai:'},
  '🛄': {key: '🛄', value: ':baggage_claim:', colons: ':baggage_claim:'},
  '😎': {key: '😎', value: ':sunglasses:', colons: ':sunglasses:'},
  '🛅': {key: '🛅', value: ':left_luggage:', colons: ':left_luggage:'},
  '🎐': {key: '🎐', value: ':wind_chime:', colons: ':wind_chime:'},
  '🍓': {key: '🍓', value: ':strawberry:', colons: ':strawberry:'},
  '🏜️': {key: '🏜️', value: ':desert:', colons: ':desert:'},
  '🎙️': {key: '🎙️', value: ':studio_microphone:', colons: ':studio_microphone:'},
  '🇦🇱': {key: '🇦🇱', value: ':flag-al:', colons: ':flag-al:'},
  '🐅': {key: '🐅', value: ':tiger2:', colons: ':tiger2:'},
  '😍': {key: '😍', value: ':heart_eyes:', colons: ':heart_eyes:'},
  '🏝️': {key: '🏝️', value: ':desert_island:', colons: ':desert_island:'},
  '🥝': {key: '🥝', value: ':kiwifruit:', colons: ':kiwifruit:'},
  '🎑': {key: '🎑', value: ':rice_scene:', colons: ':rice_scene:'},
  '😘': {key: '😘', value: ':kissing_heart:', colons: ':kissing_heart:'},
  '⚠️': {key: '⚠️', value: ':warning:', colons: ':warning:'},
  '🇦🇲': {key: '🇦🇲', value: ':flag-am:', colons: ':flag-am:'},
  '🐆': {key: '🐆', value: ':leopard:', colons: ':leopard:'},
  '🎚️': {key: '🎚️', value: ':level_slider:', colons: ':level_slider:'},
  '🐴': {key: '🐴', value: ':horse:', colons: ':horse:'},
  '🚸': {key: '🚸', value: ':children_crossing:', colons: ':children_crossing:'},
  '🎀': {key: '🎀', value: ':ribbon:', colons: ':ribbon:'},
  '🏞️': {key: '🏞️', value: ':national_park:', colons: ':national_park:'},
  '🎛️': {key: '🎛️', value: ':control_knobs:', colons: ':control_knobs:'},
  '😗': {key: '😗', value: ':kissing:', colons: ':kissing:'},
  '🍅': {key: '🍅', value: ':tomato:', colons: ':tomato:'},
  '🇦🇴': {key: '🇦🇴', value: ':flag-ao:', colons: ':flag-ao:'},
  '🏟️': {key: '🏟️', value: ':stadium:', colons: ':stadium:'},
  '🇦🇶': {key: '🇦🇶', value: ':flag-aq:', colons: ':flag-aq:'},
  '🎁': {key: '🎁', value: ':gift:', colons: ':gift:'},
  '⛔': {key: '⛔', value: ':no_entry:', colons: ':no_entry:'},
  '😙': {key: '😙', value: ':kissing_smiling_eyes:', colons: ':kissing_smiling_eyes:'},
  '🥥': {key: '🥥', value: ':coconut:', colons: ':coconut:'},
  '🐎': {key: '🐎', value: ':racehorse:', colons: ':racehorse:'},
  '🎤': {key: '🎤', value: ':microphone:', colons: ':microphone:'},
  '🏛️': {key: '🏛️', value: ':classical_building:', colons: ':classical_building:'},
  '🚫': {key: '🚫', value: ':no_entry_sign:', colons: ':no_entry_sign:'},
  '🎗️': {key: '🎗️', value: ':reminder_ribbon:', colons: ':reminder_ribbon:'},
  '😚': {key: '😚', value: ':kissing_closed_eyes:', colons: ':kissing_closed_eyes:'},
  '🦄': {key: '🦄', value: ':unicorn_face:', colons: ':unicorn_face:'},
  '🇦🇷': {key: '🇦🇷', value: ':flag-ar:', colons: ':flag-ar:'},
  '🎧': {key: '🎧', value: ':headphones:', colons: ':headphones:'},
  '🥑': {key: '🥑', value: ':avocado:', colons: ':avocado:'},
  '☺️': {key: '☺️', value: ':relaxed:', colons: ':relaxed:'},
  '🦓': {key: '🦓', value: ':zebra_face:', colons: ':zebra_face:'},
  '🍆': {key: '🍆', value: ':eggplant:', colons: ':eggplant:'},
  '📻': {key: '📻', value: ':radio:', colons: ':radio:'},
  '🏗️': {key: '🏗️', value: ':building_construction:', colons: ':building_construction:'},
  '🇦🇸': {key: '🇦🇸', value: ':flag-as:', colons: ':flag-as:'},
  '🎟️': {key: '🎟️', value: ':admission_tickets:', colons: ':admission_tickets:'},
  '🚳': {key: '🚳', value: ':no_bicycles:', colons: ':no_bicycles:'},
  '🚭': {key: '🚭', value: ':no_smoking:', colons: ':no_smoking:'},
  '🙂': {key: '🙂', value: ':slightly_smiling_face:', colons: ':slightly_smiling_face:'},
  '🇦🇹': {key: '🇦🇹', value: ':flag-at:', colons: ':flag-at:'},
  '🎫': {key: '🎫', value: ':ticket:', colons: ':ticket:'},
  '🎷': {key: '🎷', value: ':saxophone:', colons: ':saxophone:'},
  '🦌': {key: '🦌', value: ':deer:', colons: ':deer:'},
  '🏘️': {key: '🏘️', value: ':house_buildings:', colons: ':house_buildings:'},
  '🥔': {key: '🥔', value: ':potato:', colons: ':potato:'},
  '🎸': {key: '🎸', value: ':guitar:', colons: ':guitar:'},
  '🥕': {key: '🥕', value: ':carrot:', colons: ':carrot:'},
  '🏙️': {key: '🏙️', value: ':cityscape:', colons: ':cityscape:'},
  '🇦🇺': {key: '🇦🇺', value: ':flag-au:', colons: ':flag-au:'},
  '🚯': {key: '🚯', value: ':do_not_litter:', colons: ':do_not_litter:'},
  '🤗': {key: '🤗', value: ':hugging_face:', colons: ':hugging_face:'},
  '🐮': {key: '🐮', value: ':cow:', colons: ':cow:'},
  '🎖️': {key: '🎖️', value: ':medal:', colons: ':medal:'},
  '🎹': {key: '🎹', value: ':musical_keyboard:', colons: ':musical_keyboard:'},
  '🌽': {key: '🌽', value: ':corn:', colons: ':corn:'},
  '🏚️': {key: '🏚️', value: ':derelict_house_building:', colons: ':derelict_house_building:'},
  '🚱': {key: '🚱', value: ':non-potable_water:', colons: ':non-potable_water:'},
  '🏆': {key: '🏆', value: ':trophy:', colons: ':trophy:'},
  '🇦🇼': {key: '🇦🇼', value: ':flag-aw:', colons: ':flag-aw:'},
  '🤩': {key: '🤩', value: ':star-struck:', colons: ':star-struck:'},
  '🐂': {key: '🐂', value: ':ox:', colons: ':ox:'},
  '🎺': {key: '🎺', value: ':trumpet:', colons: ':trumpet:'},
  '🌶️': {key: '🌶️', value: ':hot_pepper:', colons: ':hot_pepper:'},
  '🏅': {key: '🏅', value: ':sports_medal:', colons: ':sports_medal:'},
  '🇦🇽': {key: '🇦🇽', value: ':flag-ax:', colons: ':flag-ax:'},
  '🐃': {key: '🐃', value: ':water_buffalo:', colons: ':water_buffalo:'},
  '🚷': {key: '🚷', value: ':no_pedestrians:', colons: ':no_pedestrians:'},
  '🤔': {key: '🤔', value: ':thinking_face:', colons: ':thinking_face:'},
  '🏠': {key: '🏠', value: ':house:', colons: ':house:'},
  '📵': {key: '📵', value: ':no_mobile_phones:', colons: ':no_mobile_phones:'},
  '🇦🇿': {key: '🇦🇿', value: ':flag-az:', colons: ':flag-az:'},
  '🥇': {key: '🥇', value: ':first_place_medal:', colons: ':first_place_medal:'},
  '🏡': {key: '🏡', value: ':house_with_garden:', colons: ':house_with_garden:'},
  '🎻': {key: '🎻', value: ':violin:', colons: ':violin:'},
  '🤨': {key: '🤨', value: ':face_with_raised_eyebrow:', colons: ':face_with_raised_eyebrow:'},
  '🥒': {key: '🥒', value: ':cucumber:', colons: ':cucumber:'},
  '🐄': {key: '🐄', value: ':cow2:', colons: ':cow2:'},
  '🇧🇦': {key: '🇧🇦', value: ':flag-ba:', colons: ':flag-ba:'},
  '🐷': {key: '🐷', value: ':pig:', colons: ':pig:'},
  '🥁': {key: '🥁', value: ':drum_with_drumsticks:', colons: ':drum_with_drumsticks:'},
  '🔞': {key: '🔞', value: ':underage:', colons: ':underage:'},
  '🥦': {key: '🥦', value: ':broccoli:', colons: ':broccoli:'},
  '🏢': {key: '🏢', value: ':office:', colons: ':office:'},
  '🥈': {key: '🥈', value: ':second_place_medal:', colons: ':second_place_medal:'},
  '😐': {key: '😐', value: ':neutral_face:', colons: ':neutral_face:'},
  '🥉': {key: '🥉', value: ':third_place_medal:', colons: ':third_place_medal:'},
  '🍄': {key: '🍄', value: ':mushroom:', colons: ':mushroom:'},
  '🇧🇧': {key: '🇧🇧', value: ':flag-bb:', colons: ':flag-bb:'},
  '☢️': {key: '☢️', value: ':radioactive_sign:', colons: ':radioactive_sign:'},
  '🐖': {key: '🐖', value: ':pig2:', colons: ':pig2:'},
  '😑': {key: '😑', value: ':expressionless:', colons: ':expressionless:'},
  '📱': {key: '📱', value: ':iphone:', colons: ':iphone:'},
  '🏣': {key: '🏣', value: ':post_office:', colons: ':post_office:'},
  '🏤': {key: '🏤', value: ':european_post_office:', colons: ':european_post_office:'},
  '⚽': {key: '⚽', value: ':soccer:', colons: ':soccer:'},
  '🐗': {key: '🐗', value: ':boar:', colons: ':boar:'},
  '🥜': {key: '🥜', value: ':peanuts:', colons: ':peanuts:'},
  '📲': {key: '📲', value: ':calling:', colons: ':calling:'},
  '☣️': {key: '☣️', value: ':biohazard_sign:', colons: ':biohazard_sign:'},
  '🇧🇩': {key: '🇧🇩', value: ':flag-bd:', colons: ':flag-bd:'},
  '😶': {key: '😶', value: ':no_mouth:', colons: ':no_mouth:'},
  '🙄': {key: '🙄', value: ':face_with_rolling_eyes:', colons: ':face_with_rolling_eyes:'},
  '☎️': {key: '☎️', value: ':phone:', colons: ':phone:'},
  '🐽': {key: '🐽', value: ':pig_nose:', colons: ':pig_nose:'},
  '🌰': {key: '🌰', value: ':chestnut:', colons: ':chestnut:'},
  '⬆️': {key: '⬆️', value: ':arrow_up:', colons: ':arrow_up:'},
  '🏥': {key: '🏥', value: ':hospital:', colons: ':hospital:'},
  '🇧🇪': {key: '🇧🇪', value: ':flag-be:', colons: ':flag-be:'},
  '⚾': {key: '⚾', value: ':baseball:', colons: ':baseball:'},
  '😏': {key: '😏', value: ':smirk:', colons: ':smirk:'},
  '↗️': {key: '↗️', value: ':arrow_upper_right:', colons: ':arrow_upper_right:'},
  '🇧🇫': {key: '🇧🇫', value: ':flag-bf:', colons: ':flag-bf:'},
  '🏀': {key: '🏀', value: ':basketball:', colons: ':basketball:'},
  '🐏': {key: '🐏', value: ':ram:', colons: ':ram:'},
  '🏦': {key: '🏦', value: ':bank:', colons: ':bank:'},
  '🍞': {key: '🍞', value: ':bread:', colons: ':bread:'},
  '📞': {key: '📞', value: ':telephone_receiver:', colons: ':telephone_receiver:'},
  '🥐': {key: '🥐', value: ':croissant:', colons: ':croissant:'},
  '📟': {key: '📟', value: ':pager:', colons: ':pager:'},
  '🐑': {key: '🐑', value: ':sheep:', colons: ':sheep:'},
  '➡️': {key: '➡️', value: ':arrow_right:', colons: ':arrow_right:'},
  '😣': {key: '😣', value: ':persevere:', colons: ':persevere:'},
  '🇧🇬': {key: '🇧🇬', value: ':flag-bg:', colons: ':flag-bg:'},
  '🏐': {key: '🏐', value: ':volleyball:', colons: ':volleyball:'},
  '🏨': {key: '🏨', value: ':hotel:', colons: ':hotel:'},
  '↘️': {key: '↘️', value: ':arrow_lower_right:', colons: ':arrow_lower_right:'},
  '🐐': {key: '🐐', value: ':goat:', colons: ':goat:'},
  '🇧🇭': {key: '🇧🇭', value: ':flag-bh:', colons: ':flag-bh:'},
  '🏩': {key: '🏩', value: ':love_hotel:', colons: ':love_hotel:'},
  '😥': {key: '😥', value: ':disappointed_relieved:', colons: ':disappointed_relieved:'},
  '🥖': {key: '🥖', value: ':baguette_bread:', colons: ':baguette_bread:'},
  '🏈': {key: '🏈', value: ':football:', colons: ':football:'},
  '📠': {key: '📠', value: ':fax:', colons: ':fax:'},
  '🏪': {key: '🏪', value: ':convenience_store:', colons: ':convenience_store:'},
  '🐪': {key: '🐪', value: ':dromedary_camel:', colons: ':dromedary_camel:'},
  '⬇️': {key: '⬇️', value: ':arrow_down:', colons: ':arrow_down:'},
  '🔋': {key: '🔋', value: ':battery:', colons: ':battery:'},
  '🏉': {key: '🏉', value: ':rugby_football:', colons: ':rugby_football:'},
  '🥨': {key: '🥨', value: ':pretzel:', colons: ':pretzel:'},
  '😮': {key: '😮', value: ':open_mouth:', colons: ':open_mouth:'},
  '🇧🇮': {key: '🇧🇮', value: ':flag-bi:', colons: ':flag-bi:'},
  '🇧🇯': {key: '🇧🇯', value: ':flag-bj:', colons: ':flag-bj:'},
  '🥞': {key: '🥞', value: ':pancakes:', colons: ':pancakes:'},
  '🏫': {key: '🏫', value: ':school:', colons: ':school:'},
  '🎾': {key: '🎾', value: ':tennis:', colons: ':tennis:'},
  '🤐': {key: '🤐', value: ':zipper_mouth_face:', colons: ':zipper_mouth_face:'},
  '🐫': {key: '🐫', value: ':camel:', colons: ':camel:'},
  '↙️': {key: '↙️', value: ':arrow_lower_left:', colons: ':arrow_lower_left:'},
  '🔌': {key: '🔌', value: ':electric_plug:', colons: ':electric_plug:'},
  '🧀': {key: '🧀', value: ':cheese_wedge:', colons: ':cheese_wedge:'},
  '😯': {key: '😯', value: ':hushed:', colons: ':hushed:'},
  '💻': {key: '💻', value: ':computer:', colons: ':computer:'},
  '🦒': {key: '🦒', value: ':giraffe_face:', colons: ':giraffe_face:'},
  '🎱': {key: '🎱', value: ':8ball:', colons: ':8ball:'},
  '🇧🇱': {key: '🇧🇱', value: ':flag-bl:', colons: ':flag-bl:'},
  '⬅️': {key: '⬅️', value: ':arrow_left:', colons: ':arrow_left:'},
  '🏬': {key: '🏬', value: ':department_store:', colons: ':department_store:'},
  '🍖': {key: '🍖', value: ':meat_on_bone:', colons: ':meat_on_bone:'},
  '↖️': {key: '↖️', value: ':arrow_upper_left:', colons: ':arrow_upper_left:'},
  '🇧🇲': {key: '🇧🇲', value: ':flag-bm:', colons: ':flag-bm:'},
  '😪': {key: '😪', value: ':sleepy:', colons: ':sleepy:'},
  '🎳': {key: '🎳', value: ':bowling:', colons: ':bowling:'},
  '🏭': {key: '🏭', value: ':factory:', colons: ':factory:'},
  '🖥️': {key: '🖥️', value: ':desktop_computer:', colons: ':desktop_computer:'},
  '🐘': {key: '🐘', value: ':elephant:', colons: ':elephant:'},
  '🦏': {key: '🦏', value: ':rhinoceros:', colons: ':rhinoceros:'},
  '↕️': {key: '↕️', value: ':arrow_up_down:', colons: ':arrow_up_down:'},
  '🏏': {key: '🏏', value: ':cricket_bat_and_ball:', colons: ':cricket_bat_and_ball:'},
  '🖨️': {key: '🖨️', value: ':printer:', colons: ':printer:'},
  '🍗': {key: '🍗', value: ':poultry_leg:', colons: ':poultry_leg:'},
  '😫': {key: '😫', value: ':tired_face:', colons: ':tired_face:'},
  '🏯': {key: '🏯', value: ':japanese_castle:', colons: ':japanese_castle:'},
  '🇧🇳': {key: '🇧🇳', value: ':flag-bn:', colons: ':flag-bn:'},
  '🏑': {key: '🏑', value: ':field_hockey_stick_and_ball:', colons: ':field_hockey_stick_and_ball:'},
  '😴': {key: '😴', value: ':sleeping:', colons: ':sleeping:'},
  '↔️': {key: '↔️', value: ':left_right_arrow:', colons: ':left_right_arrow:'},
  '⌨️': {key: '⌨️', value: ':keyboard:', colons: ':keyboard:'},
  '🏰': {key: '🏰', value: ':european_castle:', colons: ':european_castle:'},
  '🐭': {key: '🐭', value: ':mouse:', colons: ':mouse:'},
  '🇧🇴': {key: '🇧🇴', value: ':flag-bo:', colons: ':flag-bo:'},
  '🥩': {key: '🥩', value: ':cut_of_meat:', colons: ':cut_of_meat:'},
  '🏒': {key: '🏒', value: ':ice_hockey_stick_and_puck:', colons: ':ice_hockey_stick_and_puck:'},
  '🐁': {key: '🐁', value: ':mouse2:', colons: ':mouse2:'},
  '🖱️': {key: '🖱️', value: ':three_button_mouse:', colons: ':three_button_mouse:'},
  '↩️': {key: '↩️', value: ':leftwards_arrow_with_hook:', colons: ':leftwards_arrow_with_hook:'},
  '🥓': {key: '🥓', value: ':bacon:', colons: ':bacon:'},
  '😌': {key: '😌', value: ':relieved:', colons: ':relieved:'},
  '🇧🇶': {key: '🇧🇶', value: ':flag-bq:', colons: ':flag-bq:'},
  '💒': {key: '💒', value: ':wedding:', colons: ':wedding:'},
  '🗼': {key: '🗼', value: ':tokyo_tower:', colons: ':tokyo_tower:'},
  '↪️': {key: '↪️', value: ':arrow_right_hook:', colons: ':arrow_right_hook:'},
  '🍔': {key: '🍔', value: ':hamburger:', colons: ':hamburger:'},
  '😛': {key: '😛', value: ':stuck_out_tongue:', colons: ':stuck_out_tongue:'},
  '🖲️': {key: '🖲️', value: ':trackball:', colons: ':trackball:'},
  '🇧🇷': {key: '🇧🇷', value: ':flag-br:', colons: ':flag-br:'},
  '🐀': {key: '🐀', value: ':rat:', colons: ':rat:'},
  '🏓': {key: '🏓', value: ':table_tennis_paddle_and_ball:', colons: ':table_tennis_paddle_and_ball:'},
  '💽': {key: '💽', value: ':minidisc:', colons: ':minidisc:'},
  '😜': {key: '😜', value: ':stuck_out_tongue_winking_eye:', colons: ':stuck_out_tongue_winking_eye:'},
  '🍟': {key: '🍟', value: ':fries:', colons: ':fries:'},
  '🏸': {key: '🏸', value: ':badminton_racquet_and_shuttlecock:', colons: ':badminton_racquet_and_shuttlecock:'},
  '🗽': {key: '🗽', value: ':statue_of_liberty:', colons: ':statue_of_liberty:'},
  '🇧🇸': {key: '🇧🇸', value: ':flag-bs:', colons: ':flag-bs:'},
  '⤴️': {key: '⤴️', value: ':arrow_heading_up:', colons: ':arrow_heading_up:'},
  '🐹': {key: '🐹', value: ':hamster:', colons: ':hamster:'},
  '😝': {key: '😝', value: ':stuck_out_tongue_closed_eyes:', colons: ':stuck_out_tongue_closed_eyes:'},
  '🍕': {key: '🍕', value: ':pizza:', colons: ':pizza:'},
  '🥊': {key: '🥊', value: ':boxing_glove:', colons: ':boxing_glove:'},
  '💾': {key: '💾', value: ':floppy_disk:', colons: ':floppy_disk:'},
  '⤵️': {key: '⤵️', value: ':arrow_heading_down:', colons: ':arrow_heading_down:'},
  '🇧🇹': {key: '🇧🇹', value: ':flag-bt:', colons: ':flag-bt:'},
  '🐰': {key: '🐰', value: ':rabbit:', colons: ':rabbit:'},
  '⛪': {key: '⛪', value: ':church:', colons: ':church:'},
  '🤤': {key: '🤤', value: ':drooling_face:', colons: ':drooling_face:'},
  '🇧🇻': {key: '🇧🇻', value: ':flag-bv:', colons: ':flag-bv:'},
  '🕌': {key: '🕌', value: ':mosque:', colons: ':mosque:'},
  '🐇': {key: '🐇', value: ':rabbit2:', colons: ':rabbit2:'},
  '🌭': {key: '🌭', value: ':hotdog:', colons: ':hotdog:'},
  '🥋': {key: '🥋', value: ':martial_arts_uniform:', colons: ':martial_arts_uniform:'},
  '🔃': {key: '🔃', value: ':arrows_clockwise:', colons: ':arrows_clockwise:'},
  '💿': {key: '💿', value: ':cd:', colons: ':cd:'},
  '🔄': {key: '🔄', value: ':arrows_counterclockwise:', colons: ':arrows_counterclockwise:'},
  '🥪': {key: '🥪', value: ':sandwich:', colons: ':sandwich:'},
  '🐿️': {key: '🐿️', value: ':chipmunk:', colons: ':chipmunk:'},
  '🕍': {key: '🕍', value: ':synagogue:', colons: ':synagogue:'},
  '😒': {key: '😒', value: ':unamused:', colons: ':unamused:'},
  '🥅': {key: '🥅', value: ':goal_net:', colons: ':goal_net:'},
  '🇧🇼': {key: '🇧🇼', value: ':flag-bw:', colons: ':flag-bw:'},
  '📀': {key: '📀', value: ':dvd:', colons: ':dvd:'},
  '🦔': {key: '🦔', value: ':hedgehog:', colons: ':hedgehog:'},
  '🎯': {key: '🎯', value: ':dart:', colons: ':dart:'},
  '🌮': {key: '🌮', value: ':taco:', colons: ':taco:'},
  '🔙': {key: '🔙', value: ':back:', colons: ':back:'},
  '🇧🇾': {key: '🇧🇾', value: ':flag-by:', colons: ':flag-by:'},
  '⛩️': {key: '⛩️', value: ':shinto_shrine:', colons: ':shinto_shrine:'},
  '🎥': {key: '🎥', value: ':movie_camera:', colons: ':movie_camera:'},
  '😓': {key: '😓', value: ':sweat:', colons: ':sweat:'},
  '🌯': {key: '🌯', value: ':burrito:', colons: ':burrito:'},
  '🇧🇿': {key: '🇧🇿', value: ':flag-bz:', colons: ':flag-bz:'},
  '😔': {key: '😔', value: ':pensive:', colons: ':pensive:'},
  '🕋': {key: '🕋', value: ':kaaba:', colons: ':kaaba:'},
  '🎞️': {key: '🎞️', value: ':film_frames:', colons: ':film_frames:'},
  '🦇': {key: '🦇', value: ':bat:', colons: ':bat:'},
  '⛳': {key: '⛳', value: ':golf:', colons: ':golf:'},
  '🔚': {key: '🔚', value: ':end:', colons: ':end:'},
  '📽️': {key: '📽️', value: ':film_projector:', colons: ':film_projector:'},
  '🐻': {key: '🐻', value: ':bear:', colons: ':bear:'},
  '⛸️': {key: '⛸️', value: ':ice_skate:', colons: ':ice_skate:'},
  '⛲': {key: '⛲', value: ':fountain:', colons: ':fountain:'},
  '😕': {key: '😕', value: ':confused:', colons: ':confused:'},
  '🇨🇦': {key: '🇨🇦', value: ':flag-ca:', colons: ':flag-ca:'},
  '🔛': {key: '🔛', value: ':on:', colons: ':on:'},
  '🥙': {key: '🥙', value: ':stuffed_flatbread:', colons: ':stuffed_flatbread:'},
  '🔜': {key: '🔜', value: ':soon:', colons: ':soon:'},
  '🙃': {key: '🙃', value: ':upside_down_face:', colons: ':upside_down_face:'},
  '🎣': {key: '🎣', value: ':fishing_pole_and_fish:', colons: ':fishing_pole_and_fish:'},
  '⛺': {key: '⛺', value: ':tent:', colons: ':tent:'},
  '🎬': {key: '🎬', value: ':clapper:', colons: ':clapper:'},
  '🥚': {key: '🥚', value: ':egg:', colons: ':egg:'},
  '🇨🇨': {key: '🇨🇨', value: ':flag-cc:', colons: ':flag-cc:'},
  '🐨': {key: '🐨', value: ':koala:', colons: ':koala:'},
  '🌁': {key: '🌁', value: ':foggy:', colons: ':foggy:'},
  '📺': {key: '📺', value: ':tv:', colons: ':tv:'},
  '🐼': {key: '🐼', value: ':panda_face:', colons: ':panda_face:'},
  '🍳': {key: '🍳', value: ':fried_egg:', colons: ':fried_egg:'},
  '🔝': {key: '🔝', value: ':top:', colons: ':top:'},
  '🇨🇩': {key: '🇨🇩', value: ':flag-cd:', colons: ':flag-cd:'},
  '🤑': {key: '🤑', value: ':money_mouth_face:', colons: ':money_mouth_face:'},
  '🎽': {key: '🎽', value: ':running_shirt_with_sash:', colons: ':running_shirt_with_sash:'},
  '😲': {key: '😲', value: ':astonished:', colons: ':astonished:'},
  '🐾': {key: '🐾', value: ':feet:', colons: ':feet:'},
  '📷': {key: '📷', value: ':camera:', colons: ':camera:'},
  '🇨🇫': {key: '🇨🇫', value: ':flag-cf:', colons: ':flag-cf:'},
  '🛐': {key: '🛐', value: ':place_of_worship:', colons: ':place_of_worship:'},
  '🌃': {key: '🌃', value: ':night_with_stars:', colons: ':night_with_stars:'},
  '🎿': {key: '🎿', value: ':ski:', colons: ':ski:'},
  '🥘': {key: '🥘', value: ':shallow_pan_of_food:', colons: ':shallow_pan_of_food:'},
  '📸': {key: '📸', value: ':camera_with_flash:', colons: ':camera_with_flash:'},
  '🌄': {key: '🌄', value: ':sunrise_over_mountains:', colons: ':sunrise_over_mountains:'},
  '🦃': {key: '🦃', value: ':turkey:', colons: ':turkey:'},
  '☹️': {key: '☹️', value: ':white_frowning_face:', colons: ':white_frowning_face:'},
  '🇨🇬': {key: '🇨🇬', value: ':flag-cg:', colons: ':flag-cg:'},
  '🍲': {key: '🍲', value: ':stew:', colons: ':stew:'},
  '🛷': {key: '🛷', value: ':sled:', colons: ':sled:'},
  '⚛️': {key: '⚛️', value: ':atom_symbol:', colons: ':atom_symbol:'},
  '🥌': {key: '🥌', value: ':curling_stone:', colons: ':curling_stone:'},
  '🙁': {key: '🙁', value: ':slightly_frowning_face:', colons: ':slightly_frowning_face:'},
  '🌅': {key: '🌅', value: ':sunrise:', colons: ':sunrise:'},
  '🕉️': {key: '🕉️', value: ':om_symbol:', colons: ':om_symbol:'},
  '🐔': {key: '🐔', value: ':chicken:', colons: ':chicken:'},
  '🥣': {key: '🥣', value: ':bowl_with_spoon:', colons: ':bowl_with_spoon:'},
  '🇨🇭': {key: '🇨🇭', value: ':flag-ch:', colons: ':flag-ch:'},
  '📹': {key: '📹', value: ':video_camera:', colons: ':video_camera:'},
  '🎮': {key: '🎮', value: ':video_game:', colons: ':video_game:'},
  '🐓': {key: '🐓', value: ':rooster:', colons: ':rooster:'},
  '📼': {key: '📼', value: ':vhs:', colons: ':vhs:'},
  '🌆': {key: '🌆', value: ':city_sunset:', colons: ':city_sunset:'},
  '😖': {key: '😖', value: ':confounded:', colons: ':confounded:'},
  '🥗': {key: '🥗', value: ':green_salad:', colons: ':green_salad:'},
  '✡️': {key: '✡️', value: ':star_of_david:', colons: ':star_of_david:'},
  '🇨🇮': {key: '🇨🇮', value: ':flag-ci:', colons: ':flag-ci:'},
  '🍿': {key: '🍿', value: ':popcorn:', colons: ':popcorn:'},
  '🌇': {key: '🌇', value: ':city_sunrise:', colons: ':city_sunrise:'},
  '😞': {key: '😞', value: ':disappointed:', colons: ':disappointed:'},
  '🔍': {key: '🔍', value: ':mag:', colons: ':mag:'},
  '🐣': {key: '🐣', value: ':hatching_chick:', colons: ':hatching_chick:'},
  '🕹️': {key: '🕹️', value: ':joystick:', colons: ':joystick:'},
  '☸️': {key: '☸️', value: ':wheel_of_dharma:', colons: ':wheel_of_dharma:'},
  '🇨🇰': {key: '🇨🇰', value: ':flag-ck:', colons: ':flag-ck:'},
  '🥫': {key: '🥫', value: ':canned_food:', colons: ':canned_food:'},
  '😟': {key: '😟', value: ':worried:', colons: ':worried:'},
  '🐤': {key: '🐤', value: ':baby_chick:', colons: ':baby_chick:'},
  '🇨🇱': {key: '🇨🇱', value: ':flag-cl:', colons: ':flag-cl:'},
  '🎲': {key: '🎲', value: ':game_die:', colons: ':game_die:'},
  '🔎': {key: '🔎', value: ':mag_right:', colons: ':mag_right:'},
  '☯️': {key: '☯️', value: ':yin_yang:', colons: ':yin_yang:'},
  '🌉': {key: '🌉', value: ':bridge_at_night:', colons: ':bridge_at_night:'},
  '♠️': {key: '♠️', value: ':spades:', colons: ':spades:'},
  '🐥': {key: '🐥', value: ':hatched_chick:', colons: ':hatched_chick:'},
  '🇨🇲': {key: '🇨🇲', value: ':flag-cm:', colons: ':flag-cm:'},
  '✝️': {key: '✝️', value: ':latin_cross:', colons: ':latin_cross:'},
  '😤': {key: '😤', value: ':triumph:', colons: ':triumph:'},
  '♨️': {key: '♨️', value: ':hotsprings:', colons: ':hotsprings:'},
  '🍱': {key: '🍱', value: ':bento:', colons: ':bento:'},
  '🔬': {key: '🔬', value: ':microscope:', colons: ':microscope:'},
  '😢': {key: '😢', value: ':cry:', colons: ':cry:'},
  '🐦': {key: '🐦', value: ':bird:', colons: ':bird:'},
  '🇨🇳': {key: '🇨🇳', value: ':cn:', colons: ':cn:'},
  '🔭': {key: '🔭', value: ':telescope:', colons: ':telescope:'},
  '🍘': {key: '🍘', value: ':rice_cracker:', colons: ':rice_cracker:'},
  '♥️': {key: '♥️', value: ':hearts:', colons: ':hearts:'},
  '☦️': {key: '☦️', value: ':orthodox_cross:', colons: ':orthodox_cross:'},
  '🌌': {key: '🌌', value: ':milky_way:', colons: ':milky_way:'},
  '🍙': {key: '🍙', value: ':rice_ball:', colons: ':rice_ball:'},
  '📡': {key: '📡', value: ':satellite_antenna:', colons: ':satellite_antenna:'},
  '🇨🇴': {key: '🇨🇴', value: ':flag-co:', colons: ':flag-co:'},
  '🎠': {key: '🎠', value: ':carousel_horse:', colons: ':carousel_horse:'},
  '😭': {key: '😭', value: ':sob:', colons: ':sob:'},
  '♦️': {key: '♦️', value: ':diamonds:', colons: ':diamonds:'},
  '☪️': {key: '☪️', value: ':star_and_crescent:', colons: ':star_and_crescent:'},
  '🐧': {key: '🐧', value: ':penguin:', colons: ':penguin:'},
  '🕊️': {key: '🕊️', value: ':dove_of_peace:', colons: ':dove_of_peace:'},
  '🇨🇵': {key: '🇨🇵', value: ':flag-cp:', colons: ':flag-cp:'},
  '🎡': {key: '🎡', value: ':ferris_wheel:', colons: ':ferris_wheel:'},
  '♣️': {key: '♣️', value: ':clubs:', colons: ':clubs:'},
  '☮️': {key: '☮️', value: ':peace_symbol:', colons: ':peace_symbol:'},
  '🕯️': {key: '🕯️', value: ':candle:', colons: ':candle:'},
  '😦': {key: '😦', value: ':frowning:', colons: ':frowning:'},
  '🍚': {key: '🍚', value: ':rice:', colons: ':rice:'},
  '🇨🇷': {key: '🇨🇷', value: ':flag-cr:', colons: ':flag-cr:'},
  '🎢': {key: '🎢', value: ':roller_coaster:', colons: ':roller_coaster:'},
  '🕎': {key: '🕎', value: ':menorah_with_nine_branches:', colons: ':menorah_with_nine_branches:'},
  '🃏': {key: '🃏', value: ':black_joker:', colons: ':black_joker:'},
  '🦅': {key: '🦅', value: ':eagle:', colons: ':eagle:'},
  '🍛': {key: '🍛', value: ':curry:', colons: ':curry:'},
  '💡': {key: '💡', value: ':bulb:', colons: ':bulb:'},
  '😧': {key: '😧', value: ':anguished:', colons: ':anguished:'},
  '🇨🇺': {key: '🇨🇺', value: ':flag-cu:', colons: ':flag-cu:'},
  '💈': {key: '💈', value: ':barber:', colons: ':barber:'},
  '🦆': {key: '🦆', value: ':duck:', colons: ':duck:'},
  '🔯': {key: '🔯', value: ':six_pointed_star:', colons: ':six_pointed_star:'},
  '🍜': {key: '🍜', value: ':ramen:', colons: ':ramen:'},
  '🔦': {key: '🔦', value: ':flashlight:', colons: ':flashlight:'},
  '🀄': {key: '🀄', value: ':mahjong:', colons: ':mahjong:'},
  '😨': {key: '😨', value: ':fearful:', colons: ':fearful:'},
  '♈': {key: '♈', value: ':aries:', colons: ':aries:'},
  '🍝': {key: '🍝', value: ':spaghetti:', colons: ':spaghetti:'},
  '🎪': {key: '🎪', value: ':circus_tent:', colons: ':circus_tent:'},
  '🏮': {key: '🏮', value: ':izakaya_lantern:', colons: ':izakaya_lantern:'},
  '🇨🇻': {key: '🇨🇻', value: ':flag-cv:', colons: ':flag-cv:'},
  '😩': {key: '😩', value: ':weary:', colons: ':weary:'},
  '🎴': {key: '🎴', value: ':flower_playing_cards:', colons: ':flower_playing_cards:'},
  '🦉': {key: '🦉', value: ':owl:', colons: ':owl:'},
  '🎭': {key: '🎭', value: ':performing_arts:', colons: ':performing_arts:'},
  '🐸': {key: '🐸', value: ':frog:', colons: ':frog:'},
  '🇨🇼': {key: '🇨🇼', value: ':flag-cw:', colons: ':flag-cw:'},
  '📔': {key: '📔', value: ':notebook_with_decorative_cover:', colons: ':notebook_with_decorative_cover:'},
  '🤯': {key: '🤯', value: ':exploding_head:', colons: ':exploding_head:'},
  '♉': {key: '♉', value: ':taurus:', colons: ':taurus:'},
  '🍠': {key: '🍠', value: ':sweet_potato:', colons: ':sweet_potato:'},
  '📕': {key: '📕', value: ':closed_book:', colons: ':closed_book:'},
  '♊': {key: '♊', value: ':gemini:', colons: ':gemini:'},
  '🖼️': {key: '🖼️', value: ':frame_with_picture:', colons: ':frame_with_picture:'},
  '🇨🇽': {key: '🇨🇽', value: ':flag-cx:', colons: ':flag-cx:'},
  '😬': {key: '😬', value: ':grimacing:', colons: ':grimacing:'},
  '🐊': {key: '🐊', value: ':crocodile:', colons: ':crocodile:'},
  '🍢': {key: '🍢', value: ':oden:', colons: ':oden:'},
  '🇨🇾': {key: '🇨🇾', value: ':flag-cy:', colons: ':flag-cy:'},
  '📖': {key: '📖', value: ':book:', colons: ':book:'},
  '🐢': {key: '🐢', value: ':turtle:', colons: ':turtle:'},
  '🎨': {key: '🎨', value: ':art:', colons: ':art:'},
  '🍣': {key: '🍣', value: ':sushi:', colons: ':sushi:'},
  '😰': {key: '😰', value: ':cold_sweat:', colons: ':cold_sweat:'},
  '♋': {key: '♋', value: ':cancer:', colons: ':cancer:'},
  '🍤': {key: '🍤', value: ':fried_shrimp:', colons: ':fried_shrimp:'},
  '🎰': {key: '🎰', value: ':slot_machine:', colons: ':slot_machine:'},
  '😱': {key: '😱', value: ':scream:', colons: ':scream:'},
  '📗': {key: '📗', value: ':green_book:', colons: ':green_book:'},
  '♌': {key: '♌', value: ':leo:', colons: ':leo:'},
  '🇨🇿': {key: '🇨🇿', value: ':flag-cz:', colons: ':flag-cz:'},
  '🦎': {key: '🦎', value: ':lizard:', colons: ':lizard:'},
  '♍': {key: '♍', value: ':virgo:', colons: ':virgo:'},
  '🚂': {key: '🚂', value: ':steam_locomotive:', colons: ':steam_locomotive:'},
  '🇩🇪': {key: '🇩🇪', value: ':de:', colons: ':de:'},
  '😳': {key: '😳', value: ':flushed:', colons: ':flushed:'},
  '📘': {key: '📘', value: ':blue_book:', colons: ':blue_book:'},
  '🐍': {key: '🐍', value: ':snake:', colons: ':snake:'},
  '🍥': {key: '🍥', value: ':fish_cake:', colons: ':fish_cake:'},
  '🚃': {key: '🚃', value: ':railway_car:', colons: ':railway_car:'},
  '🍡': {key: '🍡', value: ':dango:', colons: ':dango:'},
  '📙': {key: '📙', value: ':orange_book:', colons: ':orange_book:'},
  '♎': {key: '♎', value: ':libra:', colons: ':libra:'},
  '🐲': {key: '🐲', value: ':dragon_face:', colons: ':dragon_face:'},
  '🇩🇬': {key: '🇩🇬', value: ':flag-dg:', colons: ':flag-dg:'},
  '🤪': {key: '🤪', value: ':zany_face:', colons: ':zany_face:'},
  '📚': {key: '📚', value: ':books:', colons: ':books:'},
  '🐉': {key: '🐉', value: ':dragon:', colons: ':dragon:'},
  '🇩🇯': {key: '🇩🇯', value: ':flag-dj:', colons: ':flag-dj:'},
  '🥟': {key: '🥟', value: ':dumpling:', colons: ':dumpling:'},
  '😵': {key: '😵', value: ':dizzy_face:', colons: ':dizzy_face:'},
  '♏': {key: '♏', value: ':scorpius:', colons: ':scorpius:'},
  '🚄': {key: '🚄', value: ':bullettrain_side:', colons: ':bullettrain_side:'},
  '🚅': {key: '🚅', value: ':bullettrain_front:', colons: ':bullettrain_front:'},
  '📓': {key: '📓', value: ':notebook:', colons: ':notebook:'},
  '🥠': {key: '🥠', value: ':fortune_cookie:', colons: ':fortune_cookie:'},
  '♐': {key: '♐', value: ':sagittarius:', colons: ':sagittarius:'},
  '🦕': {key: '🦕', value: ':sauropod:', colons: ':sauropod:'},
  '🇩🇰': {key: '🇩🇰', value: ':flag-dk:', colons: ':flag-dk:'},
  '😡': {key: '😡', value: ':rage:', colons: ':rage:'},
  '📒': {key: '📒', value: ':ledger:', colons: ':ledger:'},
  '😠': {key: '😠', value: ':angry:', colons: ':angry:'},
  '🦖': {key: '🦖', value: ':t-rex:', colons: ':t-rex:'},
  '♑': {key: '♑', value: ':capricorn:', colons: ':capricorn:'},
  '🥡': {key: '🥡', value: ':takeout_box:', colons: ':takeout_box:'},
  '🇩🇲': {key: '🇩🇲', value: ':flag-dm:', colons: ':flag-dm:'},
  '🚆': {key: '🚆', value: ':train2:', colons: ':train2:'},
  '📃': {key: '📃', value: ':page_with_curl:', colons: ':page_with_curl:'},
  '🐳': {key: '🐳', value: ':whale:', colons: ':whale:'},
  '🤬': {key: '🤬', value: ':face_with_symbols_on_mouth:', colons: ':face_with_symbols_on_mouth:'},
  '🇩🇴': {key: '🇩🇴', value: ':flag-do:', colons: ':flag-do:'},
  '🚇': {key: '🚇', value: ':metro:', colons: ':metro:'},
  '🍦': {key: '🍦', value: ':icecream:', colons: ':icecream:'},
  '♒': {key: '♒', value: ':aquarius:', colons: ':aquarius:'},
  '🇩🇿': {key: '🇩🇿', value: ':flag-dz:', colons: ':flag-dz:'},
  '🐋': {key: '🐋', value: ':whale2:', colons: ':whale2:'},
  '😷': {key: '😷', value: ':mask:', colons: ':mask:'},
  '📜': {key: '📜', value: ':scroll:', colons: ':scroll:'},
  '🍧': {key: '🍧', value: ':shaved_ice:', colons: ':shaved_ice:'},
  '♓': {key: '♓', value: ':pisces:', colons: ':pisces:'},
  '🚈': {key: '🚈', value: ':light_rail:', colons: ':light_rail:'},
  '🐬': {key: '🐬', value: ':dolphin:', colons: ':dolphin:'},
  '🤒': {key: '🤒', value: ':face_with_thermometer:', colons: ':face_with_thermometer:'},
  '🇪🇦': {key: '🇪🇦', value: ':flag-ea:', colons: ':flag-ea:'},
  '⛎': {key: '⛎', value: ':ophiuchus:', colons: ':ophiuchus:'},
  '🚉': {key: '🚉', value: ':station:', colons: ':station:'},
  '🍨': {key: '🍨', value: ':ice_cream:', colons: ':ice_cream:'},
  '📄': {key: '📄', value: ':page_facing_up:', colons: ':page_facing_up:'},
  '🍩': {key: '🍩', value: ':doughnut:', colons: ':doughnut:'},
  '🤕': {key: '🤕', value: ':face_with_head_bandage:', colons: ':face_with_head_bandage:'},
  '🐟': {key: '🐟', value: ':fish:', colons: ':fish:'},
  '📰': {key: '📰', value: ':newspaper:', colons: ':newspaper:'},
  '🚊': {key: '🚊', value: ':tram:', colons: ':tram:'},
  '🇪🇨': {key: '🇪🇨', value: ':flag-ec:', colons: ':flag-ec:'},
  '🔀': {key: '🔀', value: ':twisted_rightwards_arrows:', colons: ':twisted_rightwards_arrows:'},
  '🇪🇪': {key: '🇪🇪', value: ':flag-ee:', colons: ':flag-ee:'},
  '🍪': {key: '🍪', value: ':cookie:', colons: ':cookie:'},
  '🚝': {key: '🚝', value: ':monorail:', colons: ':monorail:'},
  '🐠': {key: '🐠', value: ':tropical_fish:', colons: ':tropical_fish:'},
  '🗞️': {key: '🗞️', value: ':rolled_up_newspaper:', colons: ':rolled_up_newspaper:'},
  '🤢': {key: '🤢', value: ':nauseated_face:', colons: ':nauseated_face:'},
  '🔁': {key: '🔁', value: ':repeat:', colons: ':repeat:'},
  '📑': {key: '📑', value: ':bookmark_tabs:', colons: ':bookmark_tabs:'},
  '🔂': {key: '🔂', value: ':repeat_one:', colons: ':repeat_one:'},
  '🇪🇬': {key: '🇪🇬', value: ':flag-eg:', colons: ':flag-eg:'},
  '🚞': {key: '🚞', value: ':mountain_railway:', colons: ':mountain_railway:'},
  '🎂': {key: '🎂', value: ':birthday:', colons: ':birthday:'},
  '🐡': {key: '🐡', value: ':blowfish:', colons: ':blowfish:'},
  '🤮': {key: '🤮', value: ':face_vomiting:', colons: ':face_vomiting:'},
  '▶️': {key: '▶️', value: ':arrow_forward:', colons: ':arrow_forward:'},
  '🔖': {key: '🔖', value: ':bookmark:', colons: ':bookmark:'},
  '🇪🇭': {key: '🇪🇭', value: ':flag-eh:', colons: ':flag-eh:'},
  '🦈': {key: '🦈', value: ':shark:', colons: ':shark:'},
  '🚋': {key: '🚋', value: ':train:', colons: ':train:'},
  '🤧': {key: '🤧', value: ':sneezing_face:', colons: ':sneezing_face:'},
  '🍰': {key: '🍰', value: ':cake:', colons: ':cake:'},
  '🚌': {key: '🚌', value: ':bus:', colons: ':bus:'},
  '🥧': {key: '🥧', value: ':pie:', colons: ':pie:'},
  '😇': {key: '😇', value: ':innocent:', colons: ':innocent:'},
  '⏩': {key: '⏩', value: ':fast_forward:', colons: ':fast_forward:'},
  '🏷️': {key: '🏷️', value: ':label:', colons: ':label:'},
  '🐙': {key: '🐙', value: ':octopus:', colons: ':octopus:'},
  '🇪🇷': {key: '🇪🇷', value: ':flag-er:', colons: ':flag-er:'},
  '⏭️': {
    key: '⏭️',
    value: ':black_right_pointing_double_triangle_with_vertical_bar:',
    colons: ':black_right_pointing_double_triangle_with_vertical_bar:'
  },
  '🍫': {key: '🍫', value: ':chocolate_bar:', colons: ':chocolate_bar:'},
  '🚍': {key: '🚍', value: ':oncoming_bus:', colons: ':oncoming_bus:'},
  '🐚': {key: '🐚', value: ':shell:', colons: ':shell:'},
  '🤠': {key: '🤠', value: ':face_with_cowboy_hat:', colons: ':face_with_cowboy_hat:'},
  '💰': {key: '💰', value: ':moneybag:', colons: ':moneybag:'},
  '🇪🇸': {key: '🇪🇸', value: ':es:', colons: ':es:'},
  '🦀': {key: '🦀', value: ':crab:', colons: ':crab:'},
  '💴': {key: '💴', value: ':yen:', colons: ':yen:'},
  '🇪🇹': {key: '🇪🇹', value: ':flag-et:', colons: ':flag-et:'},
  '🤡': {key: '🤡', value: ':clown_face:', colons: ':clown_face:'},
  '⏯️': {
    key: '⏯️',
    value: ':black_right_pointing_triangle_with_double_vertical_bar:',
    colons: ':black_right_pointing_triangle_with_double_vertical_bar:'
  },
  '🚎': {key: '🚎', value: ':trolleybus:', colons: ':trolleybus:'},
  '🍬': {key: '🍬', value: ':candy:', colons: ':candy:'},
  '🤥': {key: '🤥', value: ':lying_face:', colons: ':lying_face:'},
  '◀️': {key: '◀️', value: ':arrow_backward:', colons: ':arrow_backward:'},
  '💵': {key: '💵', value: ':dollar:', colons: ':dollar:'},
  '🦐': {key: '🦐', value: ':shrimp:', colons: ':shrimp:'},
  '🚐': {key: '🚐', value: ':minibus:', colons: ':minibus:'},
  '🇪🇺': {key: '🇪🇺', value: ':flag-eu:', colons: ':flag-eu:'},
  '🍭': {key: '🍭', value: ':lollipop:', colons: ':lollipop:'},
  '🦑': {key: '🦑', value: ':squid:', colons: ':squid:'},
  '💶': {key: '💶', value: ':euro:', colons: ':euro:'},
  '🇫🇮': {key: '🇫🇮', value: ':flag-fi:', colons: ':flag-fi:'},
  '🚑': {key: '🚑', value: ':ambulance:', colons: ':ambulance:'},
  '🍮': {key: '🍮', value: ':custard:', colons: ':custard:'},
  '🤫': {key: '🤫', value: ':shushing_face:', colons: ':shushing_face:'},
  '⏪': {key: '⏪', value: ':rewind:', colons: ':rewind:'},
  '⏮️': {
    key: '⏮️',
    value: ':black_left_pointing_double_triangle_with_vertical_bar:',
    colons: ':black_left_pointing_double_triangle_with_vertical_bar:'
  },
  '🤭': {key: '🤭', value: ':face_with_hand_over_mouth:', colons: ':face_with_hand_over_mouth:'},
  '🇫🇯': {key: '🇫🇯', value: ':flag-fj:', colons: ':flag-fj:'},
  '🍯': {key: '🍯', value: ':honey_pot:', colons: ':honey_pot:'},
  '🐌': {key: '🐌', value: ':snail:', colons: ':snail:'},
  '💷': {key: '💷', value: ':pound:', colons: ':pound:'},
  '🚒': {key: '🚒', value: ':fire_engine:', colons: ':fire_engine:'},
  '🍼': {key: '🍼', value: ':baby_bottle:', colons: ':baby_bottle:'},
  '🇫🇰': {key: '🇫🇰', value: ':flag-fk:', colons: ':flag-fk:'},
  '🦋': {key: '🦋', value: ':butterfly:', colons: ':butterfly:'},
  '💸': {key: '💸', value: ':money_with_wings:', colons: ':money_with_wings:'},
  '🧐': {key: '🧐', value: ':face_with_monocle:', colons: ':face_with_monocle:'},
  '🚓': {key: '🚓', value: ':police_car:', colons: ':police_car:'},
  '🔼': {key: '🔼', value: ':arrow_up_small:', colons: ':arrow_up_small:'},
  '🇫🇲': {key: '🇫🇲', value: ':flag-fm:', colons: ':flag-fm:'},
  '🥛': {key: '🥛', value: ':glass_of_milk:', colons: ':glass_of_milk:'},
  '💳': {key: '💳', value: ':credit_card:', colons: ':credit_card:'},
  '🚔': {key: '🚔', value: ':oncoming_police_car:', colons: ':oncoming_police_car:'},
  '🐛': {key: '🐛', value: ':bug:', colons: ':bug:'},
  '🤓': {key: '🤓', value: ':nerd_face:', colons: ':nerd_face:'},
  '⏫': {key: '⏫', value: ':arrow_double_up:', colons: ':arrow_double_up:'},
  '💹': {key: '💹', value: ':chart:', colons: ':chart:'},
  '🇫🇴': {key: '🇫🇴', value: ':flag-fo:', colons: ':flag-fo:'},
  '🐜': {key: '🐜', value: ':ant:', colons: ':ant:'},
  '🔽': {key: '🔽', value: ':arrow_down_small:', colons: ':arrow_down_small:'},
  '😈': {key: '😈', value: ':smiling_imp:', colons: ':smiling_imp:'},
  '🚕': {key: '🚕', value: ':taxi:', colons: ':taxi:'},
  '☕': {key: '☕', value: ':coffee:', colons: ':coffee:'},
  '🇫🇷': {key: '🇫🇷', value: ':fr:', colons: ':fr:'},
  '🚖': {key: '🚖', value: ':oncoming_taxi:', colons: ':oncoming_taxi:'},
  '⏬': {key: '⏬', value: ':arrow_double_down:', colons: ':arrow_double_down:'},
  '👿': {key: '👿', value: ':imp:', colons: ':imp:'},
  '💱': {key: '💱', value: ':currency_exchange:', colons: ':currency_exchange:'},
  '🍵': {key: '🍵', value: ':tea:', colons: ':tea:'},
  '🐝': {key: '🐝', value: ':bee:', colons: ':bee:'},
  '💲': {key: '💲', value: ':heavy_dollar_sign:', colons: ':heavy_dollar_sign:'},
  '🚗': {key: '🚗', value: ':car:', colons: ':car:'},
  '🍶': {key: '🍶', value: ':sake:', colons: ':sake:'},
  '🇬🇦': {key: '🇬🇦', value: ':flag-ga:', colons: ':flag-ga:'},
  '🐞': {key: '🐞', value: ':beetle:', colons: ':beetle:'},
  '👹': {key: '👹', value: ':japanese_ogre:', colons: ':japanese_ogre:'},
  '⏸️': {key: '⏸️', value: ':double_vertical_bar:', colons: ':double_vertical_bar:'},
  '🍾': {key: '🍾', value: ':champagne:', colons: ':champagne:'},
  '👺': {key: '👺', value: ':japanese_goblin:', colons: ':japanese_goblin:'},
  '⏹️': {key: '⏹️', value: ':black_square_for_stop:', colons: ':black_square_for_stop:'},
  '🚘': {key: '🚘', value: ':oncoming_automobile:', colons: ':oncoming_automobile:'},
  '✉️': {key: '✉️', value: ':email:', colons: ':email:'},
  '🦗': {key: '🦗', value: ':cricket:', colons: ':cricket:'},
  '🇬🇧': {key: '🇬🇧', value: ':gb:', colons: ':gb:'},
  '⏺️': {key: '⏺️', value: ':black_circle_for_record:', colons: ':black_circle_for_record:'},
  '🇬🇩': {key: '🇬🇩', value: ':flag-gd:', colons: ':flag-gd:'},
  '🕷️': {key: '🕷️', value: ':spider:', colons: ':spider:'},
  '🚙': {key: '🚙', value: ':blue_car:', colons: ':blue_car:'},
  '💀': {key: '💀', value: ':skull:', colons: ':skull:'},
  '📧': {key: '📧', value: ':e-mail:', colons: ':e-mail:'},
  '🍷': {key: '🍷', value: ':wine_glass:', colons: ':wine_glass:'},
  '🕸️': {key: '🕸️', value: ':spider_web:', colons: ':spider_web:'},
  '🍸': {key: '🍸', value: ':cocktail:', colons: ':cocktail:'},
  '☠️': {key: '☠️', value: ':skull_and_crossbones:', colons: ':skull_and_crossbones:'},
  '🇬🇪': {key: '🇬🇪', value: ':flag-ge:', colons: ':flag-ge:'},
  '⏏️': {key: '⏏️', value: ':eject:', colons: ':eject:'},
  '🚚': {key: '🚚', value: ':truck:', colons: ':truck:'},
  '📨': {key: '📨', value: ':incoming_envelope:', colons: ':incoming_envelope:'},
  '🍹': {key: '🍹', value: ':tropical_drink:', colons: ':tropical_drink:'},
  '🦂': {key: '🦂', value: ':scorpion:', colons: ':scorpion:'},
  '🎦': {key: '🎦', value: ':cinema:', colons: ':cinema:'},
  '🚛': {key: '🚛', value: ':articulated_lorry:', colons: ':articulated_lorry:'},
  '📩': {key: '📩', value: ':envelope_with_arrow:', colons: ':envelope_with_arrow:'},
  '👻': {key: '👻', value: ':ghost:', colons: ':ghost:'},
  '🇬🇫': {key: '🇬🇫', value: ':flag-gf:', colons: ':flag-gf:'},
  '💐': {key: '💐', value: ':bouquet:', colons: ':bouquet:'},
  '🚜': {key: '🚜', value: ':tractor:', colons: ':tractor:'},
  '🍺': {key: '🍺', value: ':beer:', colons: ':beer:'},
  '📤': {key: '📤', value: ':outbox_tray:', colons: ':outbox_tray:'},
  '🔅': {key: '🔅', value: ':low_brightness:', colons: ':low_brightness:'},
  '👽': {key: '👽', value: ':alien:', colons: ':alien:'},
  '🇬🇬': {key: '🇬🇬', value: ':flag-gg:', colons: ':flag-gg:'},
  '🌸': {key: '🌸', value: ':cherry_blossom:', colons: ':cherry_blossom:'},
  '📥': {key: '📥', value: ':inbox_tray:', colons: ':inbox_tray:'},
  '🇬🇭': {key: '🇬🇭', value: ':flag-gh:', colons: ':flag-gh:'},
  '🚲': {key: '🚲', value: ':bike:', colons: ':bike:'},
  '👾': {key: '👾', value: ':space_invader:', colons: ':space_invader:'},
  '🍻': {key: '🍻', value: ':beers:', colons: ':beers:'},
  '🔆': {key: '🔆', value: ':high_brightness:', colons: ':high_brightness:'},
  '📦': {key: '📦', value: ':package:', colons: ':package:'},
  '🛴': {key: '🛴', value: ':scooter:', colons: ':scooter:'},
  '💮': {key: '💮', value: ':white_flower:', colons: ':white_flower:'},
  '🥂': {key: '🥂', value: ':clinking_glasses:', colons: ':clinking_glasses:'},
  '🤖': {key: '🤖', value: ':robot_face:', colons: ':robot_face:'},
  '📶': {key: '📶', value: ':signal_strength:', colons: ':signal_strength:'},
  '🇬🇮': {key: '🇬🇮', value: ':flag-gi:', colons: ':flag-gi:'},
  '🇬🇱': {key: '🇬🇱', value: ':flag-gl:', colons: ':flag-gl:'},
  '🛵': {key: '🛵', value: ':motor_scooter:', colons: ':motor_scooter:'},
  '📫': {key: '📫', value: ':mailbox:', colons: ':mailbox:'},
  '📳': {key: '📳', value: ':vibration_mode:', colons: ':vibration_mode:'},
  '💩': {key: '💩', value: ':hankey:', colons: ':hankey:'},
  '🏵️': {key: '🏵️', value: ':rosette:', colons: ':rosette:'},
  '🥃': {key: '🥃', value: ':tumbler_glass:', colons: ':tumbler_glass:'},
  '🥤': {key: '🥤', value: ':cup_with_straw:', colons: ':cup_with_straw:'},
  '🇬🇲': {key: '🇬🇲', value: ':flag-gm:', colons: ':flag-gm:'},
  '📪': {key: '📪', value: ':mailbox_closed:', colons: ':mailbox_closed:'},
  '📴': {key: '📴', value: ':mobile_phone_off:', colons: ':mobile_phone_off:'},
  '🚏': {key: '🚏', value: ':busstop:', colons: ':busstop:'},
  '😺': {key: '😺', value: ':smiley_cat:', colons: ':smiley_cat:'},
  '🌹': {key: '🌹', value: ':rose:', colons: ':rose:'},
  '🛣️': {key: '🛣️', value: ':motorway:', colons: ':motorway:'},
  '😸': {key: '😸', value: ':smile_cat:', colons: ':smile_cat:'},
  '🇬🇳': {key: '🇬🇳', value: ':flag-gn:', colons: ':flag-gn:'},
  '🥀': {key: '🥀', value: ':wilted_flower:', colons: ':wilted_flower:'},
  '📬': {key: '📬', value: ':mailbox_with_mail:', colons: ':mailbox_with_mail:'},
  '🥢': {key: '🥢', value: ':chopsticks:', colons: ':chopsticks:'},
  '♀️': {key: '♀️', value: ':female_sign:', colons: ':female_sign:'},
  '📭': {key: '📭', value: ':mailbox_with_no_mail:', colons: ':mailbox_with_no_mail:'},
  '🍽️': {key: '🍽️', value: ':knife_fork_plate:', colons: ':knife_fork_plate:'},
  '🌺': {key: '🌺', value: ':hibiscus:', colons: ':hibiscus:'},
  '🇬🇵': {key: '🇬🇵', value: ':flag-gp:', colons: ':flag-gp:'},
  '🛤️': {key: '🛤️', value: ':railway_track:', colons: ':railway_track:'},
  '♂️': {key: '♂️', value: ':male_sign:', colons: ':male_sign:'},
  '😹': {key: '😹', value: ':joy_cat:', colons: ':joy_cat:'},
  '⛽': {key: '⛽', value: ':fuelpump:', colons: ':fuelpump:'},
  '🌻': {key: '🌻', value: ':sunflower:', colons: ':sunflower:'},
  '📮': {key: '📮', value: ':postbox:', colons: ':postbox:'},
  '🇬🇶': {key: '🇬🇶', value: ':flag-gq:', colons: ':flag-gq:'},
  '😻': {key: '😻', value: ':heart_eyes_cat:', colons: ':heart_eyes_cat:'},
  '🍴': {key: '🍴', value: ':fork_and_knife:', colons: ':fork_and_knife:'},
  '⚕️': {key: '⚕️', value: ':medical_symbol:', colons: ':medical_symbol:'},
  '♻️': {key: '♻️', value: ':recycle:', colons: ':recycle:'},
  '🥄': {key: '🥄', value: ':spoon:', colons: ':spoon:'},
  '🌼': {key: '🌼', value: ':blossom:', colons: ':blossom:'},
  '🚨': {key: '🚨', value: ':rotating_light:', colons: ':rotating_light:'},
  '😼': {key: '😼', value: ':smirk_cat:', colons: ':smirk_cat:'},
  '🗳️': {key: '🗳️', value: ':ballot_box_with_ballot:', colons: ':ballot_box_with_ballot:'},
  '🇬🇷': {key: '🇬🇷', value: ':flag-gr:', colons: ':flag-gr:'},
  '😽': {key: '😽', value: ':kissing_cat:', colons: ':kissing_cat:'},
  '✏️': {key: '✏️', value: ':pencil2:', colons: ':pencil2:'},
  '🚥': {key: '🚥', value: ':traffic_light:', colons: ':traffic_light:'},
  '⚜️': {key: '⚜️', value: ':fleur_de_lis:', colons: ':fleur_de_lis:'},
  '🌷': {key: '🌷', value: ':tulip:', colons: ':tulip:'},
  '🔪': {key: '🔪', value: ':hocho:', colons: ':hocho:'},
  '🇬🇸': {key: '🇬🇸', value: ':flag-gs:', colons: ':flag-gs:'},
  '🌱': {key: '🌱', value: ':seedling:', colons: ':seedling:'},
  '🏺': {key: '🏺', value: ':amphora:', colons: ':amphora:'},
  '🙀': {key: '🙀', value: ':scream_cat:', colons: ':scream_cat:'},
  '🚦': {key: '🚦', value: ':vertical_traffic_light:', colons: ':vertical_traffic_light:'},
  '✒️': {key: '✒️', value: ':black_nib:', colons: ':black_nib:'},
  '🇬🇹': {key: '🇬🇹', value: ':flag-gt:', colons: ':flag-gt:'},
  '🔱': {key: '🔱', value: ':trident:', colons: ':trident:'},
  '🇬🇺': {key: '🇬🇺', value: ':flag-gu:', colons: ':flag-gu:'},
  '📛': {key: '📛', value: ':name_badge:', colons: ':name_badge:'},
  '🚧': {key: '🚧', value: ':construction:', colons: ':construction:'},
  '🖋️': {key: '🖋️', value: ':lower_left_fountain_pen:', colons: ':lower_left_fountain_pen:'},
  '🌲': {key: '🌲', value: ':evergreen_tree:', colons: ':evergreen_tree:'},
  '😿': {key: '😿', value: ':crying_cat_face:', colons: ':crying_cat_face:'},
  '🇬🇼': {key: '🇬🇼', value: ':flag-gw:', colons: ':flag-gw:'},
  '🖊️': {key: '🖊️', value: ':lower_left_ballpoint_pen:', colons: ':lower_left_ballpoint_pen:'},
  '😾': {key: '😾', value: ':pouting_cat:', colons: ':pouting_cat:'},
  '🌳': {key: '🌳', value: ':deciduous_tree:', colons: ':deciduous_tree:'},
  '🛑': {key: '🛑', value: ':octagonal_sign:', colons: ':octagonal_sign:'},
  '🔰': {key: '🔰', value: ':beginner:', colons: ':beginner:'},
  '🇬🇾': {key: '🇬🇾', value: ':flag-gy:', colons: ':flag-gy:'},
  '🖌️': {key: '🖌️', value: ':lower_left_paintbrush:', colons: ':lower_left_paintbrush:'},
  '⭕': {key: '⭕', value: ':o:', colons: ':o:'},
  '🌴': {key: '🌴', value: ':palm_tree:', colons: ':palm_tree:'},
  '⚓': {key: '⚓', value: ':anchor:', colons: ':anchor:'},
  '🙈': {key: '🙈', value: ':see_no_evil:', colons: ':see_no_evil:'},
  '⛵': {key: '⛵', value: ':boat:', colons: ':boat:'},
  '✅': {key: '✅', value: ':white_check_mark:', colons: ':white_check_mark:'},
  '🇭🇰': {key: '🇭🇰', value: ':flag-hk:', colons: ':flag-hk:'},
  '🖍️': {key: '🖍️', value: ':lower_left_crayon:', colons: ':lower_left_crayon:'},
  '🙉': {key: '🙉', value: ':hear_no_evil:', colons: ':hear_no_evil:'},
  '🌵': {key: '🌵', value: ':cactus:', colons: ':cactus:'},
  '🌾': {key: '🌾', value: ':ear_of_rice:', colons: ':ear_of_rice:'},
  '🙊': {key: '🙊', value: ':speak_no_evil:', colons: ':speak_no_evil:'},
  '🇭🇲': {key: '🇭🇲', value: ':flag-hm:', colons: ':flag-hm:'},
  '☑️': {key: '☑️', value: ':ballot_box_with_check:', colons: ':ballot_box_with_check:'},
  '🛶': {key: '🛶', value: ':canoe:', colons: ':canoe:'},
  '📝': {key: '📝', value: ':memo:', colons: ':memo:'},
  '🌿': {key: '🌿', value: ':herb:', colons: ':herb:'},
  '🇭🇳': {key: '🇭🇳', value: ':flag-hn:', colons: ':flag-hn:'},
  '✔️': {key: '✔️', value: ':heavy_check_mark:', colons: ':heavy_check_mark:'},
  '💼': {key: '💼', value: ':briefcase:', colons: ':briefcase:'},
  '🚤': {key: '🚤', value: ':speedboat:', colons: ':speedboat:'},
  '👶': {key: '👶', value: ':baby:', colons: ':baby:'},
  '✖️': {key: '✖️', value: ':heavy_multiplication_x:', colons: ':heavy_multiplication_x:'},
  '🧒': {key: '🧒', value: ':child:', colons: ':child:'},
  '☘️': {key: '☘️', value: ':shamrock:', colons: ':shamrock:'},
  '🛳️': {key: '🛳️', value: ':passenger_ship:', colons: ':passenger_ship:'},
  '🇭🇷': {key: '🇭🇷', value: ':flag-hr:', colons: ':flag-hr:'},
  '📁': {key: '📁', value: ':file_folder:', colons: ':file_folder:'},
  '❌': {key: '❌', value: ':x:', colons: ':x:'},
  '🍀': {key: '🍀', value: ':four_leaf_clover:', colons: ':four_leaf_clover:'},
  '📂': {key: '📂', value: ':open_file_folder:', colons: ':open_file_folder:'},
  '👦': {key: '👦', value: ':boy:', colons: ':boy:'},
  '⛴️': {key: '⛴️', value: ':ferry:', colons: ':ferry:'},
  '🇭🇹': {key: '🇭🇹', value: ':flag-ht:', colons: ':flag-ht:'},
  '👧': {key: '👧', value: ':girl:', colons: ':girl:'},
  '❎': {key: '❎', value: ':negative_squared_cross_mark:', colons: ':negative_squared_cross_mark:'},
  '🇭🇺': {key: '🇭🇺', value: ':flag-hu:', colons: ':flag-hu:'},
  '🗂️': {key: '🗂️', value: ':card_index_dividers:', colons: ':card_index_dividers:'},
  '🍁': {key: '🍁', value: ':maple_leaf:', colons: ':maple_leaf:'},
  '🛥️': {key: '🛥️', value: ':motor_boat:', colons: ':motor_boat:'},
  '🇮🇨': {key: '🇮🇨', value: ':flag-ic:', colons: ':flag-ic:'},
  '🍂': {key: '🍂', value: ':fallen_leaf:', colons: ':fallen_leaf:'},
  '🧑': {key: '🧑', value: ':adult:', colons: ':adult:'},
  '🚢': {key: '🚢', value: ':ship:', colons: ':ship:'},
  '➕': {key: '➕', value: ':heavy_plus_sign:', colons: ':heavy_plus_sign:'},
  '📅': {key: '📅', value: ':date:', colons: ':date:'},
  '👨': {key: '👨', value: ':man:', colons: ':man:'},
  '🇮🇩': {key: '🇮🇩', value: ':flag-id:', colons: ':flag-id:'},
  '🍃': {key: '🍃', value: ':leaves:', colons: ':leaves:'},
  '➖': {key: '➖', value: ':heavy_minus_sign:', colons: ':heavy_minus_sign:'},
  '📆': {key: '📆', value: ':calendar:', colons: ':calendar:'},
  '✈️': {key: '✈️', value: ':airplane:', colons: ':airplane:'},
  '🗒️': {key: '🗒️', value: ':spiral_note_pad:', colons: ':spiral_note_pad:'},
  '➗': {key: '➗', value: ':heavy_division_sign:', colons: ':heavy_division_sign:'},
  '🛩️': {key: '🛩️', value: ':small_airplane:', colons: ':small_airplane:'},
  '👩': {key: '👩', value: ':woman:', colons: ':woman:'},
  '🇮🇪': {key: '🇮🇪', value: ':flag-ie:', colons: ':flag-ie:'},
  '➰': {key: '➰', value: ':curly_loop:', colons: ':curly_loop:'},
  '🇮🇱': {key: '🇮🇱', value: ':flag-il:', colons: ':flag-il:'},
  '🛫': {key: '🛫', value: ':airplane_departure:', colons: ':airplane_departure:'},
  '🗓️': {key: '🗓️', value: ':spiral_calendar_pad:', colons: ':spiral_calendar_pad:'},
  '🧓': {key: '🧓', value: ':older_adult:', colons: ':older_adult:'},
  '🛬': {key: '🛬', value: ':airplane_arriving:', colons: ':airplane_arriving:'},
  '📇': {key: '📇', value: ':card_index:', colons: ':card_index:'},
  '➿': {key: '➿', value: ':loop:', colons: ':loop:'},
  '👴': {key: '👴', value: ':older_man:', colons: ':older_man:'},
  '🇮🇲': {key: '🇮🇲', value: ':flag-im:', colons: ':flag-im:'},
  '🇮🇳': {key: '🇮🇳', value: ':flag-in:', colons: ':flag-in:'},
  '📈': {key: '📈', value: ':chart_with_upwards_trend:', colons: ':chart_with_upwards_trend:'},
  '〽️': {key: '〽️', value: ':part_alternation_mark:', colons: ':part_alternation_mark:'},
  '💺': {key: '💺', value: ':seat:', colons: ':seat:'},
  '👵': {key: '👵', value: ':older_woman:', colons: ':older_woman:'},
  '✳️': {key: '✳️', value: ':eight_spoked_asterisk:', colons: ':eight_spoked_asterisk:'},
  '📉': {key: '📉', value: ':chart_with_downwards_trend:', colons: ':chart_with_downwards_trend:'},
  '🇮🇴': {key: '🇮🇴', value: ':flag-io:', colons: ':flag-io:'},
  '👨‍⚕️': {key: '👨‍⚕️', value: ':male-doctor:', colons: ':male-doctor:'},
  '🚁': {key: '🚁', value: ':helicopter:', colons: ':helicopter:'},
  '👩‍⚕️': {key: '👩‍⚕️', value: ':female-doctor:', colons: ':female-doctor:'},
  '🚟': {key: '🚟', value: ':suspension_railway:', colons: ':suspension_railway:'},
  '📊': {key: '📊', value: ':bar_chart:', colons: ':bar_chart:'},
  '🇮🇶': {key: '🇮🇶', value: ':flag-iq:', colons: ':flag-iq:'},
  '✴️': {key: '✴️', value: ':eight_pointed_black_star:', colons: ':eight_pointed_black_star:'},
  '🚠': {key: '🚠', value: ':mountain_cableway:', colons: ':mountain_cableway:'},
  '👨‍🎓': {key: '👨‍🎓', value: ':male-student:', colons: ':male-student:'},
  '📋': {key: '📋', value: ':clipboard:', colons: ':clipboard:'},
  '🇮🇷': {key: '🇮🇷', value: ':flag-ir:', colons: ':flag-ir:'},
  '❇️': {key: '❇️', value: ':sparkle:', colons: ':sparkle:'},
  '👩‍🎓': {key: '👩‍🎓', value: ':female-student:', colons: ':female-student:'},
  '📌': {key: '📌', value: ':pushpin:', colons: ':pushpin:'},
  '🚡': {key: '🚡', value: ':aerial_tramway:', colons: ':aerial_tramway:'},
  '🇮🇸': {key: '🇮🇸', value: ':flag-is:', colons: ':flag-is:'},
  '‼️': {key: '‼️', value: ':bangbang:', colons: ':bangbang:'},
  '⁉️': {key: '⁉️', value: ':interrobang:', colons: ':interrobang:'},
  '🛰️': {key: '🛰️', value: ':satellite:', colons: ':satellite:'},
  '🇮🇹': {key: '🇮🇹', value: ':it:', colons: ':it:'},
  '👨‍🏫': {key: '👨‍🏫', value: ':male-teacher:', colons: ':male-teacher:'},
  '📍': {key: '📍', value: ':round_pushpin:', colons: ':round_pushpin:'},
  '🇯🇪': {key: '🇯🇪', value: ':flag-je:', colons: ':flag-je:'},
  '❓': {key: '❓', value: ':question:', colons: ':question:'},
  '🚀': {key: '🚀', value: ':rocket:', colons: ':rocket:'},
  '👩‍🏫': {key: '👩‍🏫', value: ':female-teacher:', colons: ':female-teacher:'},
  '📎': {key: '📎', value: ':paperclip:', colons: ':paperclip:'},
  '🖇️': {key: '🖇️', value: ':linked_paperclips:', colons: ':linked_paperclips:'},
  '🛸': {key: '🛸', value: ':flying_saucer:', colons: ':flying_saucer:'},
  '👨‍⚖️': {key: '👨‍⚖️', value: ':male-judge:', colons: ':male-judge:'},
  '❔': {key: '❔', value: ':grey_question:', colons: ':grey_question:'},
  '🇯🇲': {key: '🇯🇲', value: ':flag-jm:', colons: ':flag-jm:'},
  '🛎️': {key: '🛎️', value: ':bellhop_bell:', colons: ':bellhop_bell:'},
  '📏': {key: '📏', value: ':straight_ruler:', colons: ':straight_ruler:'},
  '🇯🇴': {key: '🇯🇴', value: ':flag-jo:', colons: ':flag-jo:'},
  '👩‍⚖️': {key: '👩‍⚖️', value: ':female-judge:', colons: ':female-judge:'},
  '❕': {key: '❕', value: ':grey_exclamation:', colons: ':grey_exclamation:'},
  '🚪': {key: '🚪', value: ':door:', colons: ':door:'},
  '👨‍🌾': {key: '👨‍🌾', value: ':male-farmer:', colons: ':male-farmer:'},
  '🇯🇵': {key: '🇯🇵', value: ':jp:', colons: ':jp:'},
  '📐': {key: '📐', value: ':triangular_ruler:', colons: ':triangular_ruler:'},
  '❗': {key: '❗', value: ':exclamation:', colons: ':exclamation:'},
  '🛏️': {key: '🛏️', value: ':bed:', colons: ':bed:'},
  '👩‍🌾': {key: '👩‍🌾', value: ':female-farmer:', colons: ':female-farmer:'},
  '✂️': {key: '✂️', value: ':scissors:', colons: ':scissors:'},
  '〰️': {key: '〰️', value: ':wavy_dash:', colons: ':wavy_dash:'},
  '🇰🇪': {key: '🇰🇪', value: ':flag-ke:', colons: ':flag-ke:'},
  '🇰🇬': {key: '🇰🇬', value: ':flag-kg:', colons: ':flag-kg:'},
  '🛋️': {key: '🛋️', value: ':couch_and_lamp:', colons: ':couch_and_lamp:'},
  '👨‍🍳': {key: '👨‍🍳', value: ':male-cook:', colons: ':male-cook:'},
  '🗃️': {key: '🗃️', value: ':card_file_box:', colons: ':card_file_box:'},
  '©️': {key: '©️', value: ':copyright:', colons: ':copyright:'},
  '🗄️': {key: '🗄️', value: ':file_cabinet:', colons: ':file_cabinet:'},
  '®️': {key: '®️', value: ':registered:', colons: ':registered:'},
  '🇰🇭': {key: '🇰🇭', value: ':flag-kh:', colons: ':flag-kh:'},
  '👩‍🍳': {key: '👩‍🍳', value: ':female-cook:', colons: ':female-cook:'},
  '🚽': {key: '🚽', value: ':toilet:', colons: ':toilet:'},
  '🗑️': {key: '🗑️', value: ':wastebasket:', colons: ':wastebasket:'},
  '🇰🇮': {key: '🇰🇮', value: ':flag-ki:', colons: ':flag-ki:'},
  '🚿': {key: '🚿', value: ':shower:', colons: ':shower:'},
  '👨‍🔧': {key: '👨‍🔧', value: ':male-mechanic:', colons: ':male-mechanic:'},
  '™️': {key: '™️', value: ':tm:', colons: ':tm:'},
  '#️⃣': {key: '#️⃣', value: ':hash:', colons: ':hash:'},
  '🇰🇲': {key: '🇰🇲', value: ':flag-km:', colons: ':flag-km:'},
  '🛁': {key: '🛁', value: ':bathtub:', colons: ':bathtub:'},
  '👩‍🔧': {key: '👩‍🔧', value: ':female-mechanic:', colons: ':female-mechanic:'},
  '🔒': {key: '🔒', value: ':lock:', colons: ':lock:'},
  '👨‍🏭': {key: '👨‍🏭', value: ':male-factory-worker:', colons: ':male-factory-worker:'},
  '🇰🇳': {key: '🇰🇳', value: ':flag-kn:', colons: ':flag-kn:'},
  '⌛': {key: '⌛', value: ':hourglass:', colons: ':hourglass:'},
  '*️⃣': {key: '*️⃣', value: ':keycap_star:', colons: ':keycap_star:'},
  '🔓': {key: '🔓', value: ':unlock:', colons: ':unlock:'},
  '🇰🇵': {key: '🇰🇵', value: ':flag-kp:', colons: ':flag-kp:'},
  '👩‍🏭': {key: '👩‍🏭', value: ':female-factory-worker:', colons: ':female-factory-worker:'},
  '0️⃣': {key: '0️⃣', value: ':zero:', colons: ':zero:'},
  '🔏': {key: '🔏', value: ':lock_with_ink_pen:', colons: ':lock_with_ink_pen:'},
  '⏳': {key: '⏳', value: ':hourglass_flowing_sand:', colons: ':hourglass_flowing_sand:'},
  '1️⃣': {key: '1️⃣', value: ':one:', colons: ':one:'},
  '🇰🇷': {key: '🇰🇷', value: ':kr:', colons: ':kr:'},
  '⌚': {key: '⌚', value: ':watch:', colons: ':watch:'},
  '👨‍💼': {key: '👨‍💼', value: ':male-office-worker:', colons: ':male-office-worker:'},
  '🔐': {key: '🔐', value: ':closed_lock_with_key:', colons: ':closed_lock_with_key:'},
  '👩‍💼': {key: '👩‍💼', value: ':female-office-worker:', colons: ':female-office-worker:'},
  '2️⃣': {key: '2️⃣', value: ':two:', colons: ':two:'},
  '⏰': {key: '⏰', value: ':alarm_clock:', colons: ':alarm_clock:'},
  '🔑': {key: '🔑', value: ':key:', colons: ':key:'},
  '🇰🇼': {key: '🇰🇼', value: ':flag-kw:', colons: ':flag-kw:'},
  '⏱️': {key: '⏱️', value: ':stopwatch:', colons: ':stopwatch:'},
  '👨‍🔬': {key: '👨‍🔬', value: ':male-scientist:', colons: ':male-scientist:'},
  '3️⃣': {key: '3️⃣', value: ':three:', colons: ':three:'},
  '🇰🇾': {key: '🇰🇾', value: ':flag-ky:', colons: ':flag-ky:'},
  '🗝️': {key: '🗝️', value: ':old_key:', colons: ':old_key:'},
  '🇰🇿': {key: '🇰🇿', value: ':flag-kz:', colons: ':flag-kz:'},
  '🔨': {key: '🔨', value: ':hammer:', colons: ':hammer:'},
  '👩‍🔬': {key: '👩‍🔬', value: ':female-scientist:', colons: ':female-scientist:'},
  '⏲️': {key: '⏲️', value: ':timer_clock:', colons: ':timer_clock:'},
  '4️⃣': {key: '4️⃣', value: ':four:', colons: ':four:'},
  '👨‍💻': {key: '👨‍💻', value: ':male-technologist:', colons: ':male-technologist:'},
  '🕰️': {key: '🕰️', value: ':mantelpiece_clock:', colons: ':mantelpiece_clock:'},
  '5️⃣': {key: '5️⃣', value: ':five:', colons: ':five:'},
  '🇱🇦': {key: '🇱🇦', value: ':flag-la:', colons: ':flag-la:'},
  '⛏️': {key: '⛏️', value: ':pick:', colons: ':pick:'},
  '🇱🇧': {key: '🇱🇧', value: ':flag-lb:', colons: ':flag-lb:'},
  '🕛': {key: '🕛', value: ':clock12:', colons: ':clock12:'},
  '⚒️': {key: '⚒️', value: ':hammer_and_pick:', colons: ':hammer_and_pick:'},
  '6️⃣': {key: '6️⃣', value: ':six:', colons: ':six:'},
  '👩‍💻': {key: '👩‍💻', value: ':female-technologist:', colons: ':female-technologist:'},
  '🛠️': {key: '🛠️', value: ':hammer_and_wrench:', colons: ':hammer_and_wrench:'},
  '🇱🇨': {key: '🇱🇨', value: ':flag-lc:', colons: ':flag-lc:'},
  '🕧': {key: '🕧', value: ':clock1230:', colons: ':clock1230:'},
  '7️⃣': {key: '7️⃣', value: ':seven:', colons: ':seven:'},
  '👨‍🎤': {key: '👨‍🎤', value: ':male-singer:', colons: ':male-singer:'},
  '8️⃣': {key: '8️⃣', value: ':eight:', colons: ':eight:'},
  '🇱🇮': {key: '🇱🇮', value: ':flag-li:', colons: ':flag-li:'},
  '🗡️': {key: '🗡️', value: ':dagger_knife:', colons: ':dagger_knife:'},
  '🕐': {key: '🕐', value: ':clock1:', colons: ':clock1:'},
  '👩‍🎤': {key: '👩‍🎤', value: ':female-singer:', colons: ':female-singer:'},
  '👨‍🎨': {key: '👨‍🎨', value: ':male-artist:', colons: ':male-artist:'},
  '⚔️': {key: '⚔️', value: ':crossed_swords:', colons: ':crossed_swords:'},
  '9️⃣': {key: '9️⃣', value: ':nine:', colons: ':nine:'},
  '🇱🇰': {key: '🇱🇰', value: ':flag-lk:', colons: ':flag-lk:'},
  '🕜': {key: '🕜', value: ':clock130:', colons: ':clock130:'},
  '🕑': {key: '🕑', value: ':clock2:', colons: ':clock2:'},
  '🔫': {key: '🔫', value: ':gun:', colons: ':gun:'},
  '🔟': {key: '🔟', value: ':keycap_ten:', colons: ':keycap_ten:'},
  '👩‍🎨': {key: '👩‍🎨', value: ':female-artist:', colons: ':female-artist:'},
  '🇱🇷': {key: '🇱🇷', value: ':flag-lr:', colons: ':flag-lr:'},
  '🕝': {key: '🕝', value: ':clock230:', colons: ':clock230:'},
  '💯': {key: '💯', value: ':100:', colons: ':100:'},
  '🏹': {key: '🏹', value: ':bow_and_arrow:', colons: ':bow_and_arrow:'},
  '👨‍✈️': {key: '👨‍✈️', value: ':male-pilot:', colons: ':male-pilot:'},
  '🇱🇸': {key: '🇱🇸', value: ':flag-ls:', colons: ':flag-ls:'},
  '🇱🇹': {key: '🇱🇹', value: ':flag-lt:', colons: ':flag-lt:'},
  '🔠': {key: '🔠', value: ':capital_abcd:', colons: ':capital_abcd:'},
  '👩‍✈️': {key: '👩‍✈️', value: ':female-pilot:', colons: ':female-pilot:'},
  '🕒': {key: '🕒', value: ':clock3:', colons: ':clock3:'},
  '🛡️': {key: '🛡️', value: ':shield:', colons: ':shield:'},
  '👨‍🚀': {key: '👨‍🚀', value: ':male-astronaut:', colons: ':male-astronaut:'},
  '🔡': {key: '🔡', value: ':abcd:', colons: ':abcd:'},
  '🕞': {key: '🕞', value: ':clock330:', colons: ':clock330:'},
  '🇱🇺': {key: '🇱🇺', value: ':flag-lu:', colons: ':flag-lu:'},
  '🔧': {key: '🔧', value: ':wrench:', colons: ':wrench:'},
  '🔩': {key: '🔩', value: ':nut_and_bolt:', colons: ':nut_and_bolt:'},
  '🔢': {key: '🔢', value: ':1234:', colons: ':1234:'},
  '🕓': {key: '🕓', value: ':clock4:', colons: ':clock4:'},
  '👩‍🚀': {key: '👩‍🚀', value: ':female-astronaut:', colons: ':female-astronaut:'},
  '🇱🇻': {key: '🇱🇻', value: ':flag-lv:', colons: ':flag-lv:'},
  '⚙️': {key: '⚙️', value: ':gear:', colons: ':gear:'},
  '👨‍🚒': {key: '👨‍🚒', value: ':male-firefighter:', colons: ':male-firefighter:'},
  '🇱🇾': {key: '🇱🇾', value: ':flag-ly:', colons: ':flag-ly:'},
  '🔣': {key: '🔣', value: ':symbols:', colons: ':symbols:'},
  '🕟': {key: '🕟', value: ':clock430:', colons: ':clock430:'},
  '🇲🇦': {key: '🇲🇦', value: ':flag-ma:', colons: ':flag-ma:'},
  '🗜️': {key: '🗜️', value: ':compression:', colons: ':compression:'},
  '👩‍🚒': {key: '👩‍🚒', value: ':female-firefighter:', colons: ':female-firefighter:'},
  '🔤': {key: '🔤', value: ':abc:', colons: ':abc:'},
  '🕔': {key: '🕔', value: ':clock5:', colons: ':clock5:'},
  '🕠': {key: '🕠', value: ':clock530:', colons: ':clock530:'},
  '🅰️': {key: '🅰️', value: ':a:', colons: ':a:'},
  '⚗️': {key: '⚗️', value: ':alembic:', colons: ':alembic:'},
  '🇲🇨': {key: '🇲🇨', value: ':flag-mc:', colons: ':flag-mc:'},
  '👮': {key: '👮', value: ':cop:', colons: ':cop:'},
  '⚖️': {key: '⚖️', value: ':scales:', colons: ':scales:'},
  '🕕': {key: '🕕', value: ':clock6:', colons: ':clock6:'},
  '🇲🇩': {key: '🇲🇩', value: ':flag-md:', colons: ':flag-md:'},
  '🆎': {key: '🆎', value: ':ab:', colons: ':ab:'},
  '👮‍♂️': {key: '👮‍♂️', value: ':male-police-officer:', colons: ':male-police-officer:'},
  '🔗': {key: '🔗', value: ':link:', colons: ':link:'},
  '🇲🇪': {key: '🇲🇪', value: ':flag-me:', colons: ':flag-me:'},
  '🕡': {key: '🕡', value: ':clock630:', colons: ':clock630:'},
  '🅱️': {key: '🅱️', value: ':b:', colons: ':b:'},
  '👮‍♀️': {key: '👮‍♀️', value: ':female-police-officer:', colons: ':female-police-officer:'},
  '🕖': {key: '🕖', value: ':clock7:', colons: ':clock7:'},
  '🆑': {key: '🆑', value: ':cl:', colons: ':cl:'},
  '🕵️': {key: '🕵️', value: ':sleuth_or_spy:', colons: ':sleuth_or_spy:'},
  '🇲🇫': {key: '🇲🇫', value: ':flag-mf:', colons: ':flag-mf:'},
  '⛓️': {key: '⛓️', value: ':chains:', colons: ':chains:'},
  '💉': {key: '💉', value: ':syringe:', colons: ':syringe:'},
  '🕵️‍♂️': {key: '🕵️‍♂️', value: ':male-detective:', colons: ':male-detective:'},
  '🆒': {key: '🆒', value: ':cool:', colons: ':cool:'},
  '🕢': {key: '🕢', value: ':clock730:', colons: ':clock730:'},
  '🇲🇬': {key: '🇲🇬', value: ':flag-mg:', colons: ':flag-mg:'},
  '🆓': {key: '🆓', value: ':free:', colons: ':free:'},
  '🇲🇭': {key: '🇲🇭', value: ':flag-mh:', colons: ':flag-mh:'},
  '🕗': {key: '🕗', value: ':clock8:', colons: ':clock8:'},
  '💊': {key: '💊', value: ':pill:', colons: ':pill:'},
  '🕵️‍♀️': {key: '🕵️‍♀️', value: ':female-detective:', colons: ':female-detective:'},
  '🕣': {key: '🕣', value: ':clock830:', colons: ':clock830:'},
  '💂': {key: '💂', value: ':guardsman:', colons: ':guardsman:'},
  ℹ️: {key: 'ℹ️', value: ':information_source:', colons: ':information_source:'},
  '🇲🇰': {key: '🇲🇰', value: ':flag-mk:', colons: ':flag-mk:'},
  '🚬': {key: '🚬', value: ':smoking:', colons: ':smoking:'},
  '🆔': {key: '🆔', value: ':id:', colons: ':id:'},
  '🕘': {key: '🕘', value: ':clock9:', colons: ':clock9:'},
  '🇲🇱': {key: '🇲🇱', value: ':flag-ml:', colons: ':flag-ml:'},
  '⚰️': {key: '⚰️', value: ':coffin:', colons: ':coffin:'},
  '💂‍♂️': {key: '💂‍♂️', value: ':male-guard:', colons: ':male-guard:'},
  'Ⓜ️': {key: 'Ⓜ️', value: ':m:', colons: ':m:'},
  '⚱️': {key: '⚱️', value: ':funeral_urn:', colons: ':funeral_urn:'},
  '💂‍♀️': {key: '💂‍♀️', value: ':female-guard:', colons: ':female-guard:'},
  '🇲🇲': {key: '🇲🇲', value: ':flag-mm:', colons: ':flag-mm:'},
  '🕤': {key: '🕤', value: ':clock930:', colons: ':clock930:'},
  '🗿': {key: '🗿', value: ':moyai:', colons: ':moyai:'},
  '🆕': {key: '🆕', value: ':new:', colons: ':new:'},
  '🇲🇳': {key: '🇲🇳', value: ':flag-mn:', colons: ':flag-mn:'},
  '👷': {key: '👷', value: ':construction_worker:', colons: ':construction_worker:'},
  '🕙': {key: '🕙', value: ':clock10:', colons: ':clock10:'},
  '🕥': {key: '🕥', value: ':clock1030:', colons: ':clock1030:'},
  '🆖': {key: '🆖', value: ':ng:', colons: ':ng:'},
  '👷‍♂️': {key: '👷‍♂️', value: ':male-construction-worker:', colons: ':male-construction-worker:'},
  '🇲🇴': {key: '🇲🇴', value: ':flag-mo:', colons: ':flag-mo:'},
  '🛢️': {key: '🛢️', value: ':oil_drum:', colons: ':oil_drum:'},
  '🅾️': {key: '🅾️', value: ':o2:', colons: ':o2:'},
  '👷‍♀️': {key: '👷‍♀️', value: ':female-construction-worker:', colons: ':female-construction-worker:'},
  '🕚': {key: '🕚', value: ':clock11:', colons: ':clock11:'},
  '🔮': {key: '🔮', value: ':crystal_ball:', colons: ':crystal_ball:'},
  '🇲🇵': {key: '🇲🇵', value: ':flag-mp:', colons: ':flag-mp:'},
  '🇲🇶': {key: '🇲🇶', value: ':flag-mq:', colons: ':flag-mq:'},
  '🤴': {key: '🤴', value: ':prince:', colons: ':prince:'},
  '🆗': {key: '🆗', value: ':ok:', colons: ':ok:'},
  '🕦': {key: '🕦', value: ':clock1130:', colons: ':clock1130:'},
  '🛒': {key: '🛒', value: ':shopping_trolley:', colons: ':shopping_trolley:'},
  '🇲🇷': {key: '🇲🇷', value: ':flag-mr:', colons: ':flag-mr:'},
  '👸': {key: '👸', value: ':princess:', colons: ':princess:'},
  '🌑': {key: '🌑', value: ':new_moon:', colons: ':new_moon:'},
  '🅿️': {key: '🅿️', value: ':parking:', colons: ':parking:'},
  '🆘': {key: '🆘', value: ':sos:', colons: ':sos:'},
  '👳': {key: '👳', value: ':man_with_turban:', colons: ':man_with_turban:'},
  '🇲🇸': {key: '🇲🇸', value: ':flag-ms:', colons: ':flag-ms:'},
  '🌒': {key: '🌒', value: ':waxing_crescent_moon:', colons: ':waxing_crescent_moon:'},
  '🆙': {key: '🆙', value: ':up:', colons: ':up:'},
  '🌓': {key: '🌓', value: ':first_quarter_moon:', colons: ':first_quarter_moon:'},
  '🇲🇹': {key: '🇲🇹', value: ':flag-mt:', colons: ':flag-mt:'},
  '👳‍♂️': {key: '👳‍♂️', value: ':man-wearing-turban:', colons: ':man-wearing-turban:'},
  '🌔': {key: '🌔', value: ':moon:', colons: ':moon:'},
  '👳‍♀️': {key: '👳‍♀️', value: ':woman-wearing-turban:', colons: ':woman-wearing-turban:'},
  '🆚': {key: '🆚', value: ':vs:', colons: ':vs:'},
  '🇲🇺': {key: '🇲🇺', value: ':flag-mu:', colons: ':flag-mu:'},
  '👲': {key: '👲', value: ':man_with_gua_pi_mao:', colons: ':man_with_gua_pi_mao:'},
  '🈁': {key: '🈁', value: ':koko:', colons: ':koko:'},
  '🌕': {key: '🌕', value: ':full_moon:', colons: ':full_moon:'},
  '🇲🇻': {key: '🇲🇻', value: ':flag-mv:', colons: ':flag-mv:'},
  '🧕': {key: '🧕', value: ':person_with_headscarf:', colons: ':person_with_headscarf:'},
  '🌖': {key: '🌖', value: ':waning_gibbous_moon:', colons: ':waning_gibbous_moon:'},
  '🈂️': {key: '🈂️', value: ':sa:', colons: ':sa:'},
  '🇲🇼': {key: '🇲🇼', value: ':flag-mw:', colons: ':flag-mw:'},
  '🌗': {key: '🌗', value: ':last_quarter_moon:', colons: ':last_quarter_moon:'},
  '🈷️': {key: '🈷️', value: ':u6708:', colons: ':u6708:'},
  '🧔': {key: '🧔', value: ':bearded_person:', colons: ':bearded_person:'},
  '🇲🇽': {key: '🇲🇽', value: ':flag-mx:', colons: ':flag-mx:'},
  '🈶': {key: '🈶', value: ':u6709:', colons: ':u6709:'},
  '👱': {key: '👱', value: ':person_with_blond_hair:', colons: ':person_with_blond_hair:'},
  '🌘': {key: '🌘', value: ':waning_crescent_moon:', colons: ':waning_crescent_moon:'},
  '🇲🇾': {key: '🇲🇾', value: ':flag-my:', colons: ':flag-my:'},
  '🈯': {key: '🈯', value: ':u6307:', colons: ':u6307:'},
  '👱‍♂️': {key: '👱‍♂️', value: ':blond-haired-man:', colons: ':blond-haired-man:'},
  '🌙': {key: '🌙', value: ':crescent_moon:', colons: ':crescent_moon:'},
  '🇲🇿': {key: '🇲🇿', value: ':flag-mz:', colons: ':flag-mz:'},
  '🌚': {key: '🌚', value: ':new_moon_with_face:', colons: ':new_moon_with_face:'},
  '🇳🇦': {key: '🇳🇦', value: ':flag-na:', colons: ':flag-na:'},
  '👱‍♀️': {key: '👱‍♀️', value: ':blond-haired-woman:', colons: ':blond-haired-woman:'},
  '🉐': {key: '🉐', value: ':ideograph_advantage:', colons: ':ideograph_advantage:'},
  '🌛': {key: '🌛', value: ':first_quarter_moon_with_face:', colons: ':first_quarter_moon_with_face:'},
  '🤵': {key: '🤵', value: ':man_in_tuxedo:', colons: ':man_in_tuxedo:'},
  '🇳🇨': {key: '🇳🇨', value: ':flag-nc:', colons: ':flag-nc:'},
  '🈹': {key: '🈹', value: ':u5272:', colons: ':u5272:'},
  '🇳🇪': {key: '🇳🇪', value: ':flag-ne:', colons: ':flag-ne:'},
  '🌜': {key: '🌜', value: ':last_quarter_moon_with_face:', colons: ':last_quarter_moon_with_face:'},
  '🈚': {key: '🈚', value: ':u7121:', colons: ':u7121:'},
  '👰': {key: '👰', value: ':bride_with_veil:', colons: ':bride_with_veil:'},
  '🈲': {key: '🈲', value: ':u7981:', colons: ':u7981:'},
  '🤰': {key: '🤰', value: ':pregnant_woman:', colons: ':pregnant_woman:'},
  '🌡️': {key: '🌡️', value: ':thermometer:', colons: ':thermometer:'},
  '🇳🇫': {key: '🇳🇫', value: ':flag-nf:', colons: ':flag-nf:'},
  '☀️': {key: '☀️', value: ':sunny:', colons: ':sunny:'},
  '🉑': {key: '🉑', value: ':accept:', colons: ':accept:'},
  '🇳🇬': {key: '🇳🇬', value: ':flag-ng:', colons: ':flag-ng:'},
  '🤱': {key: '🤱', value: ':breast-feeding:', colons: ':breast-feeding:'},
  '🌝': {key: '🌝', value: ':full_moon_with_face:', colons: ':full_moon_with_face:'},
  '🇳🇮': {key: '🇳🇮', value: ':flag-ni:', colons: ':flag-ni:'},
  '🈸': {key: '🈸', value: ':u7533:', colons: ':u7533:'},
  '👼': {key: '👼', value: ':angel:', colons: ':angel:'},
  '🌞': {key: '🌞', value: ':sun_with_face:', colons: ':sun_with_face:'},
  '🎅': {key: '🎅', value: ':santa:', colons: ':santa:'},
  '🈴': {key: '🈴', value: ':u5408:', colons: ':u5408:'},
  '🇳🇱': {key: '🇳🇱', value: ':flag-nl:', colons: ':flag-nl:'},
  '🤶': {key: '🤶', value: ':mrs_claus:', colons: ':mrs_claus:'},
  '🈳': {key: '🈳', value: ':u7a7a:', colons: ':u7a7a:'},
  '⭐': {key: '⭐', value: ':star:', colons: ':star:'},
  '🇳🇴': {key: '🇳🇴', value: ':flag-no:', colons: ':flag-no:'},
  '🧙': {key: '🧙', value: ':mage:', colons: ':mage:'},
  '🌟': {key: '🌟', value: ':star2:', colons: ':star2:'},
  '🇳🇵': {key: '🇳🇵', value: ':flag-np:', colons: ':flag-np:'},
  '㊗️': {key: '㊗️', value: ':congratulations:', colons: ':congratulations:'},
  '🇳🇷': {key: '🇳🇷', value: ':flag-nr:', colons: ':flag-nr:'},
  '🌠': {key: '🌠', value: ':stars:', colons: ':stars:'},
  '🧙‍♀️': {key: '🧙‍♀️', value: ':female_mage:', colons: ':female_mage:'},
  '㊙️': {key: '㊙️', value: ':secret:', colons: ':secret:'},
  '🇳🇺': {key: '🇳🇺', value: ':flag-nu:', colons: ':flag-nu:'},
  '🈺': {key: '🈺', value: ':u55b6:', colons: ':u55b6:'},
  '🧙‍♂️': {key: '🧙‍♂️', value: ':male_mage:', colons: ':male_mage:'},
  '☁️': {key: '☁️', value: ':cloud:', colons: ':cloud:'},
  '🇳🇿': {key: '🇳🇿', value: ':flag-nz:', colons: ':flag-nz:'},
  '⛅': {key: '⛅', value: ':partly_sunny:', colons: ':partly_sunny:'},
  '🧚': {key: '🧚', value: ':fairy:', colons: ':fairy:'},
  '🈵': {key: '🈵', value: ':u6e80:', colons: ':u6e80:'},
  '▪️': {key: '▪️', value: ':black_small_square:', colons: ':black_small_square:'},
  '⛈️': {key: '⛈️', value: ':thunder_cloud_and_rain:', colons: ':thunder_cloud_and_rain:'},
  '🧚‍♀️': {key: '🧚‍♀️', value: ':female_fairy:', colons: ':female_fairy:'},
  '🇴🇲': {key: '🇴🇲', value: ':flag-om:', colons: ':flag-om:'},
  '▫️': {key: '▫️', value: ':white_small_square:', colons: ':white_small_square:'},
  '🇵🇦': {key: '🇵🇦', value: ':flag-pa:', colons: ':flag-pa:'},
  '🌤️': {key: '🌤️', value: ':mostly_sunny:', colons: ':mostly_sunny:'},
  '🧚‍♂️': {key: '🧚‍♂️', value: ':male_fairy:', colons: ':male_fairy:'},
  '🌥️': {key: '🌥️', value: ':barely_sunny:', colons: ':barely_sunny:'},
  '◻️': {key: '◻️', value: ':white_medium_square:', colons: ':white_medium_square:'},
  '🇵🇪': {key: '🇵🇪', value: ':flag-pe:', colons: ':flag-pe:'},
  '🧛': {key: '🧛', value: ':vampire:', colons: ':vampire:'},
  '🧛‍♀️': {key: '🧛‍♀️', value: ':female_vampire:', colons: ':female_vampire:'},
  '🌦️': {key: '🌦️', value: ':partly_sunny_rain:', colons: ':partly_sunny_rain:'},
  '🇵🇫': {key: '🇵🇫', value: ':flag-pf:', colons: ':flag-pf:'},
  '◼️': {key: '◼️', value: ':black_medium_square:', colons: ':black_medium_square:'},
  '◽': {key: '◽', value: ':white_medium_small_square:', colons: ':white_medium_small_square:'},
  '🌧️': {key: '🌧️', value: ':rain_cloud:', colons: ':rain_cloud:'},
  '🇵🇬': {key: '🇵🇬', value: ':flag-pg:', colons: ':flag-pg:'},
  '🧛‍♂️': {key: '🧛‍♂️', value: ':male_vampire:', colons: ':male_vampire:'},
  '🇵🇭': {key: '🇵🇭', value: ':flag-ph:', colons: ':flag-ph:'},
  '🧜': {key: '🧜', value: ':merperson:', colons: ':merperson:'},
  '◾': {key: '◾', value: ':black_medium_small_square:', colons: ':black_medium_small_square:'},
  '🌨️': {key: '🌨️', value: ':snow_cloud:', colons: ':snow_cloud:'},
  '🌩️': {key: '🌩️', value: ':lightning:', colons: ':lightning:'},
  '⬛': {key: '⬛', value: ':black_large_square:', colons: ':black_large_square:'},
  '🧜‍♀️': {key: '🧜‍♀️', value: ':mermaid:', colons: ':mermaid:'},
  '🇵🇰': {key: '🇵🇰', value: ':flag-pk:', colons: ':flag-pk:'},
  '🧜‍♂️': {key: '🧜‍♂️', value: ':merman:', colons: ':merman:'},
  '⬜': {key: '⬜', value: ':white_large_square:', colons: ':white_large_square:'},
  '🌪️': {key: '🌪️', value: ':tornado:', colons: ':tornado:'},
  '🇵🇱': {key: '🇵🇱', value: ':flag-pl:', colons: ':flag-pl:'},
  '🧝': {key: '🧝', value: ':elf:', colons: ':elf:'},
  '🌫️': {key: '🌫️', value: ':fog:', colons: ':fog:'},
  '🔶': {key: '🔶', value: ':large_orange_diamond:', colons: ':large_orange_diamond:'},
  '🇵🇲': {key: '🇵🇲', value: ':flag-pm:', colons: ':flag-pm:'},
  '🇵🇳': {key: '🇵🇳', value: ':flag-pn:', colons: ':flag-pn:'},
  '🌬️': {key: '🌬️', value: ':wind_blowing_face:', colons: ':wind_blowing_face:'},
  '🧝‍♀️': {key: '🧝‍♀️', value: ':female_elf:', colons: ':female_elf:'},
  '🔷': {key: '🔷', value: ':large_blue_diamond:', colons: ':large_blue_diamond:'},
  '🧝‍♂️': {key: '🧝‍♂️', value: ':male_elf:', colons: ':male_elf:'},
  '🔸': {key: '🔸', value: ':small_orange_diamond:', colons: ':small_orange_diamond:'},
  '🇵🇷': {key: '🇵🇷', value: ':flag-pr:', colons: ':flag-pr:'},
  '🌀': {key: '🌀', value: ':cyclone:', colons: ':cyclone:'},
  '🌈': {key: '🌈', value: ':rainbow:', colons: ':rainbow:'},
  '🔹': {key: '🔹', value: ':small_blue_diamond:', colons: ':small_blue_diamond:'},
  '🧞': {key: '🧞', value: ':genie:', colons: ':genie:'},
  '🇵🇸': {key: '🇵🇸', value: ':flag-ps:', colons: ':flag-ps:'},
  '🔺': {key: '🔺', value: ':small_red_triangle:', colons: ':small_red_triangle:'},
  '🌂': {key: '🌂', value: ':closed_umbrella:', colons: ':closed_umbrella:'},
  '🧞‍♀️': {key: '🧞‍♀️', value: ':female_genie:', colons: ':female_genie:'},
  '🇵🇹': {key: '🇵🇹', value: ':flag-pt:', colons: ':flag-pt:'},
  '🇵🇼': {key: '🇵🇼', value: ':flag-pw:', colons: ':flag-pw:'},
  '🔻': {key: '🔻', value: ':small_red_triangle_down:', colons: ':small_red_triangle_down:'},
  '☂️': {key: '☂️', value: ':umbrella:', colons: ':umbrella:'},
  '🧞‍♂️': {key: '🧞‍♂️', value: ':male_genie:', colons: ':male_genie:'},
  '🧟': {key: '🧟', value: ':zombie:', colons: ':zombie:'},
  '🇵🇾': {key: '🇵🇾', value: ':flag-py:', colons: ':flag-py:'},
  '💠': {key: '💠', value: ':diamond_shape_with_a_dot_inside:', colons: ':diamond_shape_with_a_dot_inside:'},
  '☔': {key: '☔', value: ':umbrella_with_rain_drops:', colons: ':umbrella_with_rain_drops:'},
  '🔘': {key: '🔘', value: ':radio_button:', colons: ':radio_button:'},
  '🧟‍♀️': {key: '🧟‍♀️', value: ':female_zombie:', colons: ':female_zombie:'},
  '🇶🇦': {key: '🇶🇦', value: ':flag-qa:', colons: ':flag-qa:'},
  '⛱️': {key: '⛱️', value: ':umbrella_on_ground:', colons: ':umbrella_on_ground:'},
  '🔲': {key: '🔲', value: ':black_square_button:', colons: ':black_square_button:'},
  '⚡': {key: '⚡', value: ':zap:', colons: ':zap:'},
  '🧟‍♂️': {key: '🧟‍♂️', value: ':male_zombie:', colons: ':male_zombie:'},
  '🇷🇪': {key: '🇷🇪', value: ':flag-re:', colons: ':flag-re:'},
  '🇷🇴': {key: '🇷🇴', value: ':flag-ro:', colons: ':flag-ro:'},
  '❄️': {key: '❄️', value: ':snowflake:', colons: ':snowflake:'},
  '🔳': {key: '🔳', value: ':white_square_button:', colons: ':white_square_button:'},
  '🙍': {key: '🙍', value: ':person_frowning:', colons: ':person_frowning:'},
  '🇷🇸': {key: '🇷🇸', value: ':flag-rs:', colons: ':flag-rs:'},
  '🙍‍♂️': {key: '🙍‍♂️', value: ':man-frowning:', colons: ':man-frowning:'},
  '⚪': {key: '⚪', value: ':white_circle:', colons: ':white_circle:'},
  '☃️': {key: '☃️', value: ':snowman:', colons: ':snowman:'},
  '⛄': {key: '⛄', value: ':snowman_without_snow:', colons: ':snowman_without_snow:'},
  '🇷🇺': {key: '🇷🇺', value: ':ru:', colons: ':ru:'},
  '⚫': {key: '⚫', value: ':black_circle:', colons: ':black_circle:'},
  '🙍‍♀️': {key: '🙍‍♀️', value: ':woman-frowning:', colons: ':woman-frowning:'},
  '🇷🇼': {key: '🇷🇼', value: ':flag-rw:', colons: ':flag-rw:'},
  '☄️': {key: '☄️', value: ':comet:', colons: ':comet:'},
  '🙎': {key: '🙎', value: ':person_with_pouting_face:', colons: ':person_with_pouting_face:'},
  '🔴': {key: '🔴', value: ':red_circle:', colons: ':red_circle:'},
  '🔵': {key: '🔵', value: ':large_blue_circle:', colons: ':large_blue_circle:'},
  '🙎‍♂️': {key: '🙎‍♂️', value: ':man-pouting:', colons: ':man-pouting:'},
  '🇸🇦': {key: '🇸🇦', value: ':flag-sa:', colons: ':flag-sa:'},
  '🔥': {key: '🔥', value: ':fire:', colons: ':fire:'},
  '🙎‍♀️': {key: '🙎‍♀️', value: ':woman-pouting:', colons: ':woman-pouting:'},
  '🇸🇧': {key: '🇸🇧', value: ':flag-sb:', colons: ':flag-sb:'},
  '💧': {key: '💧', value: ':droplet:', colons: ':droplet:'},
  '🙅': {key: '🙅', value: ':no_good:', colons: ':no_good:'},
  '🇸🇨': {key: '🇸🇨', value: ':flag-sc:', colons: ':flag-sc:'},
  '🌊': {key: '🌊', value: ':ocean:', colons: ':ocean:'},
  '🙅‍♂️': {key: '🙅‍♂️', value: ':man-gesturing-no:', colons: ':man-gesturing-no:'},
  '🇸🇩': {key: '🇸🇩', value: ':flag-sd:', colons: ':flag-sd:'},
  '🙅‍♀️': {key: '🙅‍♀️', value: ':woman-gesturing-no:', colons: ':woman-gesturing-no:'},
  '🇸🇪': {key: '🇸🇪', value: ':flag-se:', colons: ':flag-se:'},
  '🇸🇬': {key: '🇸🇬', value: ':flag-sg:', colons: ':flag-sg:'},
  '🙆': {key: '🙆', value: ':ok_woman:', colons: ':ok_woman:'},
  '🇸🇭': {key: '🇸🇭', value: ':flag-sh:', colons: ':flag-sh:'},
  '🙆‍♂️': {key: '🙆‍♂️', value: ':man-gesturing-ok:', colons: ':man-gesturing-ok:'},
  '🇸🇮': {key: '🇸🇮', value: ':flag-si:', colons: ':flag-si:'},
  '🙆‍♀️': {key: '🙆‍♀️', value: ':woman-gesturing-ok:', colons: ':woman-gesturing-ok:'},
  '💁': {key: '💁', value: ':information_desk_person:', colons: ':information_desk_person:'},
  '🇸🇯': {key: '🇸🇯', value: ':flag-sj:', colons: ':flag-sj:'},
  '💁‍♂️': {key: '💁‍♂️', value: ':man-tipping-hand:', colons: ':man-tipping-hand:'},
  '🇸🇰': {key: '🇸🇰', value: ':flag-sk:', colons: ':flag-sk:'},
  '🇸🇱': {key: '🇸🇱', value: ':flag-sl:', colons: ':flag-sl:'},
  '💁‍♀️': {key: '💁‍♀️', value: ':woman-tipping-hand:', colons: ':woman-tipping-hand:'},
  '🇸🇲': {key: '🇸🇲', value: ':flag-sm:', colons: ':flag-sm:'},
  '🙋': {key: '🙋', value: ':raising_hand:', colons: ':raising_hand:'},
  '🇸🇳': {key: '🇸🇳', value: ':flag-sn:', colons: ':flag-sn:'},
  '🙋‍♂️': {key: '🙋‍♂️', value: ':man-raising-hand:', colons: ':man-raising-hand:'},
  '🇸🇴': {key: '🇸🇴', value: ':flag-so:', colons: ':flag-so:'},
  '🙋‍♀️': {key: '🙋‍♀️', value: ':woman-raising-hand:', colons: ':woman-raising-hand:'},
  '🇸🇷': {key: '🇸🇷', value: ':flag-sr:', colons: ':flag-sr:'},
  '🙇': {key: '🙇', value: ':bow:', colons: ':bow:'},
  '🙇‍♂️': {key: '🙇‍♂️', value: ':man-bowing:', colons: ':man-bowing:'},
  '🇸🇸': {key: '🇸🇸', value: ':flag-ss:', colons: ':flag-ss:'},
  '🙇‍♀️': {key: '🙇‍♀️', value: ':woman-bowing:', colons: ':woman-bowing:'},
  '🇸🇹': {key: '🇸🇹', value: ':flag-st:', colons: ':flag-st:'},
  '🤦': {key: '🤦', value: ':face_palm:', colons: ':face_palm:'},
  '🇸🇻': {key: '🇸🇻', value: ':flag-sv:', colons: ':flag-sv:'},
  '🤦‍♂️': {key: '🤦‍♂️', value: ':man-facepalming:', colons: ':man-facepalming:'},
  '🇸🇽': {key: '🇸🇽', value: ':flag-sx:', colons: ':flag-sx:'},
  '🇸🇾': {key: '🇸🇾', value: ':flag-sy:', colons: ':flag-sy:'},
  '🤦‍♀️': {key: '🤦‍♀️', value: ':woman-facepalming:', colons: ':woman-facepalming:'},
  '🤷': {key: '🤷', value: ':shrug:', colons: ':shrug:'},
  '🇸🇿': {key: '🇸🇿', value: ':flag-sz:', colons: ':flag-sz:'},
  '🇹🇦': {key: '🇹🇦', value: ':flag-ta:', colons: ':flag-ta:'},
  '🤷‍♂️': {key: '🤷‍♂️', value: ':man-shrugging:', colons: ':man-shrugging:'},
  '🤷‍♀️': {key: '🤷‍♀️', value: ':woman-shrugging:', colons: ':woman-shrugging:'},
  '🇹🇨': {key: '🇹🇨', value: ':flag-tc:', colons: ':flag-tc:'},
  '💆': {key: '💆', value: ':massage:', colons: ':massage:'},
  '🇹🇩': {key: '🇹🇩', value: ':flag-td:', colons: ':flag-td:'},
  '💆‍♂️': {key: '💆‍♂️', value: ':man-getting-massage:', colons: ':man-getting-massage:'},
  '🇹🇫': {key: '🇹🇫', value: ':flag-tf:', colons: ':flag-tf:'},
  '💆‍♀️': {key: '💆‍♀️', value: ':woman-getting-massage:', colons: ':woman-getting-massage:'},
  '🇹🇬': {key: '🇹🇬', value: ':flag-tg:', colons: ':flag-tg:'},
  '💇': {key: '💇', value: ':haircut:', colons: ':haircut:'},
  '🇹🇭': {key: '🇹🇭', value: ':flag-th:', colons: ':flag-th:'},
  '💇‍♂️': {key: '💇‍♂️', value: ':man-getting-haircut:', colons: ':man-getting-haircut:'},
  '🇹🇯': {key: '🇹🇯', value: ':flag-tj:', colons: ':flag-tj:'},
  '🇹🇰': {key: '🇹🇰', value: ':flag-tk:', colons: ':flag-tk:'},
  '💇‍♀️': {key: '💇‍♀️', value: ':woman-getting-haircut:', colons: ':woman-getting-haircut:'},
  '🚶': {key: '🚶', value: ':walking:', colons: ':walking:'},
  '🇹🇱': {key: '🇹🇱', value: ':flag-tl:', colons: ':flag-tl:'},
  '🚶‍♂️': {key: '🚶‍♂️', value: ':man-walking:', colons: ':man-walking:'},
  '🇹🇲': {key: '🇹🇲', value: ':flag-tm:', colons: ':flag-tm:'},
  '🚶‍♀️': {key: '🚶‍♀️', value: ':woman-walking:', colons: ':woman-walking:'},
  '🇹🇳': {key: '🇹🇳', value: ':flag-tn:', colons: ':flag-tn:'},
  '🏃': {key: '🏃', value: ':runner:', colons: ':runner:'},
  '🇹🇴': {key: '🇹🇴', value: ':flag-to:', colons: ':flag-to:'},
  '🏃‍♂️': {key: '🏃‍♂️', value: ':man-running:', colons: ':man-running:'},
  '🇹🇷': {key: '🇹🇷', value: ':flag-tr:', colons: ':flag-tr:'},
  '🇹🇹': {key: '🇹🇹', value: ':flag-tt:', colons: ':flag-tt:'},
  '🏃‍♀️': {key: '🏃‍♀️', value: ':woman-running:', colons: ':woman-running:'},
  '🇹🇻': {key: '🇹🇻', value: ':flag-tv:', colons: ':flag-tv:'},
  '💃': {key: '💃', value: ':dancer:', colons: ':dancer:'},
  '🇹🇼': {key: '🇹🇼', value: ':flag-tw:', colons: ':flag-tw:'},
  '🕺': {key: '🕺', value: ':man_dancing:', colons: ':man_dancing:'},
  '👯': {key: '👯', value: ':dancers:', colons: ':dancers:'},
  '🇹🇿': {key: '🇹🇿', value: ':flag-tz:', colons: ':flag-tz:'},
  '🇺🇦': {key: '🇺🇦', value: ':flag-ua:', colons: ':flag-ua:'},
  '👯‍♂️': {key: '👯‍♂️', value: ':man-with-bunny-ears-partying:', colons: ':man-with-bunny-ears-partying:'},
  '👯‍♀️': {key: '👯‍♀️', value: ':woman-with-bunny-ears-partying:', colons: ':woman-with-bunny-ears-partying:'},
  '🇺🇬': {key: '🇺🇬', value: ':flag-ug:', colons: ':flag-ug:'},
  '🇺🇲': {key: '🇺🇲', value: ':flag-um:', colons: ':flag-um:'},
  '🧖': {key: '🧖', value: ':person_in_steamy_room:', colons: ':person_in_steamy_room:'},
  '🧖‍♀️': {key: '🧖‍♀️', value: ':woman_in_steamy_room:', colons: ':woman_in_steamy_room:'},
  '🇺🇳': {key: '🇺🇳', value: ':flag-un:', colons: ':flag-un:'},
  '🇺🇸': {key: '🇺🇸', value: ':us:', colons: ':us:'},
  '🧖‍♂️': {key: '🧖‍♂️', value: ':man_in_steamy_room:', colons: ':man_in_steamy_room:'},
  '🧗': {key: '🧗', value: ':person_climbing:', colons: ':person_climbing:'},
  '🇺🇾': {key: '🇺🇾', value: ':flag-uy:', colons: ':flag-uy:'},
  '🧗‍♀️': {key: '🧗‍♀️', value: ':woman_climbing:', colons: ':woman_climbing:'},
  '🇺🇿': {key: '🇺🇿', value: ':flag-uz:', colons: ':flag-uz:'},
  '🧗‍♂️': {key: '🧗‍♂️', value: ':man_climbing:', colons: ':man_climbing:'},
  '🇻🇦': {key: '🇻🇦', value: ':flag-va:', colons: ':flag-va:'},
  '🧘': {key: '🧘', value: ':person_in_lotus_position:', colons: ':person_in_lotus_position:'},
  '🇻🇨': {key: '🇻🇨', value: ':flag-vc:', colons: ':flag-vc:'},
  '🇻🇪': {key: '🇻🇪', value: ':flag-ve:', colons: ':flag-ve:'},
  '🧘‍♀️': {key: '🧘‍♀️', value: ':woman_in_lotus_position:', colons: ':woman_in_lotus_position:'},
  '🧘‍♂️': {key: '🧘‍♂️', value: ':man_in_lotus_position:', colons: ':man_in_lotus_position:'},
  '🇻🇬': {key: '🇻🇬', value: ':flag-vg:', colons: ':flag-vg:'},
  '🇻🇮': {key: '🇻🇮', value: ':flag-vi:', colons: ':flag-vi:'},
  '🛀': {key: '🛀', value: ':bath:', colons: ':bath:'},
  '🛌': {key: '🛌', value: ':sleeping_accommodation:', colons: ':sleeping_accommodation:'},
  '🇻🇳': {key: '🇻🇳', value: ':flag-vn:', colons: ':flag-vn:'},
  '🕴️': {key: '🕴️', value: ':man_in_business_suit_levitating:', colons: ':man_in_business_suit_levitating:'},
  '🇻🇺': {key: '🇻🇺', value: ':flag-vu:', colons: ':flag-vu:'},
  '🇼🇫': {key: '🇼🇫', value: ':flag-wf:', colons: ':flag-wf:'},
  '🗣️': {key: '🗣️', value: ':speaking_head_in_silhouette:', colons: ':speaking_head_in_silhouette:'},
  '👤': {key: '👤', value: ':bust_in_silhouette:', colons: ':bust_in_silhouette:'},
  '🇼🇸': {key: '🇼🇸', value: ':flag-ws:', colons: ':flag-ws:'},
  '👥': {key: '👥', value: ':busts_in_silhouette:', colons: ':busts_in_silhouette:'},
  '🇽🇰': {key: '🇽🇰', value: ':flag-xk:', colons: ':flag-xk:'},
  '🤺': {key: '🤺', value: ':fencer:', colons: ':fencer:'},
  '🇾🇪': {key: '🇾🇪', value: ':flag-ye:', colons: ':flag-ye:'},
  '🇾🇹': {key: '🇾🇹', value: ':flag-yt:', colons: ':flag-yt:'},
  '🏇': {key: '🏇', value: ':horse_racing:', colons: ':horse_racing:'},
  '🇿🇦': {key: '🇿🇦', value: ':flag-za:', colons: ':flag-za:'},
  '⛷️': {key: '⛷️', value: ':skier:', colons: ':skier:'},
  '🇿🇲': {key: '🇿🇲', value: ':flag-zm:', colons: ':flag-zm:'},
  '🏂': {key: '🏂', value: ':snowboarder:', colons: ':snowboarder:'},
  '🏌️': {key: '🏌️', value: ':golfer:', colons: ':golfer:'},
  '🇿🇼': {key: '🇿🇼', value: ':flag-zw:', colons: ':flag-zw:'},
  '🏌️‍♂️': {key: '🏌️‍♂️', value: ':man-golfing:', colons: ':man-golfing:'},
  '🏴󠁧󠁢󠁥󠁮󠁧󠁿': {key: '🏴󠁧󠁢󠁥󠁮󠁧󠁿', value: ':flag-england:', colons: ':flag-england:'},
  '🏌️‍♀️': {key: '🏌️‍♀️', value: ':woman-golfing:', colons: ':woman-golfing:'},
  '🏴󠁧󠁢󠁳󠁣󠁴󠁿': {key: '🏴󠁧󠁢󠁳󠁣󠁴󠁿', value: ':flag-scotland:', colons: ':flag-scotland:'},
  '🏴󠁧󠁢󠁷󠁬󠁳󠁿': {key: '🏴󠁧󠁢󠁷󠁬󠁳󠁿', value: ':flag-wales:', colons: ':flag-wales:'},
  '🏄': {key: '🏄', value: ':surfer:', colons: ':surfer:'},
  '🏄‍♂️': {key: '🏄‍♂️', value: ':man-surfing:', colons: ':man-surfing:'},
  '🏄‍♀️': {key: '🏄‍♀️', value: ':woman-surfing:', colons: ':woman-surfing:'},
  '🚣': {key: '🚣', value: ':rowboat:', colons: ':rowboat:'},
  '🚣‍♂️': {key: '🚣‍♂️', value: ':man-rowing-boat:', colons: ':man-rowing-boat:'},
  '🚣‍♀️': {key: '🚣‍♀️', value: ':woman-rowing-boat:', colons: ':woman-rowing-boat:'},
  '🏊': {key: '🏊', value: ':swimmer:', colons: ':swimmer:'},
  '🏊‍♂️': {key: '🏊‍♂️', value: ':man-swimming:', colons: ':man-swimming:'},
  '🏊‍♀️': {key: '🏊‍♀️', value: ':woman-swimming:', colons: ':woman-swimming:'},
  '⛹️': {key: '⛹️', value: ':person_with_ball:', colons: ':person_with_ball:'},
  '⛹️‍♂️': {key: '⛹️‍♂️', value: ':man-bouncing-ball:', colons: ':man-bouncing-ball:'},
  '⛹️‍♀️': {key: '⛹️‍♀️', value: ':woman-bouncing-ball:', colons: ':woman-bouncing-ball:'},
  '🏋️': {key: '🏋️', value: ':weight_lifter:', colons: ':weight_lifter:'},
  '🏋️‍♂️': {key: '🏋️‍♂️', value: ':man-lifting-weights:', colons: ':man-lifting-weights:'},
  '🏋️‍♀️': {key: '🏋️‍♀️', value: ':woman-lifting-weights:', colons: ':woman-lifting-weights:'},
  '🚴': {key: '🚴', value: ':bicyclist:', colons: ':bicyclist:'},
  '🚴‍♂️': {key: '🚴‍♂️', value: ':man-biking:', colons: ':man-biking:'},
  '🚴‍♀️': {key: '🚴‍♀️', value: ':woman-biking:', colons: ':woman-biking:'},
  '🚵': {key: '🚵', value: ':mountain_bicyclist:', colons: ':mountain_bicyclist:'},
  '🚵‍♂️': {key: '🚵‍♂️', value: ':man-mountain-biking:', colons: ':man-mountain-biking:'},
  '🚵‍♀️': {key: '🚵‍♀️', value: ':woman-mountain-biking:', colons: ':woman-mountain-biking:'},
  '🏎️': {key: '🏎️', value: ':racing_car:', colons: ':racing_car:'},
  '🏍️': {key: '🏍️', value: ':racing_motorcycle:', colons: ':racing_motorcycle:'},
  '🤸': {key: '🤸', value: ':person_doing_cartwheel:', colons: ':person_doing_cartwheel:'},
  '🤸‍♂️': {key: '🤸‍♂️', value: ':man-cartwheeling:', colons: ':man-cartwheeling:'},
  '🤸‍♀️': {key: '🤸‍♀️', value: ':woman-cartwheeling:', colons: ':woman-cartwheeling:'},
  '🤼': {key: '🤼', value: ':wrestlers:', colons: ':wrestlers:'},
  '🤼‍♂️': {key: '🤼‍♂️', value: ':man-wrestling:', colons: ':man-wrestling:'},
  '🤼‍♀️': {key: '🤼‍♀️', value: ':woman-wrestling:', colons: ':woman-wrestling:'},
  '🤽': {key: '🤽', value: ':water_polo:', colons: ':water_polo:'},
  '🤽‍♂️': {key: '🤽‍♂️', value: ':man-playing-water-polo:', colons: ':man-playing-water-polo:'},
  '🤽‍♀️': {key: '🤽‍♀️', value: ':woman-playing-water-polo:', colons: ':woman-playing-water-polo:'},
  '🤾': {key: '🤾', value: ':handball:', colons: ':handball:'},
  '🤾‍♂️': {key: '🤾‍♂️', value: ':man-playing-handball:', colons: ':man-playing-handball:'},
  '🤾‍♀️': {key: '🤾‍♀️', value: ':woman-playing-handball:', colons: ':woman-playing-handball:'},
  '🤹': {key: '🤹', value: ':juggling:', colons: ':juggling:'},
  '🤹‍♂️': {key: '🤹‍♂️', value: ':man-juggling:', colons: ':man-juggling:'},
  '🤹‍♀️': {key: '🤹‍♀️', value: ':woman-juggling:', colons: ':woman-juggling:'},
  '👫': {key: '👫', value: ':couple:', colons: ':couple:'},
  '👬': {key: '👬', value: ':two_men_holding_hands:', colons: ':two_men_holding_hands:'},
  '👭': {key: '👭', value: ':two_women_holding_hands:', colons: ':two_women_holding_hands:'},
  '💏': {key: '💏', value: ':couplekiss:', colons: ':couplekiss:'},
  '👩‍❤️‍💋‍👨': {key: '👩‍❤️‍💋‍👨', value: ':woman-kiss-man:', colons: ':woman-kiss-man:'},
  '👨‍❤️‍💋‍👨': {key: '👨‍❤️‍💋‍👨', value: ':man-kiss-man:', colons: ':man-kiss-man:'},
  '👩‍❤️‍💋‍👩': {key: '👩‍❤️‍💋‍👩', value: ':woman-kiss-woman:', colons: ':woman-kiss-woman:'},
  '💑': {key: '💑', value: ':couple_with_heart:', colons: ':couple_with_heart:'},
  '👩‍❤️‍👨': {key: '👩‍❤️‍👨', value: ':woman-heart-man:', colons: ':woman-heart-man:'},
  '👨‍❤️‍👨': {key: '👨‍❤️‍👨', value: ':man-heart-man:', colons: ':man-heart-man:'},
  '👩‍❤️‍👩': {key: '👩‍❤️‍👩', value: ':woman-heart-woman:', colons: ':woman-heart-woman:'},
  '👪': {key: '👪'},
  '👨‍👩‍👦': {key: '👨‍👩‍👦', value: ':man-woman-boy:', colons: ':man-woman-boy:'},
  '👨‍👩‍👧': {key: '👨‍👩‍👧', value: ':man-woman-girl:', colons: ':man-woman-girl:'},
  '👨‍👩‍👧‍👦': {key: '👨‍👩‍👧‍👦', value: ':man-woman-girl-boy:', colons: ':man-woman-girl-boy:'},
  '👨‍👩‍👦‍👦': {key: '👨‍👩‍👦‍👦', value: ':man-woman-boy-boy:', colons: ':man-woman-boy-boy:'},
  '👨‍👩‍👧‍👧': {key: '👨‍👩‍👧‍👧', value: ':man-woman-girl-girl:', colons: ':man-woman-girl-girl:'},
  '👨‍👨‍👦': {key: '👨‍👨‍👦', value: ':man-man-boy:', colons: ':man-man-boy:'},
  '👨‍👨‍👧': {key: '👨‍👨‍👧', value: ':man-man-girl:', colons: ':man-man-girl:'},
  '👨‍👨‍👧‍👦': {key: '👨‍👨‍👧‍👦', value: ':man-man-girl-boy:', colons: ':man-man-girl-boy:'},
  '👨‍👨‍👦‍👦': {key: '👨‍👨‍👦‍👦', value: ':man-man-boy-boy:', colons: ':man-man-boy-boy:'},
  '👨‍👨‍👧‍👧': {key: '👨‍👨‍👧‍👧', value: ':man-man-girl-girl:', colons: ':man-man-girl-girl:'},
  '👩‍👩‍👦': {key: '👩‍👩‍👦', value: ':woman-woman-boy:', colons: ':woman-woman-boy:'},
  '👩‍👩‍👧': {key: '👩‍👩‍👧', value: ':woman-woman-girl:', colons: ':woman-woman-girl:'},
  '👩‍👩‍👧‍👦': {key: '👩‍👩‍👧‍👦', value: ':woman-woman-girl-boy:', colons: ':woman-woman-girl-boy:'},
  '👩‍👩‍👦‍👦': {key: '👩‍👩‍👦‍👦', value: ':woman-woman-boy-boy:', colons: ':woman-woman-boy-boy:'},
  '👩‍👩‍👧‍👧': {key: '👩‍👩‍👧‍👧', value: ':woman-woman-girl-girl:', colons: ':woman-woman-girl-girl:'},
  '👨‍👦': {key: '👨‍👦', value: ':man-boy:', colons: ':man-boy:'},
  '👨‍👦‍👦': {key: '👨‍👦‍👦', value: ':man-boy-boy:', colons: ':man-boy-boy:'},
  '👨‍👧': {key: '👨‍👧', value: ':man-girl:', colons: ':man-girl:'},
  '👨‍👧‍👦': {key: '👨‍👧‍👦', value: ':man-girl-boy:', colons: ':man-girl-boy:'},
  '👨‍👧‍👧': {key: '👨‍👧‍👧', value: ':man-girl-girl:', colons: ':man-girl-girl:'},
  '👩‍👦': {key: '👩‍👦', value: ':woman-boy:', colons: ':woman-boy:'},
  '👩‍👦‍👦': {key: '👩‍👦‍👦', value: ':woman-boy-boy:', colons: ':woman-boy-boy:'},
  '👩‍👧': {key: '👩‍👧', value: ':woman-girl:', colons: ':woman-girl:'},
  '👩‍👧‍👦': {key: '👩‍👧‍👦', value: ':woman-girl-boy:', colons: ':woman-girl-boy:'},
  '👩‍👧‍👧': {key: '👩‍👧‍👧', value: ':woman-girl-girl:', colons: ':woman-girl-girl:'},
  '🤳': {key: '🤳', value: ':selfie:', colons: ':selfie:'},
  '💪': {key: '💪', value: ':muscle:', colons: ':muscle:'},
  '👈': {key: '👈', value: ':point_left:', colons: ':point_left:'},
  '👉': {key: '👉', value: ':point_right:', colons: ':point_right:'},
  '☝️': {key: '☝️', value: ':point_up:', colons: ':point_up:'},
  '👆': {key: '👆', value: ':point_up_2:', colons: ':point_up_2:'},
  '🖕': {key: '🖕', value: ':middle_finger:', colons: ':middle_finger:'},
  '👇': {key: '👇', value: ':point_down:', colons: ':point_down:'},
  '✌️': {key: '✌️', value: ':v:', colons: ':v:'},
  '🤞': {key: '🤞', value: ':crossed_fingers:', colons: ':crossed_fingers:'},
  '🖖': {key: '🖖', value: ':spock-hand:', colons: ':spock-hand:'},
  '🤘': {key: '🤘', value: ':the_horns:', colons: ':the_horns:'},
  '🤙': {key: '🤙', value: ':call_me_hand:', colons: ':call_me_hand:'},
  '🖐️': {key: '🖐️', value: ':raised_hand_with_fingers_splayed:', colons: ':raised_hand_with_fingers_splayed:'},
  '✋': {key: '✋', value: ':hand:', colons: ':hand:'},
  '👌': {key: '👌', value: ':ok_hand:', colons: ':ok_hand:'},
  '👍': {key: '👍', value: ':up_hand:', colons: ':+1:'},
  '👎': {key: '👎', value: ':down_hand:', colons: ':-1:'},
  '✊': {key: '✊', value: ':fist:', colons: ':fist:'},
  '👊': {key: '👊', value: ':facepunch:', colons: ':facepunch:'},
  '🤛': {key: '🤛', value: ':left-facing_fist:', colons: ':left-facing_fist:'},
  '🤜': {key: '🤜', value: ':right-facing_fist:', colons: ':right-facing_fist:'},
  '🤚': {key: '🤚', value: ':raised_back_of_hand:', colons: ':raised_back_of_hand:'},
  '👋': {key: '👋', value: ':wave:', colons: ':wave:'},
  '🤟': {key: '🤟', value: ':i_love_you_hand_sign:', colons: ':i_love_you_hand_sign:'},
  '✍️': {key: '✍️', value: ':writing_hand:', colons: ':writing_hand:'},
  '👏': {key: '👏', value: ':clap:', colons: ':clap:'},
  '👐': {key: '👐', value: ':open_hands:', colons: ':open_hands:'},
  '🙌': {key: '🙌', value: ':raised_hands:', colons: ':raised_hands:'},
  '🤲': {key: '🤲', value: ':palms_up_together:', colons: ':palms_up_together:'},
  '🙏': {key: '🙏', value: ':pray:', colons: ':pray:'},
  '🤝': {key: '🤝', value: ':handshake:', colons: ':handshake:'},
  '💅': {key: '💅', value: ':nail_care:', colons: ':nail_care:'},
  '👂': {key: '👂', value: ':ear:', colons: ':ear:'},
  '👃': {key: '👃', value: ':nose:', colons: ':nose:'},
  '👣': {key: '👣', value: ':footprints:', colons: ':footprints:'},
  '👀': {key: '👀', value: ':eyes:', colons: ':eyes:'},
  '👁️': {key: '👁️', value: ':eye:', colons: ':eye:'},
  '👁️‍🗨️': {key: '👁️‍🗨️', value: ':eye-in-speech-bubble:', colons: ':eye-in-speech-bubble:'},
  '🧠': {key: '🧠', value: ':brain:', colons: ':brain:'},
  '👅': {key: '👅', value: ':tongue:', colons: ':tongue:'},
  '👄': {key: '👄', value: ':lips:', colons: ':lips:'},
  '💋': {key: '💋', value: ':kiss:', colons: ':kiss:'},
  '💘': {key: '💘', value: ':cupid:', colons: ':cupid:'},
  '❤️': {key: '❤️', value: ':heart:', colons: ':heart:'},
  '💓': {key: '💓', value: ':heartbeat:', colons: ':heartbeat:'},
  '💔': {key: '💔', value: ':broken_heart:', colons: ':broken_heart:'},
  '💕': {key: '💕', value: ':two_hearts:', colons: ':two_hearts:'},
  '💖': {key: '💖', value: ':sparkling_heart:', colons: ':sparkling_heart:'},
  '💗': {key: '💗', value: ':heartpulse:', colons: ':heartpulse:'},
  '💙': {key: '💙', value: ':blue_heart:', colons: ':blue_heart:'},
  '💚': {key: '💚', value: ':green_heart:', colons: ':green_heart:'},
  '💛': {key: '💛', value: ':yellow_heart:', colons: ':yellow_heart:'},
  '🧡': {key: '🧡', value: ':orange_heart:', colons: ':orange_heart:'},
  '💜': {key: '💜', value: ':purple_heart:', colons: ':purple_heart:'},
  '🖤': {key: '🖤', value: ':black_heart:', colons: ':black_heart:'},
  '💝': {key: '💝', value: ':gift_heart:', colons: ':gift_heart:'},
  '💞': {key: '💞', value: ':revolving_hearts:', colons: ':revolving_hearts:'},
  '💟': {key: '💟', value: ':heart_decoration:', colons: ':heart_decoration:'},
  '❣️': {key: '❣️', value: ':heavy_heart_exclamation_mark_ornament:', colons: ':heavy_heart_exclamation_mark_ornament:'},
  '💌': {key: '💌', value: ':love_letter:', colons: ':love_letter:'},
  '💤': {key: '💤', value: ':zzz:', colons: ':zzz:'},
  '💢': {key: '💢', value: ':anger:', colons: ':anger:'},
  '💣': {key: '💣', value: ':bomb:', colons: ':bomb:'},
  '💥': {key: '💥', value: ':boom:', colons: ':boom:'},
  '💦': {key: '💦', value: ':sweat_drops:', colons: ':sweat_drops:'},
  '💨': {key: '💨', value: ':dash:', colons: ':dash:'},
  '💫': {key: '💫', value: ':dizzy:', colons: ':dizzy:'},
  '💬': {key: '💬', value: ':speech_balloon:', colons: ':speech_balloon:'},
  '🗨️': {key: '🗨️', value: ':left_speech_bubble:', colons: ':left_speech_bubble:'},
  '🗯️': {key: '🗯️', value: ':right_anger_bubble:', colons: ':right_anger_bubble:'},
  '💭': {key: '💭', value: ':thought_balloon:', colons: ':thought_balloon:'},
  '🕳️': {key: '🕳️', value: ':hole:', colons: ':hole:'},
  '👓': {key: '👓', value: ':eyeglasses:', colons: ':eyeglasses:'},
  '🕶️': {key: '🕶️', value: ':dark_sunglasses:', colons: ':dark_sunglasses:'},
  '👔': {key: '👔', value: ':necktie:', colons: ':necktie:'},
  '👕': {key: '👕', value: ':shirt:', colons: ':shirt:'},
  '👖': {key: '👖', value: ':jeans:', colons: ':jeans:'},
  '🧣': {key: '🧣', value: ':scarf:', colons: ':scarf:'},
  '🧤': {key: '🧤', value: ':gloves:', colons: ':gloves:'},
  '🧥': {key: '🧥', value: ':coat:', colons: ':coat:'},
  '🧦': {key: '🧦', value: ':socks:', colons: ':socks:'},
  '👗': {key: '👗', value: ':dress:', colons: ':dress:'},
  '👘': {key: '👘', value: ':kimono:', colons: ':kimono:'},
  '👙': {key: '👙', value: ':bikini:', colons: ':bikini:'},
  '👚': {key: '👚', value: ':womans_clothes:', colons: ':womans_clothes:'},
  '👛': {key: '👛', value: ':purse:', colons: ':purse:'},
  '👜': {key: '👜', value: ':handbag:', colons: ':handbag:'},
  '👝': {key: '👝', value: ':pouch:', colons: ':pouch:'},
  '🛍️': {key: '🛍️', value: ':shopping_bags:', colons: ':shopping_bags:'},
  '🎒': {key: '🎒', value: ':school_satchel:', colons: ':school_satchel:'},
  '👞': {key: '👞', value: ':mans_shoe:', colons: ':mans_shoe:'},
  '👟': {key: '👟', value: ':athletic_shoe:', colons: ':athletic_shoe:'},
  '👠': {key: '👠', value: ':high_heel:', colons: ':high_heel:'},
  '👡': {key: '👡', value: ':sandal:', colons: ':sandal:'},
  '👢': {key: '👢', value: ':boot:', colons: ':boot:'},
  '👑': {key: '👑', value: ':crown:', colons: ':crown:'},
  '👒': {key: '👒', value: ':womans_hat:', colons: ':womans_hat:'},
  '🎩': {key: '🎩', value: ':tophat:', colons: ':tophat:'},
  '🎓': {key: '🎓', value: ':mortar_board:', colons: ':mortar_board:'},
  '🧢': {key: '🧢', value: ':billed_cap:', colons: ':billed_cap:'},
  '⛑️': {key: '⛑️', value: ':helmet_with_white_cross:', colons: ':helmet_with_white_cross:'},
  '📿': {key: '📿', value: ':prayer_beads:', colons: ':prayer_beads:'},
  '💄': {key: '💄', value: ':lipstick:', colons: ':lipstick:'},
  '💍': {key: '💍', value: ':ring:', colons: ':ring:'},
  '💎': {key: '💎', value: ':gem:', colons: ':gem:'}
};
