import {environment} from '../../environments/environment';

export const endpoints = {
  getUsers: environment.baseUrl + 'users/',
  createConversation: environment.baseUrl + 'conversations',
  assignUsers: environment.baseUrl + 'conversations/{conversationId}/userConversationRelations/{userId}',
  conversations: environment.baseUrl + 'conversations',
  messages: environment.baseUrl + 'conversations/{conversationId}/messages',
  getUser: environment.baseUrl + 'users/',
  addMessage: environment.baseUrl + 'conversations/{conversationId}/messages',
  addMessageWithAttachment: environment.baseUrl + '/conversations/{conversationId}/messages/{messageId}/messageAttachment',
  setReadStatus: environment.baseUrl + '/conversations/{conversationId}/userConversationRelations/{userId}',
  helpCategories: environment.baseUrl + '/helpCategories',
  getAttachment: environment.baseUrl + '/conversations/{conversationId}/messages/{messageId}/messageAttachment',
  conversation: environment.baseUrl + 'conversations/{conversationId}',
  accounts: environment.baseUrl + '/accounts/{account_id}',
  emailAlerts: environment.baseUrl + 'users/{userID}/emailalert/{accountID}',
};
