<ng-template #content let-modal>
  <form [@fadeInOut] [formGroup]="form">
    <div class="modal-header">
      <i class="fas fa-comments-alt"></i>
      <h4 class="modal-title" id="modal-basic-title">&nbsp;&nbsp;{{ 'NEW_CONVERSATION' | translate }}</h4>
      <button type="button" class="close close-button" aria-label="Close" (click)="onClose()">
        <i class="fal fa-close" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body">
      <p class="intro" *ngIf="!nextClicked && !chatDetails">{{'select-group-to-chat-with' | translate  }}</p>
      <p class="intro" *ngIf="nextClicked">{{'select-teammember-to-chat-with' | translate  }}</p>

      <app-chat-type (messageEvent)="receiveMessage($event)" [nextClicked]="nextClicked" [chatDetails]="chatDetails" [relatedObjectUid]="related_object_uid"></app-chat-type>
    </div>
    <div class="modal-footer"
         [ngClass]="{'justify-content-between' : nextClicked && !noBack || chatDetails && !noBack, 'justify-content-end': noBack}">
      <div *ngIf="nextClicked && !noBack" (click)="nextClicked = !nextClicked">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        <button class=" back"> {{'Back' | translate}}</button></div>

        <div *ngIf="chatDetails && !noBack && !nextClicked" (click)="chatDetails = !chatDetails">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
        <button class=" back"> {{'Back' | translate}}</button>
      </div>

      <button *ngIf="!nextClicked && shouldGoNext"
              type="button" class="btn btn-primary action-classes"
              [disabled]="!conversationTypeSelected" (click)="next()">
        <span>{{'Next' | translate}}</span>
      </button>
      <button *ngIf="conversationTypeSelected=='support' && !chatDetails"
              type="button" class="btn btn-primary action-classes"
              [disabled]="!conversationTypeSelected" (click)="nextOpt()">
        <span>{{'Next' | translate}}</span>
      </button>
      <button *ngIf="chatDetails && !nextClicked"
              type="button" class="btn btn-primary action-classes"
              [disabled]="!conversationTypeSelected || loading" (click)="startConversation()">
        <span *ngIf="!loading">START</span>
        <div *ngIf="loading" class="spinner-border" role="status">
          <span class="sr-only">{{'Loading' | translate}}...</span>
        </div>
      </button> 
      <button *ngIf="conversationTypeSelected=='consultant' && !chatDetails"
              type="button" class="btn btn-primary action-classes"
              [disabled]="!conversationTypeSelected || loading" (click)="startConversation()">
        <span *ngIf="!loading">START</span>
        <div *ngIf="loading" class="spinner-border" role="status">
          <span class="sr-only">{{'Loading' | translate}}...</span>
        </div>
      </button>
        <button *ngIf="nextClicked" class="btn btn-primary" [disabled]="loading" (click)="startConversation()">
          <span *ngIf="!loading">START </span>
          <div *ngIf="loading" class="spinner-border" role="status">
            <span class="sr-only">{{'Loading' | translate}}...</span>
          </div>
        </button>
    </div>
  </form>
</ng-template>
