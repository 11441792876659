<div class="message" [ngClass]="{'sent': byCurrentUser(), 'single_chat': conversationType=='SingleChat'}">
  <img *ngIf="message.createdBy.photo && message.sys_message == 0" class="user-image" [src]="message.createdBy.photo">
  <span *ngIf="!message.createdBy.photo && message.sys_message == 0" class="user_name_photo">{{ message.createdBy.name.charAt(0).toUpperCase() }}</span>
  <div *ngIf="message.isImage; else emoji">
    <div [ngClass]="message.failed ? 'image_msg_failed' : (message.loading ? 'msg_loading image_msg_loading' : '')">
      <div *ngIf="message.loading" class="spinner-border" role="status"></div>
      <div class="text">
        <span class="user_name">{{ message.createdBy.name }}</span>
        <img class="img-thumbnail" [src]="message.attachment.imageUrl" (click)="download()">
        <span class="time py-2">
          <div *ngIf="message.failed" class="message_error_icon pull-left"><i class="fa fa-exclamation"></i></div>
          <div class="pull-right">{{message.createdAt | date: authService.getUserTimeFormat() : authService.getUser().timezoneOffset : authService.getUser().language}}</div>
          <div class="clearfix"></div>
        </span>
      </div>
    </div>
  </div>
  <ng-template #emoji>
    <div *ngIf="message.isEmoji(); else text" class="emjo-container">
      <div class="text-container">
        <span class="user_name">{{ message.createdBy.name }}</span>
        <ngx-emoji set="emojione" [emoji]="message.emoji" size="64" class="emojis"></ngx-emoji>
      </div>
      <span class="time ">{{message.createdAt | date: authService.getUserTimeFormat() : authService.getUser().timezoneOffset : authService.getUser().language}}</span>
    </div>
  </ng-template>
  <ng-template #text>
    <div class="text" [ngClass]="{'msg_loading': message.loading && !message.failed, 'msg_failed': message.failed, 'sys_message': message.sys_message == 1}" *ngIf="message.isFile() && !message.failed; else: plainText">
      <div *ngIf="message.loading" class="spinner-border" role="status"></div>
      <span class="user_name">{{ message.createdBy.name }}</span>
      <button class="attachment" (click)="download()">
        <i class="fa fa-paperclip mr-2"></i>{{ message.text  }}
      </button>
      <span *ngIf="message.isFile(); else: plainText" class="time ">{{message.createdAt | date: authService.getUserTimeFormat() : authService.getUser().timezoneOffset : authService.getUser().language}}</span>
    </div>
    <ng-template #plainText>
      <div class="text-container" [ngClass]="{'msg_failed': message.failed, 'sys_message': message.sys_message == 1}">
        <div class="text">
          <span class="user_name">{{ message.createdBy.name }}</span>
          <span *ngIf="message.failed" class="message_error_icon"><i class="fa fa-exclamation"></i></span>
          <span class="msg_txt" [innerHTML]="message.text">{{ message.text }}</span>
          <span class="time">{{message.createdAt | date: authService.getUserTimeFormat() : authService.getUser().timezoneOffset : authService.getUser().language}}</span>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
