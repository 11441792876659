import {MessageResponse} from './responses/message.response';
import {merge} from "rxjs";

export class MessagesModel {
  public messages: any;
  private lastMessage: MessageResponse = null;
  private length = 0;

  constructor() {
    this.messages = {};
  }

  addMessage(message: MessageResponse) {
    if (!(message.creationDate in this.messages)) {
      this.addDate(message.creationDate);
    }
    this.setLastMessage(message);
    this.messages[message.creationDate][message.id + '' + message.createdAt.getTime()] = message;
    this.length++;
  }

  addDate(date: number) {
    this.messages[date] = {};
  }

  setOldMessages(messages: MessageResponse[]) {
    messages.forEach(message => {
      this.addMessage(message);
    });
  }

  private setLastMessage(message: MessageResponse) {
    if (this.lastMessage && message.id > this.lastMessage.id) {
      this.lastMessage = message;
    } else if (!this.lastMessage) {
      this.lastMessage = message;
    }
  }

  getLastMessageId() {
    return this.lastMessage ? this.lastMessage.id : 0;
  }

  getLength() {
    return this.length;
  }
}
