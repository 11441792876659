import {Injectable} from '@angular/core';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class AlertService {
  constructor(
    private translation: TranslateService) {
  }

  showForm(title: string) {
    return swal.fire({
      title,
      input: 'text',
      showCancelButton: true,
    });
  }

  showConfirm(message: string) {
    return swal.fire({
      text: this.translation.instant(message),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.translation.instant('YES'),
      cancelButtonText: this.translation.instant('CANCEL')
    });
  }
}
