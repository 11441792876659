<div class="card">
  <div class="card-body">    
    <div class="help-center-section">
      <h5>{{ 'HELP_CENTER' | translate }}</h5>
      <button (click)="clickHelpCente()" class="help-center-section-btn" >
        <i class="fas fa-question-circle"></i>
        <span>{{ 'VISIT_HELP_CENTER' | translate }} </span>
      </button>
    </div>
  </div>
</div>
