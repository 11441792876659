import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {endpoints} from '../constants/endpoints';
import {ApiResponseList, ApiResponseSingle} from '../models/responses/api.response';
import {MessageResponse} from '../models/responses/message.response';
import {flatMap, map} from 'rxjs/operators';
import {getAttachmentUrl, isImage, mapMessageApiResponse, mapMessagesApiResponse, replaceEmojis} from '../helpers/mapMessages';
import {Attachment} from '../models/attachment';
import {interval, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class MessageService {
  private page = 0;
  private baseUrl: string;
  private conversationId;

  constructor(private httpClient: HttpClient, private authService: AuthService, private sanitizer: DomSanitizer) {
  }

  init(conversationId) {
    this.conversationId = conversationId;
    this.page = 0;
    this.setBaseUrl();
    return this;
  }

  getOldMessages() {
    const limit = 10;
    const offset = this.page * 10;
    this.page++;
    const url = this.baseUrl + `?orderBy=createdAt&order=desc&limit=${limit}&offset=${offset}`;
    return this.getMessages(url);
  }

  pullNewMessages(lastMessageId: number) {
    const url = this.baseUrl + `?orderBy=id&order=desc&query=["id", ${lastMessageId}, ">"]`;
    return this.getMessages(url);
  }

  addMessage(message: string, attachment?: Attachment) {
    return  attachment ? this.postMessageObservable(message, attachment) : this.postMessageObservable(message);
  }

  private postMessageObservable(message: string, attachment?: Attachment): Observable<MessageResponse> {
    const post = {
      message: replaceEmojis(message),
      createdAt: new Date().toUTCString(),
      createdBy: this.authService.getUserId(),
      modifiedAt: new Date().toUTCString(),
      modifiedBy: this.authService.getUserId(),
      fileName: attachment ? attachment.fileName : null,
      fileType: attachment ? attachment.fileType : null,
      fileContent: attachment ? attachment.fileContent : null,
    };

    return this.httpClient.post<ApiResponseSingle<MessageResponse>>(this.baseUrl, post).pipe(map(mapMessageApiResponse));
  }

  addAttachment(conversationId, message: MessageResponse, attachment: Attachment) {
    const url = endpoints.addMessageWithAttachment.replace('{conversationId}', conversationId);
    return this.httpClient
      .post<ApiResponseSingle<Attachment>>(url.replace('{messageId}', message.id.toString()), attachment)
      .pipe(
        map(res => res.record),
        map(res => {
          message.attachment.fileContent = getAttachmentUrl(message);
          return message;
        })
      );
  }

  getMessages(url: string) {
    return this.httpClient
      .get<ApiResponseList<MessageResponse>>(url)
      .pipe(
        map(mapMessagesApiResponse),
        map((messages: MessageResponse[]) => {
          messages
            .filter(message => message.attachment && isImage(message.attachment))
            .map(message => {
              this
                .downloadAttachment(message.attachment.fileContent, true)
                .subscribe(res => message.attachment.imageUrl = res);
              return message;
            });
          return messages;
        })
      );
  }

  setBaseUrl() {
    this.baseUrl = endpoints.messages.replace('{conversationId}', this.conversationId);
  }

  downloadAttachment(url: string, safe = false) {
    return this.httpClient
      .get(url, {responseType: 'blob'})
      .pipe(
        map(res => {
          if (safe) {
            return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(res));
          } else {
            return URL.createObjectURL(res);
          }
        })
      );
  }
}
