import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import {AuthService} from '../../../services/auth.service';
@Pipe({
  name: 'formNow'
})
export class FromNowPipe implements PipeTransform {
	constructor(
		private authService: AuthService) {
  	}
  transform(value: any): any {
  	var user = this.authService.getUser();
  	var displayValue = value;
  	if(user){
		var utcCutoff = moment.utc(value, 'YYYYMMDD HH:mm:ss');
		displayValue  = utcCutoff.clone().tz(user.timezone); 
   	}
   	return moment(displayValue).fromNow();
  	}
}
