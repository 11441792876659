import {animate, animateChild, group, query, state, style, transition, trigger} from '@angular/animations';
import {animateFromBottom, fadeInOut} from './enterFromBottom';

export const slideInAnimation =
    trigger('routeAnimations', [
        transition('Home => *', fadeInOut),
        transition('OldConversation => *', fadeInOut),
        transition('ChatBox => *', fadeInOut),
        transition('NewConversation => *', fadeInOut)
    ]);
