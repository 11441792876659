import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {endpoints} from '../constants/endpoints';
import {flatMap, map} from 'rxjs/operators';
import {ConversationResponse} from '../models/responses/conversation.response';
import {ApiResponseList, ApiResponseSingle} from '../models/responses/api.response';
import {mapConversations} from '../helpers/mapConversation';
import {interval, Observable} from 'rxjs';
import { BehaviorSubject } from 'rxjs';


@Injectable({providedIn: 'root'})
export class ConversationService {
  pollConversationObservable = null;
  private messageID = new BehaviorSubject('');
  currentID = this.messageID.asObservable();
  currentConversationId: number;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  startNewConversation(users, files, title = null, description = null, relatedData = null) {
    const conversation = {
      accountId: this.authService.getAccountId(),
      title,
      description,
      relatedObjectKey: '',
      relatedObjectUid: '',
      relatedObjectIcon: '',
      relatedObjectName: '',
      relatedObjectDeeplink: '',
      lastMessageAt: new Date(),
      createdAt: new Date(),
      createdBy: this.authService.getUserId(),
      modifiedAt: new Date(),
      modifiedBy: this.authService.getUserId(),
      otherUsers: JSON.stringify(users),
      files: JSON.stringify(files)
    };

    let conversationId = null;

    // Related data
    if (
      relatedData && relatedData.object_uid
      && relatedData.object_key
      && relatedData.object_name
      && relatedData.object_deeplink
    ) {
      conversation.relatedObjectKey = relatedData.object_key;
      conversation.relatedObjectUid = relatedData.object_uid;
      conversation.relatedObjectName = relatedData.object_name;
      conversation.relatedObjectDeeplink = relatedData.object_deeplink;
    }

    return this.httpClient
      .post<ApiResponseSingle<ConversationResponse>>(endpoints.createConversation, conversation)
      .pipe(
        map(res => {
          conversationId = res.record.id;
          return res.record;
        }),
        flatMap(() => {
          let assignUsers = this.assignUser(conversationId, users[0]);
          for (let i = 1; i < users.length; i++) {
            assignUsers = assignUsers.pipe(flatMap(() => this.assignUser(conversationId, users[i])));
          }
          return assignUsers;
        }),
        flatMap(() => this.assignUser(conversationId, this.authService.getUserId())),
      );
  }

  getConversation(conversationId) {
    const url = endpoints.conversations + `?query=[["id", ${conversationId}, "="]]`;
    return this.httpClient.get<ApiResponseList<ConversationResponse>>(url).pipe(map(res => Object.values(res.records).pop()));
  }

  assignUser(conversationId, userId) {
    const url = endpoints.assignUsers.replace('{conversationId}', conversationId).replace('{userId}', userId);
    return this.httpClient.put(url, {lastRead: new Date()}).pipe(map(res => conversationId));
  }

  getConversations(limit = 0, archived= -1, related_data = null) {
    let url = endpoints.conversations + `?userId=${this.authService.getUserId()}&orderBy=lastMessageAt&fetchNewMessageCount=1&have_messages=1`;
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (archived >= 0) {
      url += `&archived=${archived}`;
    }

    if (related_data && related_data.object_key && related_data.object_uid) {
      const filter = new Array();
      filter[0] = Array('relatedObjectKey', related_data.object_key, '=');
      filter[1] = Array('relatedObjectUid', related_data.object_uid, '=');
      url += '&query=' + JSON.stringify(filter);
    }

    return this.httpClient
      .get<ApiResponseList<ConversationResponse>>(url)
      .pipe(
        map(res => mapConversations(Object.values(res.records))),
      );
  }

  pollConversation() {
    if (!this.pollConversationObservable) {
      this.pollConversationObservable = interval(5000).pipe(flatMap(() => this.getConversations()));
    }

    return this.pollConversationObservable;
  }

  setLastRead(conversationId: string) {
    const url = endpoints.setReadStatus
      .replace('{conversationId}', conversationId)
      .replace('{userId}', this.authService.getUserId().toString());
    return this.httpClient.put(url, {lastRead: new Date()}).subscribe(res => res);
  }
  changeID(id: any) {
    this.messageID.next(id);
  }

  update(conversation: ConversationResponse, updates: Partial<ConversationResponse>) {
    const url = endpoints.conversation.replace('{conversationId}', conversation.id.toString());
    return this.httpClient.patch(url, {
      ...conversation,
      ...updates,
    });
  }

  archive(conversationId) {
    const url = endpoints.setReadStatus
      .replace('{conversationId}', conversationId)
      .replace('{userId}', this.authService.getUserId().toString());
    return this.httpClient.put(url, {archive: 1});
  }

  delete(conversationId) {
    const url = endpoints.conversation
      .replace('{conversationId}', conversationId);
    return this.httpClient.request('delete', url, {
      body: {
        only_if_empty: 1
      }
    });
  }
}
