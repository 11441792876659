import {NgModule} from '@angular/core';
import {ChatItemComponent} from './components/chat-item/chat-item.component';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FromNowPipe} from './pipes/from-now.pipe';
import {CreateNewConversationComponent} from './components/create-new-conversation/create-new-conversation.component';
import {SearchComponent} from './components/search/search.component';
import {SystemStatusComponent} from './components/system-status/system-status.component';
import {ListItemComponent} from './components/list-item/list-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ScrollingModule as ExperimentalScrollingModule} from '@angular/cdk-experimental/scrolling';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {ChatboxComponent} from './components/chatbox/chatbox.component';
import {MessageComponent} from './components/message/message.component';
import {UtcDatePipe} from './pipes/utc-date.pipe';
import {ReverseScrollComponent} from './components/reverse-scroll/reverse-scroll.component';
import {TooltipDirective} from './directives/tooltip.directive';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import { ChatTypeComponent } from './components/chat-type/chat-type.component';
import {AddMembersComponent} from './components/add-members/add-members.component';
import {ConversationHeaderComponent} from './components/conversation-header/conversation-header.component';
import { HelpCenterComponent } from './components/help-center/help-center.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    EmojiModule,
    PickerModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    MatTooltipModule,
    HttpClientModule,
    MatCheckboxModule
  ],
  declarations: [
    ChatItemComponent,
    FromNowPipe,
    CreateNewConversationComponent,
    SearchComponent,
    SystemStatusComponent,
    ListItemComponent,
    ChatboxComponent,
    MessageComponent,
    UtcDatePipe,
    ReverseScrollComponent,
    TooltipDirective,
    ChatTypeComponent,
    AddMembersComponent,
    ConversationHeaderComponent,
    HelpCenterComponent
  ],
  exports: [
    ChatItemComponent,
    CommonModule,
    NgbModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    FromNowPipe,
    CreateNewConversationComponent,
    SearchComponent,
    FormsModule,
    SystemStatusComponent,
    TranslateModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    ChatboxComponent,
    MessageComponent,
    ReverseScrollComponent,
    TooltipDirective,
    HttpClientModule,
    ChatTypeComponent,
    AddMembersComponent,
    ConversationHeaderComponent,
    HelpCenterComponent
  ],
})
export class SharedModule {

}
