import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'full-screen', loadChildren: () => import('./modules/full-screen/full-screen.module').then(m => m.FullScreenModule),
  },
  {
    path: '', loadChildren: () => import('./modules/widget/widget.module').then(m => m.WidgetModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
