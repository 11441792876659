import {Component, OnInit} from '@angular/core';
import {fadeInOut} from '../../../../animations/FadeInOut';
import {HelpService} from '../../../../services/help.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    fadeInOut
  ]
})
export class SearchComponent implements OnInit {
  keyword: string;

  constructor(private helpService: HelpService) {
  }

  ngOnInit() {
  }

  onKeyup(event) {
    // fadeAnimation key
    if (event.which === 13) {
      this.onSearch();
    }
  }

  onSearch() {
    window.open(`https://help.getmyinvoices.com/de/?s=${this.keyword}&post_type=faq`, '_blank');
    this.keyword = '';
  }
}
