import {animate, state, style, transition, trigger} from '@angular/animations';

export const emojisAnimation = trigger('emojisAnimation', [
  state('0', style({opacity: 0, zIndex: -1, display: 'none'})),
  state('1', style({opacity: 1, display: 'block'})),
  transition('0 => 1', [
    animate(250)
  ]),
  transition('1 => 0', [
    animate(100)
  ])
]);
