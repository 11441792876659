import { AccountResponce } from '../models/responses/account.response';
import {
  TIME_MAP
} from './../constants/time-formats.constant';
import {
  DATE_MAP
} from './../constants/date-formats.constant';
import {
  Injectable,
  Injector
} from '@angular/core';
import {
  ActivatedRoute
} from '@angular/router';
import {
  flatMap,
  map
} from 'rxjs/operators';
import {
  HttpClient
} from '@angular/common/http';
import {
  endpoints
} from '../constants/endpoints';
import {
  ApiResponseSingle
} from '../models/responses/api.response';
import {
  UserResponse
} from '../models/responses/user.response';
import {interval} from 'rxjs';
import {IframeParentService} from 'src/app/services/iframe-parent.service';

@Injectable()
export class AuthService {
  private localStorageTokenKey = 'token';
  private localStorageUserKey = 'userId';

  private static getURLParam(param: string) {
    const params = new URLSearchParams(window.location.search);
    return params.get(param);
  }

  constructor(private httpClient: HttpClient, private _iFrameSer: IframeParentService) { }

  public setToken() {
    sessionStorage.setItem(this.localStorageTokenKey, AuthService.getURLParam(this.localStorageTokenKey));
  }

  public getToken() {
    return sessionStorage.getItem(this.localStorageTokenKey);
  }

  public getAccountId() {
    return this.getUser().accountId;
  }

  public getUserId(): number {
    return this.getUser().id;
  }

  public setUser() {
    var user_id = AuthService.getURLParam(this.localStorageUserKey) ? AuthService.getURLParam(this.localStorageUserKey) : this.getUserId();
    return this.httpClient.get<ApiResponseSingle<UserResponse>>(endpoints.getUsers + user_id)
      .pipe(
        map(res => res.record),
      )
      .toPromise()
      .then(user => sessionStorage.setItem(this.localStorageUserKey, JSON.stringify(user)));
  }

  public getUser(): UserResponse {
    return sessionStorage.getItem(this.localStorageUserKey) ? JSON.parse(sessionStorage.getItem(this.localStorageUserKey)) : '';
  }

  public getUserDateFormat(): string {
    return this.getUser().dateFormat in DATE_MAP ? DATE_MAP[this.getUser().dateFormat] : 'yyyy-mm-dd';
  }

  public getUserTimeFormat(): string {
    return this.getUser().timeFormat in TIME_MAP ? TIME_MAP[this.getUser().timeFormat] : 'hh:mm:ss aa';
  }

  public getAccountsData() {
    return this.httpClient.get<ApiResponseSingle<AccountResponce>>(
      endpoints.accounts.replace('{account_id}', this.getUser().accountId.toString()))
      .pipe(
        map(res => res.record),
      )
      .toPromise();
  }

  public pollUserInfo() {
    /*window.parent.postMessage('newMessage__' + this.getUser().newMessageCount, '*');

    interval(30000).subscribe(() => {
      this.setUser();
      this._iFrameSer.newMessageBadge(this.getUser().newMessageCount);
    });*/
  }
}

