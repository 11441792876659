import {Component,OnInit} from '@angular/core';
import {RouterOutlet, ActivatedRoute} from '@angular/router';
import {slideInAnimation} from './animations/routing';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {
  constructor(
      private translate: TranslateService, 
      private authService: AuthService,
      private route: ActivatedRoute
    ) {}

  ngOnInit() {
    this.route.queryParams
		.subscribe(params => {
      this.translate.setDefaultLang(`${params.lang || 'en'}`);
      moment.locale(`${params.lang}`);
				console.log(params.lang);  
			}
		);
    this.authService.setToken();
    this.authService.setUser();
    this.authService.pollUserInfo();
  } 
  
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
