import {Component, OnInit} from '@angular/core';
import {fadeInOut} from '../../../../animations/FadeInOut';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
  animations: [
    fadeInOut
  ]
})
export class HelpCenterComponent implements OnInit {

  local = null;
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.translate.get('HELP_CENTER_LINK').subscribe(data => {
      this.local = data;
    });
  }

  clickHelpCente() {
    window.open(`${this.local}`, '_blank');
  }
}
