import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {fadeInOut} from '../../../../animations/FadeInOut';
import {ConversationService} from '../../../../services/conversation.service';

@Component({
  templateUrl: './add-members.component.html',
  selector: 'app-add-members',
  styleUrls: ['./add-members.component.scss'],
  animations: [
    fadeInOut,
  ]
})
export class AddMembersComponent {
  @ViewChild('content', {static: true}) content: ElementRef;
  loading = false;

  @Input()
  chatMembers = [];

  @Input()
  conversationId;

  @Output()
  addedUser = new EventEmitter();

  ids = [];
  names = [];

  constructor(private modalService: NgbModal, private conversationService: ConversationService) {
  }

  memberReceived(event) {
    this.ids = event.teamMember.teamMemberId;
    this.names = event.teamMember.teamMemberName;
  }

  open() {
    this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title', centered: true});
  }

  async addMembers() {
    this.loading = true;
    for (const id of this.ids) {
      await this.conversationService.assignUser(this.conversationId, id).toPromise();
    }
    this.loading = false;
    this.modalService.dismissAll();
    this.addedUser.emit({names: this.names, ids: this.ids});
  }
}
