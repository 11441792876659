import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConversationResponse } from '../../../../models/responses/conversation.response';
import { IframeParentService } from '../../../../services/iframe-parent.service';
import { Router } from '@angular/router';
import { AddMembersComponent } from '../add-members/add-members.component';
import { AuthService } from '../../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ConversationService } from '../../../../services/conversation.service';

@Component({
  templateUrl: './conversation-header.component.html',
  selector: 'app-conversation-header',
  styleUrls: ['./conversation-header.scss']
})
export class ConversationHeaderComponent implements OnInit {
  @Input()
  conversation: ConversationResponse;

  @Input()
  isFullScreen: any = false;

  @ViewChild(AddMembersComponent, { static: true })
  addMembers: AddMembersComponent;

  images = [];

  supportAgent: boolean;
  chatMembers: string;
  chatMembersIds: number[];

  newTitle: string;

  editable = false;

  @Output()
  membersAdded = new EventEmitter();

  @Input()
  loading = true;

  constructor(
    private _IframeParentService: IframeParentService,
    private _route: Router,
    private authService: AuthService,
    private translation: TranslateService,
    private conversationService: ConversationService
  ) {
  }

  ngOnInit(): void {
    this.supportAgent = this.conversation.users.filter(user => user.id !== this.authService.getUserId() && user.isSupportAgent === '1')
      .length > 0;
    this.chatMembers = this.conversation.users
      .filter(user => user.id !== this.authService.getUserId()).map(user => user.name).join(',');
    if (this.chatMembers.length >= 34) {
      this.chatMembers = this.chatMembers.substr(0, 34) + ' ...';
    }
    this.chatMembersIds = this.conversation.users.map(user => user.id);

    this.newTitle = this.conversation.title || this.translation.instant('chat-title');
    this.setImages();
  }

  addUser() {
    this.addMembers.open();
  }

  maximize() {
    const isFullScreen = this._IframeParentService.getIsFullScreen();

    if (isFullScreen) {
      this._route.navigate(['/conversations', this.conversation.id]);
    } else {
      this._route.navigate(['full-screen']);
    }
    setTimeout(() => this._IframeParentService.changeWidth(), 100);
  }

  close() {
    this._IframeParentService.close();
  }

  setImages() {
    this.images = this.conversation.users
    .filter(user => user.id !== this.authService.getUserId())
    .map(user => user.photo).slice(0, 2);
    return this.images;
  }

  onMembersAdded(event) {
    this.membersAdded.emit(event);
  }

  onEditTitle() {
    this.conversationService.update(this.conversation, { title: this.newTitle }).subscribe(res => {
      this.conversation.title = this.newTitle;
      this.editable = false;
    }, err => {
      this.conversation.title = this.newTitle;
      this.editable = false;
    });
  }

  editTitle() {
    this.editable = true;
  }

}
